import { url } from 'inspector';
import { User } from './../interfaces/user.interface';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, catchError } from 'rxjs/operators';
import { Me } from '../interfaces/me.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private machalaAdminService: MachalaAdminService
  ) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    let URL: string = location.href;
    if (!URL) {
      URL = window.location.href;
    }

    let auth = false;
    if (!URL.includes('/login')) {
      this.machalaAdminService
        .getUserMe()
        .toPromise()
        .then((user: Me) => {
          for (const rol of user.roles) {
            if (
              URL.includes(
                `/${rol === 'transparencias' ? 'transparencia' : rol}`
              )
            ) {
              auth = true;
              break;
            }
          }
          if (!auth) {
            this.authService.logOut();
          }
        });
    }

    return this.authService.verifyToken().pipe(
      map((resp) => {
        if (resp === 'Unauthorized') {
          this.authService.logOut();
          return false;
        } else {
          return true;
        }
      }),
      catchError((err) => {
        this.authService.logOut();
        return of(false);
      })
    );
  }
}
