<section class="container normal-text container-create-edit">
    <div class="container-form mx-auto">
        <div class="text-center title-template">
            <h2 class="border-title d-inline-block">
                <span>Agregar Cargo</span>
            </h2>
        </div>

        <form [formGroup]="useform" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="nombre" class="form-title">Nombre</label>
                <input id="nombre" type="text" name="" class="form-control" placeholder="Ingrese nombre del cargo"
                    aria-describedby="helpId" formControlName="nombre" />
                <ng-container *ngIf="useform.controls['nombre'].errors && useform.controls['nombre'].touched">
                    <ng-container *ngIf="useform.controls['nombre'].hasError('required')">
                        <small class="text-danger">El nombre es requerido.</small>
                    </ng-container>
                </ng-container>
            </div>

            <div class="form-group">
                <label for="Resumen" class="form-title">Detalle (Opcional)</label>
                <textarea id='resumen' type="text" class="form-control" placeholder="Ingresar detalle"
                    aria-describedby="helpId" rows="6" formControlName="detalle"></textarea>
                
            </div>

            <div class="text-right form-btn mt-5">
                <button type="submit" class="btn btn-success clean-text btn-enviar title-text">
                    <strong>Guardar</strong>
                </button>
            </div>
        </form>
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <div class="img-load">
                <img src="assets/img/load.gif" alt="" />
            </div>
        </div>
    </div>
</section>