import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  HostListener,
} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CreateNewsInterface } from '../../../helpers/interfaces/create-news.interface';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-news',
  templateUrl: './preview-news.component.html',
  styleUrls: ['./preview-news.component.scss'],
})
export class PreviewNewsComponent implements OnInit {
  @ViewChild('previewNewsModal') previewNewsModal: ElementRef<HTMLElement>;
  @Input() news: CreateNewsInterface;
  tags = [];
  tagsValue = [];
  entreVideo = false;
  safeURLVideo: SafeUrl;
  images = [];
  customOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  constructor(
    private machalaAdminService: MachalaAdminService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.machalaAdminService.getTags().subscribe((resp) => {
      this.tags = resp;
    });
  }

  openModal(): void {
    window.history.pushState('backPressed', null, null);
    window.history.pushState('dummy', null, null);
    window.addEventListener(
      'popstate',
      (event) => {
        if (event.state === 'backPressed') {
          // hide the HTML elements
          this.closeModal();
        }
      },
      { once: true }
    );
    if (!this.previewNewsModal.nativeElement.classList.contains('activate')) {
      this.previewNewsModal.nativeElement.classList.add('activate');
      document.body.style.overflow = 'hidden';
    }
  }

  closeModal(): void {
    if (this.previewNewsModal.nativeElement.classList.contains('activate')) {
      this.previewNewsModal.nativeElement.classList.remove('activate');
      document.body.style.overflow = '';
    }
  }

  public setNews(news: CreateNewsInterface): void {
    this.news = news;
    this.tagsValue = [];
    this.news.tags.forEach((tag) => {
      this.tagsValue.push(this.getTag(`${tag.id}`));
      // console.log(this.tagsValue);
    });
    this.safeURLVideo = undefined;
    this.images = [];
    this.news.urls.forEach((url) => {
      if (url.tipo === 'video') {
        this.safeURLVideo = this.getSefeURL(url.url);
      } else if (url.tipo === 'imagen') {
        this.images.push(url.url);
      }
    });
  }

  getTag(id: string): string {
    for (const tag of this.tags) {
      if (tag.id_tag === id) {
        if (tag.tag) {
        }
        return tag.tag;
      }
    }
  }

  getSefeURL(url: string): SafeUrl {
    let URL = url;
    if (url.indexOf('?v=') > 0) {
      const pos = url.indexOf('?v=') + 3;
      const end = url.indexOf('&') > 0 ? url.indexOf('&') : url.length;
      URL = `https://www.youtube.com/embed/${url.substring(pos, end)}`;
    } else if (url.indexOf('.be/') > 0) {
      const pos = url.indexOf('.be/') + 4;
      URL = `https://www.youtube.com/embed/${url.substring(pos)}`;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL);
  }
}
