import { UserResolver } from './helpers/resolvers/user.resolver';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { AppRouterModule } from './app.routes';
import { NewsComponent } from './pages/news/news/news.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { FooterComponent } from './pages/footer/footer.component';
import { HeaderComponent } from './pages/header/header.component';
import { CreateNewsComponent } from './pages/news/create-news/create-news.component';
import { ClaimsComponent } from './pages/claim/claims/claims.component';

import { ViewNewsComponent } from './pages/news/view-news/view-news.component';
import { ViewClaimComponent } from './pages/claim/view-claim/view-claim.component';
import { ViewComplaintComponent } from './pages/complaint/view-complaint/view-complaint.component';
import { AchievementsComponent } from './pages/achievement/achievements/achievements.component';
import { TemplateFormNewsComponent } from './pages/news/template-form-news/template-form-news.component';
import { TemplateFormAchievementComponent } from './pages/achievement/template-form-achievement/template-form-achievement.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './helpers/interceptors/auth.interceptor';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { RegisterComponent } from './pages/register/register/register.component';
import { PreviewNewsComponent } from './pages/news/preview-news/preview-news.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { EditNewsComponent } from './pages/news/edit-news/edit-news.component';
import { ModalComponent } from './pages/common-pages/modal/modal.component';
import { ComplaintsComponent } from './pages/complaint/complaints/complaints.component';
import { TransparencyComponent } from './pages/transparency/transparency/transparency.component';
import { AddDocumentsComponent } from './pages/transparency/add-documents/add-documents.component';
import { ClaimsDashboardComponent } from './pages/dashboard/claims-dashboard/claims-dashboard.component';

import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import { PlotlyModule } from 'angular-plotly.js';
PlotlyModule.plotlyjs = PlotlyJS;

import { HighchartsChartModule } from 'highcharts-angular';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { MatTableModule } from '@angular/material/table';

import { TableModule } from 'primeng/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxEditorModule } from 'ngx-editor';
import { UpdateUserComponent } from './pages/register/update-user/update-user.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UpdateTransparencyComponent } from './pages/transparency/update-transparency/update-transparency.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AddAccountabilityComponent } from './pages/transparency/add-accountability/add-accountability.component';
import { UpdateAccountabilityComponent } from './pages/transparency/update-accountability/update-accountability.component';

import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { AddOtherDocumentsComponent } from './pages/transparency/add-other-documents/add-other-documents.component';
import { UpdateOtherDocumentsComponent } from './pages/transparency/update-other-documents/update-other-documents.component';

import { ClipboardModule } from 'ngx-clipboard';
import { PaymentsComponent } from './pages/payments/payments/payments.component';
import { PaymentsListComponent } from './pages/payments/payments-list/payments-list.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { CreateAnnouncementComponent } from './pages/announcement/create-announcement/create-announcement/create-announcement.component';
import { EditAnnouncementComponent } from './pages/announcement/edit-announcement/edit-announcement.component';
import { ChargesComponent } from './pages/charges/charges.component';
import { CreateChargeComponent } from './pages/charges/create-charge/create-charge.component';
import { EditChargeComponent } from './pages/charges/edit-charge/edit-charge.component';
import { ViewChargesComponent } from './pages/announcement/view-charges/view-charges.component';
import { FileChargeComponent } from './pages/charges/file-charge/file-charge.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NewsComponent,

    LayoutComponent,

    FooterComponent,

    HeaderComponent,

    CreateNewsComponent,

    ClaimsComponent,

    ViewNewsComponent,

    ViewClaimComponent,

    ViewComplaintComponent,

    AchievementsComponent,

    TemplateFormNewsComponent,

    TemplateFormAchievementComponent,

    RegisterComponent,

    PreviewNewsComponent,

    EditNewsComponent,

    ModalComponent,

    ComplaintsComponent,

    TransparencyComponent,

    AddDocumentsComponent,

    ClaimsDashboardComponent,

    UpdateUserComponent,

    UpdateTransparencyComponent,

    AddAccountabilityComponent,

    UpdateAccountabilityComponent,

    AddOtherDocumentsComponent,

    UpdateOtherDocumentsComponent,

    PaymentsComponent,

    PaymentsListComponent,

    AnnouncementComponent,

    CreateAnnouncementComponent,

    EditAnnouncementComponent,

    ChargesComponent,

    CreateChargeComponent,

    EditChargeComponent,

    ViewChargesComponent,

    FileChargeComponent,
  ],
  imports: [
    BrowserModule,
    AppRouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    PlotlyModule,
    HighchartsChartModule,
    LeafletModule,
    MatTableModule,
    NgxEditorModule,
    NgxPaginationModule,
    AccordionModule,
    ClipboardModule,
    ScrollToModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAW0FlOWDg1vtzZaYSLSvVKJaYCRHdp1rs',
    }),
    TableModule,
    MatExpansionModule,
    PaginationModule,
  ],
  providers: [
    UserResolver,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
