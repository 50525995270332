<section class="view-complaint-container container normal-text">
  <form class="mb-4" *ngIf="complaint">
    <div class="form-group d-flex justify-content-end">
      <select
        class="form-control h-100 normal-text"
        style="max-width: 200px"
        (change)="changeState($event.target.value)"
      >
        <option value="-1" selected>Cambiar Estado</option>
        <option [value]="state.id_estado" *ngFor="let state of claimsStates">
          {{ state.estado }}
        </option>
      </select>
      <button
        type="button"
        class="btn btn-success clean-text normal-text"
        (click)="openModal()"
      >
        Enviar
      </button>
    </div>
    <div *ngIf="idState == '2'">
      <label for=""
        >Elija un el código de reclamo para ser enviado a la bd.</label
      >
      <div class="tags-claim">
        <div class="tag-claim" *ngFor="let states of statesDB; let i = index">
          <input
            type="radio"
            name="estados-den"
            id="f-com-ch-{{ i }}"
            [checked]="stateCode === states.id"
            [value]="states.id"
            (change)="onItemChange($event.target.value)"
          />
          <label for="f-com-ch-{{ i }}" class="m-0"
            ><small
              >{{ states.id }} - {{ states.value | titlecase }}</small
            ></label
          >
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="idState == '3'">
      <label for="complaint-f-ta">Ingrese el motivo del rechazo.</label>
      <textarea
        class="form-control"
        name="motivo-rechazo"
        id="complaint-f-ta"
        rows="3"
        [(ngModel)]="reasonClaim"
      ></textarea>
      <small class="text-danger" *ngIf="!reasonClaim">Ingrese un motivo.</small>
    </div>
  </form>

  <div class="content" *ngIf="complaint">
    <section class="mb-4">
      <h2 class="border-title"><span>Gestión del Denuncias</span></h2>
      <h4 class="subtitle-color">Denuncia en Cuidad</h4>
      <h5>
        Estado: <span class="text-success">{{ complaint.estado_actual }}</span>
      </h5>
    </section>
    <div class="row">
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"
            ><span>{{ this.idLabel }}: </span></strong
          >
        </h6>
        {{ complaint.id_persona }}
      </div>
      <!-- <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"><span>Cédula:</span></strong>
        </h6>
        {{ complaint.id_persona }}
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"><span>RUC:</span></strong>
        </h6>
        {{ complaint.id_persona }}
      </div> -->
      <!-- <div class="col-12 col-md-6 col-xl-4">
      <h6 class='complaint-content'><strong class=" text-third"><span>Nro Cuenta:</span></strong></h6>
      0125682566001
    </div> -->
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"><span>Nombre:</span></strong>
        </h6>
        {{ complaint.nombre }}
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"><span>Email:</span></strong>
        </h6>
        {{ complaint.email }}
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"><span>Fecha:</span></strong>
        </h6>
        {{ complaint.timestamp_reclamo | date }}
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"><span>Teléfono de Contacto:</span></strong>
        </h6>
        {{ complaint.telefono1 }}
      </div>
      <!-- <div class="col-12 col-md-6 col-xl-4">
        <h6 class="complaint-content">
          <strong class="text-third"><span>Teléfono Celular:</span></strong>
        </h6>
        {{ complaint.telefono2 }}
      </div> -->
      <div class="col-12">
        <div class="mt-3">
          <h6>
            <strong class="text-third"
              ><span>Categoría de servicio:</span></strong
            >
          </h6>
          <ul>
            <li>
              {{ complaint.categoria_reclamo }}
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12">
        <div class="content-complaint text-justify">
          <h6>
            <strong class="text-third"
              ><span>Información del reclamo:</span></strong
            >
          </h6>
          <p>
            {{ complaint.descripcion }}
          </p>
        </div>
      </div>

      <!-- <div class="col-12">

        <div class="map-complaint">
          <agm-map
            [latitude]="+complaint.latlong.split(',')[0].trim()"
            [zoom]="14"
            [longitude]="+complaint.latlong.split(',')[1].trim()"
          >
            <agm-marker
              [latitude]="+complaint.latlong.split(',')[0].trim()"
              [longitude]="+complaint.latlong.split(',')[1].trim()"
            ></agm-marker>
          </agm-map>
        </div>
      </div> -->
    </div>
  </div>
  <div id="mapComplaint" style="height: 50vh"></div>
</section>

<app-modal
  #modalComplaint
  [esUsado]="'view-complaint'"
  [header]="'Cambiar de estado'"
  [body]="'Está seguro que desea cambiar de estado.'"
  [msjBotonAcep]="'Continuar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="saveState()"
></app-modal>
