import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { last, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private storage: AngularFireStorage
  ) { }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text + '.jpeg';
  }

  createBlobImageFileAndShow(base64: string): File {
    base64 = base64.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
    const imageBlob: Blob = this.dataURItoBlob(base64);
    const imageName = this.generateName();
    const imageFile: File = new File([imageBlob], imageName, {
      type: 'image/jpeg',
    });
    return imageFile;
  }

  dataURItoBlob(dataURI:string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public uploadFile(file: File): Observable<any> {
    const archivo = file;
    // const referencia = this.referenciaCloudStorage(file.name);
    const filePath = 'imagenes-noticias/' + file.name;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, archivo);

    return task.snapshotChanges().pipe(
      last(),
      mergeMap(() => {
        const url = fileRef.getDownloadURL();
        return url;
      })
    );
  }
}
