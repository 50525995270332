import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as L from 'leaflet';
import { GeneralClaimsInterface } from 'src/app/helpers/interfaces/claims.interface';
import HighchartsMore from 'highcharts/highcharts-more.src.js';
import solidGauge from 'highcharts//modules/solid-gauge.js';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';

HighchartsMore(Highcharts);
solidGauge(Highcharts);

interface GlobalIndicators {
  metaData: MetaDatum[];
  rows: Array<number[]>;
}

export interface MetaDatum {
  name: string;
}

@Component({
  selector: 'app-claims-dashboard',
  templateUrl: './claims-dashboard.component.html',
  styleUrls: ['./claims-dashboard.component.scss'],
})
export class ClaimsDashboardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  heightHeader = 0;
  @ViewChild('configDashboard') configDashboard: ElementRef<HTMLElement>;
  getYear = 2021;
  getMonth = '1';
  getType = 'FI';
  beforeYear = 0;
  beforeMonth = '';
  beforeType = '';
  typeName = 'Finalizado';

  meses = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];

  maxPeakWeek = 0;

  optionWeek: any[] = [];

  years: number[];
  months: any[];

  globalIndicators: GlobalIndicators;
  globalSumary: GlobalIndicators;
  claimByWeek: GlobalIndicators;
  claimsByYear: GlobalIndicators;

  constructor(private MachalaService: MachalaAdminService) {}

  map;

  totalClaims: GeneralClaimsInterface;
  totalClaimsList: Array<GeneralClaimsInterface>;

  formatClaim: GeneralClaimsInterface;
  formatClaimList: Array<GeneralClaimsInterface>;

  tiposReclamo: Array<string>;
  valoresTiposReclamo: Array<any>;

  tiposFormato: Array<string>;
  valoresTiposFormato: [];

  diasDesempeno: Array<string>;
  valoresDiasDesempeno: [];

  mesReclamo: Array<string>;
  valoresMesReclamo: [];
  valoresMesReclamoTotal: number[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  chartOptionsTR: Highcharts.Options;
  chartOptionsTD: Highcharts.Options;
  chartOptionRA: Highcharts.Options;
  chartOptionRM: Highcharts.Options;
  chartOptionRMTO: Highcharts.Options;
  chartOptionClaimsByYear: Highcharts.Options;

  yearsClaims: Array<string>;
  totalClaimsByYear = [];

  getTypeName(type: string): string {
    switch (type) {
      case 'FI':
        this.typeName = 'Finalizados';
        return 'Finalizados';
      case 'PD':
        this.typeName = 'Pendientes';
        return 'Finalizados';
      case 'IN':
        this.typeName = 'Ingresados';
        return 'Ingresados';
    }
  }

  public initMap = (): void => {
    this.map = L.map('map', {
      center: [-3.25861, -79.96053],
      zoom: 13,
      keyboard: false,
      boxZoom: false,
      touchZoom: false,
      dragging: true,
      scrollWheelZoom: false,
      doubleClickZoom: true,
    });
    const tiles = L.tileLayer(
      'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    tiles.addTo(this.map);
    this.setLatLogDashboard();
    //this.initZonesClaims();
  };

  public initZonesClaims = (): void => {
    this.MachalaService.getClaims().subscribe((resp) => {
      const claimGroup = [];

      resp.forEach((element) => {
        if (element['latlong'] != null && element['latlong'] != '') {
          var latlongString = element['latlong'].replace(' ', '').split(',');

          var latlong = latlongString.map((i) => Number(i));

          //console.log("IDReclamo: " + element['IDReclamo'] + " " + latlong)

          var circle = L.circle(latlong, {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
            radius: 50,
          });

          circle.bindPopup(
            'IDReclamo: ' +
              element['IDReclamo'] +
              ' - Descripción: ' +
              element['descripcion'] +
              ' - Fecha: ' +
              element['fecha']
          );
          claimGroup.push(circle);
          circle.addTo(this.map);
        } else {
          //console.log("IDReclamo: " + element['IDReclamo'] + " LATLONG NULA")
        }
      });

      this.map.fitBounds(L.featureGroup(claimGroup).getBounds());
    });
  };

  setYearsAndMonts(): void {
    this.MachalaService.getYearsDashboard().subscribe((resp) => {
      if (resp && resp.length) {
        this.years = resp.map((anio) => anio.ANIO);
        this.getYear = this.years[0];
        this.setMonts(this.getYear);
      }
    });
  }

  setMonts(value: number): void {
    this.MachalaService.getMonthsDashboard(value).subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.months = resp;
          this.getMonth = this.months[0].NUMBER;
          this.changeIndicatorsDashboard(
            this.getYear,
            this.getMonth,
            this.getType
          );
        }
      },
      (err) => console.log(err)
    );
  }

  changeIndicatorsDashboard(anio: number, mes: string, type: string): void {
    if (
      this.beforeYear !== anio &&
      this.beforeMonth !== mes &&
      this.beforeType !== type
    ) {
      this.setClaimByMountOfYearDashboardTotal(anio);
      this.setGlobalIndicatorsDashboard(anio, mes);
      this.setSumaryClaim(anio, mes);
      this.setClaimByTypeDashboard(anio, mes, type);
      this.setClaimByWeekDashboard(anio, mes, type);
      this.setClaimByDayDashboard(anio, mes, type);
      this.setClaimByMountOfYearDashboard(anio, type);
      this.setClaimFormatDashboard(anio, mes);
      this.setClaimByYearDashboard(mes, type);
      this.getTypeName(type);
    } else if (this.beforeYear !== anio) {
      this.setClaimByMountOfYearDashboardTotal(anio);
      this.setGlobalIndicatorsDashboard(anio, mes);
      this.setSumaryClaim(anio, mes);
      this.setClaimFormatDashboard(anio, mes);
      this.setClaimByTypeDashboard(anio, mes, type);
      this.setClaimByWeekDashboard(anio, mes, type);
      this.setClaimByDayDashboard(anio, mes, type);
      this.setClaimByMountOfYearDashboard(anio, type);
    } else if (this.beforeMonth !== mes) {
      this.setGlobalIndicatorsDashboard(anio, mes);
      this.setSumaryClaim(anio, mes);
      this.setClaimFormatDashboard(anio, mes);
      this.setClaimByTypeDashboard(anio, mes, type);
      this.setClaimByWeekDashboard(anio, mes, type);
      this.setClaimByDayDashboard(anio, mes, type);
      this.setClaimByYearDashboard(mes, type);
    } else if (this.beforeType !== type) {
      this.setClaimByTypeDashboard(anio, mes, type);
      this.setClaimByWeekDashboard(anio, mes, type);
      this.setClaimByDayDashboard(anio, mes, type);
      this.setClaimByYearDashboard(mes, type);
      this.setClaimByMountOfYearDashboard(anio, type);
      this.getTypeName(type);
    }

    this.beforeMonth = mes;
    this.beforeYear = anio;
    this.beforeType = type;
  }

  setSumaryClaim(anio: number, mes: string): void {
    this.MachalaService.getSummaryClaim(anio, mes).subscribe((resp) => {
      this.globalSumary = resp;
    });
  }

  setGlobalIndicatorsDashboard(anio: number, mes: string): void {
    this.MachalaService.getGlobalIndicatorsDashboard(anio, mes).subscribe(
      (resp) => {
        this.globalIndicators = resp;
        this.globalIndicators.metaData.forEach((ind) => {
          if (
            ind.name.toLocaleLowerCase().indexOf('reclamos rechazados') > -1
          ) {
            ind.name = 'Reclamos Anulados';
          }
        });
      }
    );
  }

  setClaimFormatDashboard(anio: number, mes: string): void {
    this.MachalaService.getClaimFormatDashboard(anio, mes).subscribe((resp) => {
      this.formatClaimList = [];
      this.tiposFormato = [];
      this.valoresTiposFormato = resp.rows[0];
      for (var i = 0; i < resp.metaData.length; i++) {
        this.formatClaim = {
          name: '',
          value: 0,
        };
        this.formatClaimList.push(this.formatClaim);
        this.tiposFormato.push(resp.metaData[i].name);
      }
      this.chartOptions = {
        chart: {
          type: 'bar',
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: this.tiposFormato,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total de Reclamos',
          },
        },
        legend: {
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        series: [
          {
            name: 'Reclamos',
            type: 'bar',
            data: this.valoresTiposFormato,
          },
        ],
      };
    });
  }

  setLatLogDashboard(): void {
    this.MachalaService.getLatLogDashboard().subscribe((resp) => {
      const claimGroup = [];

      resp.forEach((element) => {
        if (element && element.length) {
          var latlong = element.map((latlog) => Number(latlog.trim()));
          var circle = L.circle(latlong, {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
            radius: 50,
          });

          claimGroup.push(circle);
          circle.addTo(this.map);
        }
      });

      this.map.fitBounds(L.featureGroup(claimGroup).getBounds());
    });
  }

  setClaimByTypeDashboard(anio: number, mes: string, type: string): void {
    this.MachalaService.getClaimByTypeDashboard(anio, mes, type).subscribe(
      (resp) => {
        console.log(resp);

        this.tiposReclamo = [];
        this.valoresTiposReclamo = [];
        for (var i = 0; i < resp.length; i++) {
          this.tiposReclamo.push(resp[i].name);
          const label = resp[i].value.reduce(
            (sum, data) =>
              `${sum} ${data.label == 'Nulos' ? 'No asignados' : data.label}: ${
                data.total
              } + `,
            ''
          );
          this.valoresTiposReclamo.push([
            label.substring(0, label.length - 2),
            resp[i].value.reduce((sum, data) => data.total + sum, 0),
          ]);
        }
        this.chartOptionsTR = {
          chart: {
            type: 'column',
          },
          title: {
            text: '',
          },
          xAxis: {
            categories: this.tiposReclamo,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Total de Reclamos',
            },
          },
          plotOptions: {
            column: {
              borderRadius: 3,
            },
          },
          tooltip: {
            headerFormat: '',
            pointFormat: '{point.name} = ' + '{point.y}',
          },
          series: [
            {
              name: 'Total',
              type: 'column',
              data: this.valoresTiposReclamo,
            },
          ],
          legend: {
            enabled: false,
          },
        };
      }
    );
  }

  setClaimByWeekDashboard(anio: number, mes: string, type: string): void {
    this.MachalaService.getClaimByWeekDashboard(anio, mes, type).subscribe(
      (resp) => {
        this.claimByWeek = resp;

        this.maxPeakWeek = this.claimByWeek.rows[0].reduce((previus, current) =>
          previus > current ? previus : current
        );
        this.optionWeek = [];
        this.claimByWeek.metaData.forEach((data, i) => {
          this.optionWeek.push(
            this.createOption(data.name, this.claimByWeek.rows[0][i])
          );
        });
      }
    );
  }

  setClaimByYearDashboard(mes: string, type: string) {
    this.MachalaService.getClaimByYearDashboard(mes, type).subscribe((resp) => {
      this.claimsByYear = resp;
      this.yearsClaims = resp.metaData.map((year: any) => year.name.toString());
      this.totalClaimsByYear = resp.rows[0].map((row: any) => row);
      this.chartOptionClaimsByYear = {
        chart: {
          type: 'colum',
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: this.yearsClaims,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total de Reclamos',
          },
        },
        plotOptions: {
          column: {
            borderRadius: 3,
          },
        },
        series: [
          {
            name: 'Años',
            type: 'column',
            data: this.totalClaimsByYear,
          },
        ],
      };
    });
  }

  setClaimByMountOfYearDashboard(year: number, type: string): void {
    this.MachalaService.getClaimByMountOfYearDashboard(year, type).subscribe(
      (resp) => {
        this.mesReclamo = [];
        this.valoresMesReclamo = resp.rows[0];
        for (let i = 0; i < resp.metaData.length; i++) {
          this.mesReclamo.push(resp.metaData[i].name);
        }
        this.chartOptionRM = {
          chart: {
            type: 'bar',
          },
          title: {
            text: '',
          },
          xAxis: {
            categories: this.mesReclamo,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Total de Reclamos',
            },
          },
          legend: {
            reversed: true,
          },
          plotOptions: {
            series: {
              stacking: 'normal',
            },
          },
          series: [
            {
              name: 'Reclamos',
              type: 'bar',
              data: this.valoresMesReclamo,
            },
          ],
        };
      }
    );
  }

  setClaimByMountOfYearDashboardTotal(year: number): void {
    this.MachalaService.getClaimByMountOfYearDashboard(year, 'PD').subscribe(
      (resp) => {
        this.mesReclamo = [];
        this.valoresMesReclamoTotal = resp.rows[0] as number[];
        for (let i = 0; i < resp.metaData.length; i++) {
          this.mesReclamo.push(resp.metaData[i].name);
        }

        this.MachalaService.getClaimByMountOfYearDashboard(
          year,
          'FI'
        ).subscribe((resp) => {
          this.valoresMesReclamoTotal.forEach((valor, i) => {
            this.valoresMesReclamoTotal[i] =
              this.valoresMesReclamoTotal[i] + resp.rows[0][i];
          });

          this.chartOptionRMTO = {
            chart: {
              type: 'bar',
            },
            title: {
              text: '',
            },
            xAxis: {
              categories: this.mesReclamo,
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Total de Reclamos',
              },
            },
            legend: {
              reversed: true,
            },
            plotOptions: {
              series: {
                stacking: 'normal',
              },
            },
            series: [
              {
                name: 'Reclamos',
                type: 'bar',
                data: this.valoresMesReclamoTotal,
              },
            ],
          };
        });
      }
    );
  }

  setClaimByDayDashboard(year: number, mes: string, type: string): void {
    this.MachalaService.getClaimByDayDashboard(year, mes, type).subscribe(
      (resp) => {
        this.diasDesempeno = [];
        this.valoresDiasDesempeno = resp.rows[0];
        for (let i = 0; i < resp.metaData.length; i++) {
          this.diasDesempeno.push(resp.metaData[i].name);
        }
        this.chartOptionsTD = {
          chart: {
            type: 'bar',
          },
          title: {
            text: '',
          },
          xAxis: {
            categories: this.diasDesempeno,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Total de Reclamos',
            },
          },
          legend: {
            reversed: true,
          },
          plotOptions: {
            series: {
              stacking: 'normal',
            },
          },
          series: [
            {
              name: 'Reclamos',
              type: 'bar',
              data: this.valoresDiasDesempeno,
            },
          ],
        };
      }
    );
  }

  createOption(name: string, data: number) {
    const options = {
      chart: {
        type: 'solidgauge',
      },

      title: {
        text: '',
      },
      pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -75,
        endAngle: 75,
        background: {
          backgroundColor: Highcharts.theme || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },

      tooltip: {
        enabled: false,
      },

      yAxis: {
        stops: [
          [0.1, '#55BF3B'],
          [0.5, '#DDDF0D'],
          [0.9, '#DF5353'],
        ],
        min: 0,
        max: this.maxPeakWeek,
        lineWidth: 0,
        minorTickInterval: null,
        tickWidth: 0,
        tickAmount: 2,
        title: {
          y: -60,
        },
        labels: {
          y: 16,
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 0,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },

      series: [
        {
          name: name,
          data: [data],
          dataLabels: {
            y: 20,
            format:
              '<div style="text-align:center"><span style="font-size:25px;color:' +
              (Highcharts.theme || 'black') +
              '">{y}</span><br/>' +
              '<span style="font-size:12px;color:silver">' +
              name +
              '</span></div>',
          },
        },
      ],
    };

    return options;
  }

  ngAfterViewInit(): void {
    const globalHeader: HTMLElement = document.getElementById('globalHeader');
    globalHeader.style.position = 'initial';
  }

  ngOnDestroy(): void {
    const globalHeader: HTMLElement = document.getElementById('globalHeader');
    globalHeader.style.position = 'sticky';
  }

  ngOnInit(): void {
    this.setYearsAndMonts();
    this.initMap();
  }
}
