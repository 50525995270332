import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginData } from '../interfaces/login-data.interface';
import { MachalaAdminService } from './machala-admin.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private machalaAdminService: MachalaAdminService
  ) {}

  public setSession(authResult: LoginData): void {
    localStorage.setItem('accessToken', authResult.accessToken);
  }

  public logOut(): void {
    localStorage.clear();
    this.machalaAdminService.emptyUserMe();
    this.router.navigateByUrl('/login');
  }

  public  verifyToken(): Observable<any> {
    return this.machalaAdminService.getUserMe();
  }
}
