<app-preview-news #modalPreviewNews></app-preview-news>
<div class="container-form mx-auto">
  <div class="text-center title-template">
    <h2 class="border-title d-inline-block" *ngIf="!isEdit">
      <span>Agregar Noticia</span>
    </h2>
    <h2 class="border-title d-inline-block" *ngIf="isEdit">
      <span>Editar Noticia</span>
    </h2>
  </div>
  <form [formGroup]="form" (ngSubmit)="addNews()">
    <div class="form-group">
      <label for="titulo" class="form-title">Título</label>
      <input
        id="titulo"
        type="text"
        name=""
        class="form-control"
        placeholder="Ingrese un Título"
        aria-describedby="helpId"
        formControlName="title"
        [class.is-invalid]="invalidTitle"
      />
      <small class="text-danger" *ngIf="invalidTitle">Ingrese un título.</small>
    </div>
    <div class="form-group">
      <label for="subtitulo " class="form-title">Subtítulo</label>
      <input
        id="subtitulo"
        type="text"
        name=""
        class="form-control"
        placeholder="Ingrese un Subtítulo"
        aria-describedby="helpId"
        formControlName="subtitle"
        [class.is-invalid]="invalidSubtitle"
      />
      <small class="text-danger" *ngIf="invalidSubtitle"
        >Ingrese un subtítulo.</small
      >
    </div>
    <div class="form-group">
      <label for="form-date" class="form-title"
        >Ingrese la fecha de creación de la noticia
      </label>

      <input
        type="date"
        name="fecha"
        id="form-date"
        class="form-control"
        aria-describedby="helpId"
        max="{{ currentDate | date: 'yyyy-MM-dd' }}"
        formControlName="timestamp"
        [class.is-invalid]="invalidTimestamp"
      />
      <small class="text-danger" *ngIf="invalidTimestamp"
        >Ingrese una fecha de creación de la noticia.</small
      >
    </div>
    <div class="form-group">
      <label for="Resumen" class="form-title">Resumen</label>
      <!-- <textarea id='resumen' type="text" class="form-control" placeholder="Ingrese la noticia" aria-describedby="helpId"
        rows="6" formControlName="content" [class.is-invalid]="invalidContent"></textarea> -->

      <div class="editor">
        <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
        </ngx-editor-menu>
        <ngx-editor
          [editor]="editor"
          formControlName="content"
          [class.is-invalid]="invalidContent"
        >
        </ngx-editor>
      </div>
      {{ html }}
      <small class="text-danger" *ngIf="invalidContent"
        >Tiene que ingresar algún contenido.</small
      >
    </div>

    <label class="form-title">Agregar Imágenes</label>
    <div class="form-img">
      <div
        class="form-img__image"
        *ngFor="let item of formArray.controls; let i = index"
      >
        <span
          class="image__delete"
          (click)="deleteImage(i)"
          *ngIf="formArray.controls[i].value"
          ><i class="glyph-icon flaticon-remove"></i
        ></span>

        <label class="file-label-img" for="validatedCustomFile-{{ i }}">
          <div class="label__img" *ngIf="formArray.controls[i].value">
            <img [src]="this.formArray.controls[i].value" alt="" />
          </div>
          <i *ngIf="!loadingImage" class="image__icon flaticon-add-1"></i>
          <div class="loading" *ngIf="loadingImage && indexLoading == i">
            <img src="assets/img/load.gif" alt="" />
          </div>
        </label>

        <input
          type="file"
          class="img-input"
          id="validatedCustomFile-{{ i }}"
          (change)="addImage($event, i)"
          accept="image/jpeg, image/png"
        />
      </div>
      <!-- <div class="invalid-feedback">Example invalid custom file feedback</div> -->
    </div>

    <div class="form-group">
      <label for="form__video" class="form-title mt-3"
        >Agregar Video Youtube</label
      >
      <input
        type="text"
        name=""
        id="form__video"
        class="form-control"
        placeholder="Ingrese el URL del video"
        aria-describedby="helpId"
        formControlName="urlYoutube"
        [class.is-invalid]="invalidURLYoutube"
      />
      <small class="text-danger" *ngIf="invalidURLYoutube">URL invalido.</small>
    </div>
    <div>
      <label class="form-title">Tags de la noticia</label>
    </div>
    <div class="tags-news">
      <div class="tag-news" *ngFor="let tag of tags; let i = index">
        <input
          id="tag-news-{{ i }}"
          type="checkbox"
          name=""
          (click)="addTag($event, i)"
          [checked]="checkedTags.length && checkedTags[i]"
        />
        <label for="tag-news-{{ i }}">{{ tag.tag }}</label>
      </div>
    </div>

    <div class="text-right form-btn">
      <button
        type="button"
        class="btn btn-warning clean-text btn-enviar title-text"
        (click)="openModalPreview()"
      >
        <strong>Preview</strong>
      </button>
      <button
        *ngIf="!isEdit"
        type="submit"
        class="btn btn-success clean-text btn-enviar title-text"
      >
        <strong>Guardar</strong>
      </button>
      <button
        *ngIf="isEdit"
        type="button"
        class="btn btn-primary clean-text btn-enviar title-text"
        (click)="editNews()"
      >
        <strong>Modificar</strong>
      </button>
    </div>
    <div class="d-flex justify-content-center" *ngIf="estaCargando">
      <div class="img-load">
        <img src="assets/img/load.gif" alt="" />
      </div>
    </div>
  </form>
</div>
