<header class="title-text global-header">
  <div class="header">
    <div class="header__logo">
      <div class="cont-logo">
        <img
          src="assets/img/machalaep/img-logo-2.png"
          alt="logo de la página"
          class="logo__img"
        />
      </div>
    </div>
    <div class="small-text header__text background-third">
      <div class="cont-text">
        <p class="" *ngIf="user">
          <strong>{{ user.nombre }}</strong>
          <br />
          {{ user.area }}
        </p>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="navbar-logo">
      <div class="cont-logo">
        <img
          src="assets/img/machalaep/img-logo-2.png"
          alt="logo de la página"
          class="logo__img"
        />
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div class="collapse navbar-collapse normal-text" id="navbarNav">
      <ul #navbarAdmin class="navbar-nav text-secondary" *ngIf="user">

        <li
          class="nav-item"
          *ngFor="let rol of user.roles"
          [routerLinkActive]="['active']"
          [routerLink]="
            '/' + (rol === 'transparencias' ? 'transparencia' : rol)
          "
        >
          <a class="nav-link"
            ><span>{{
              rol === "transparencias" ? "Transparencia" : (rol | titlecase)
            }}</span></a
          >
        </li>
        <!-- <li class="nav-item" [routerLinkActive]="['active']" routerLink="/dashboard">
          <a class="nav-link"><span>Dashboard</span></a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" routerLink="/transparencia">
          <a class="nav-link"><span>Transparencia</span></a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" routerLink="/reclamos">
          <a class="nav-link"><span>Reclamos</span></a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" routerLink="/denuncias">
          <a class="nav-link"><span>Denuncias</span></a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" routerLink="/noticias">
          <a class="nav-link"><span>Noticias</span></a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" routerLink="/logros">
          <a class="nav-link"><span>Logros</span></a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" routerLink="/usuarios">
          <a class="nav-link"><span>Usuarios</span></a>
        </li> -->
       <!--  <li
          class="nav-item "
          role="button"
          [routerLinkActive]="['active']"
          routerLink="/convocatorias"
        >
          <a class="nav-link"><span>Convocatorias</span></a>
        </li>
        <li
          class="nav-item "
          role="button"
          [routerLinkActive]="['active']"
          routerLink="/cargos"
        >
          <a class="nav-link"><span>Cargos</span></a>
        </li> -->
        <li
          class="nav-item border-red"
          (click)="logout()"
          [routerLinkActive]="['active']"
          routerLink="/login"
        >
          <a class="nav-link text-danger"><span>Salir</span></a>
        </li>
      </ul>
    </div>
  </nav>
</header>
