import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { ModalComponent } from '../../common-pages/modal/modal.component';
declare var Notify: any;
import * as L from 'leaflet';
import { icon, Marker } from 'leaflet';
const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-view-complaint',
  templateUrl: './view-complaint.component.html',
  styleUrls: ['./view-complaint.component.scss'],
})
export class ViewComplaintComponent implements OnInit, AfterViewInit {
  mapComplaint: any;
  complaint: any;
  claimsStates = [];
  state = '';
  idState = '-1';
  stateCode = 'CAT';
  reasonClaim = '';
  enterClaim = true;
  changeCode: any;
  @ViewChild('modalComplaint') modalComplaint: ModalComponent;
  typeId = [
    { id: 'CED', value: 'Cedula' },
    { id: 'RUC', value: 'RUC' },
    { id: 'NRO', value: 'Numero de Cuenta' },
  ];
  idLabel = '';

  statesDB = [
    { id: 'CAT', value: 'CATEGORIA' },
    { id: 'FAG', value: 'FUGA DE AGUA' },
    { id: 'DOB', value: 'DOBLE EMISION' },
    { id: 'AFA', value: 'ALTA FACTURACION' },
    //{ id: 'PNI', value: 'PAGO NO IMPUTADO' },
    //{ id: 'PMI', value: 'PAGO MAL IMPUTADO' },
    { id: 'AME', value: 'AGUA EN MAL ESTADO' },
    //{ id: 'RAC', value: 'ROTURA DE ACUEDUCTO' },
    { id: 'IAG', value: 'INEXISTENCIA DE AGUA' },
    { id: 'IGU', value: 'INEXISTENCIA DE GUIA' },
    //{ id: 'CIM', value: 'COBRO INDEBIDO DE MULTAS' },
    { id: 'MRA', value: 'MANT. RED TERCIARIA ALCANT' },
    { id: 'SSV', value: 'SUSPENSION POR SOLAR VACIO' },
    //{ id: 'CII', value: 'COBRO INDEBIDO DE INTERESES' },
    { id: 'CSN', value: 'CONSTRUCCION SUSPENDIDA NUEVA' },
    { id: 'IAL', value: 'INEXISTENCIA DE ALCANTARILLADO' },
    { id: 'CSA', value: 'CONSTRUCCION SUSPENDIDA ANTIGUA' },
    { id: 'VDC', value: 'VIVIENDA DESHABITADA CON SUSPENSION' },
    { id: 'VDS', value: 'VIVIENDA DESHABITADA SIN SUSPENSION' },
  ];
  constructor(
    private activatedRoute: ActivatedRoute,
    private machalaAdminService: MachalaAdminService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.machalaAdminService.getClaimsState().subscribe((resp) => {
      this.claimsStates = resp;
    });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe((resp) => {
      this.getComplaint(resp.id);
    });
  }

  public initMap = (): void => {
    this.mapComplaint = L.map('mapComplaint', {
      center: [-3.25861, -79.96053],
      zoom: 13,
      keyboard: false,
      boxZoom: false,
      touchZoom: false,
      dragging: true,
      scrollWheelZoom: false,
      doubleClickZoom: true,
    });
    const tiles = L.tileLayer(
      'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    tiles.addTo(this.mapComplaint);
    // this.initZonesClaims();
  };

  getComplaint(id: any): void {
    this.initMap();
    this.machalaAdminService.getComplaint(id).subscribe(
      (complaint) => {
        L.marker([
          complaint.latlong.split(',')[0].trim(),
          complaint.latlong.split(',')[1].trim(),
        ]).addTo(this.mapComplaint);
        this.complaint = complaint;
        this.idLabel = this.typeId.filter(
          (resp) => resp.id === complaint.identificador_id_persona
        )[0].value;
      },
      (err) => {
        this.router.navigateByUrl('/denuncias');
      }
    );
  }

  changeState(value: any): void {
    this.idState = value;
  }

  onItemChange(value: any): void {
    this.stateCode = value;
  }

  saveState(): void {
    if (this.idState != '-1') {
      let changeCode: any;
      if (this.idState === '2') {
        changeCode = { estado: this.idState, codigo_reclamo: this.stateCode };
      } else if (this.idState === '3') {
        if (this.reasonClaim) {
          changeCode = {
            estado: this.idState,
            razon_rechazo: this.reasonClaim,
          };
          this.enterClaim = true;
        } else {
          this.enterClaim = false;
        }
      } else {
        changeCode = { estado: this.idState };
      }
      if (this.enterClaim || this.idState !== '3') {
        this.machalaAdminService
          .changeStateClaim(this.complaint.id_reclamo, changeCode)
          .subscribe(
            (resp) => {
              this.getComplaint(this.complaint.id_reclamo);
            },
            (err) => {
              Notify(err.error.message, null, null, 'danger');
            }
          );
      }
    }
  }

  openModal(): void {
    this.modalComplaint.mostrarPopup();
  }
}
