<section class="container normal-text container-create-edit">
    <div class="container-form mx-auto">
        <div class="text-center title-template">
            <h2 class="border-title d-inline-block">
                <span>Asignar Archivo</span>
            </h2>
        </div>
        <form form [formGroup]="form" (ngSubmit)="onSubmit()">
            <label for="selectExample">Selecciona una opción:</label>
            <select class="form-control" id="selectCharge" formControlName="typeArchive">
                <option *ngFor="let item of files" value="{{item.id_archivo}}">{{generateName(item.nombre)}}</option>
            </select>
            <ng-container *ngIf="form.controls['typeArchive'].errors && form.controls['typeArchive'].touched">
                <ng-container *ngIf="form.controls['typeArchive'].hasError('required')">
                    <small class="text-danger">Debe elegir un tipo de archivo.</small>
                </ng-container>
            </ng-container>
            <label class="form-title mt-3">Agregar archivo</label>
            <div class="form-img">
                <div class="form-img">
                    <div class="container mt-3">
                        <input type="file" (change)="onSelectFile('archive',$event)" class="form-control mb-2"
                            accept="application/pdf, application/xlsx">
                    </div>
                </div>
            </div>
            <div class="text-right form-btn mt-5">
                <button type="submit" class="btn btn-success clean-text btn-enviar title-text">
                    <strong>Guardar</strong>
                </button>
            </div>
        </form>
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <div class="img-load">
                <img src="assets/img/load.gif" alt="" />
            </div>
        </div>
        <div class="my-3">
            <hr>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="text-center title-template">
                    <h2 class="border-title d-inline-block">
                        <span>Archivos Cargados</span>
                    </h2>
                </div>
                <div>
                    <div class="table-container">
                        <div class="loading-list">
                            <img src="assets/img/load.gif" alt="" *ngIf="isLoading" />
                        </div>
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th class="table__col-1" scope="col">Archivos</th>
                                    <th class="table__col-1" scope="col">Gestionar</th>
                                </tr>

                            </thead>
                            <tbody class="news_list">
                                <tr *ngFor="let items of files">
                                    <td *ngIf="viewFile(items.url_descarga)" class="ellipsis table__col-1">
                                        {{generateName(items.nombre)}}</td>
                                    <td *ngIf="viewFile(items.url_descarga)">
                                        <a href="{{items.url_descarga}}" role="button" class="btn btn-success" target="_blank" rel="noopener noreferrer">Descargar</a>
                                    </td>
                                </tr>
                              
                            </tbody>
                            <tbody class="news_list" *ngIf="!files.length">
                                <tr *ngFor="let load of newAnnouncementLoading">
                                    <td>
                                        <div class="loading-i"></div>
                                    </td>
                                    <td>
                                        <div class="loading-i"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>