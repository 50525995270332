<!-- <div class="table-responsive-md">
  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th class="col-table-2" scope="col">Cuenta</th>
        <th class="col-table-2" scope="col">Nombre</th>
        <th class="col-table-2" scope="col">Ultima Factura</th>
        <th class="col-table-2" scope="col">Estado</th>
        <th class="col-table-4" scope="col">Gestionar</th>
      </tr>
    </thead>
    <tbody >
      <tr >
        <td class="ellipsis">2012</td>

        <td class="ellipsis">Christian Collaguazo</td>
        <td>6666</td>
        <td class="ellipsis">sin registrar</td>
        <td class="d-flex">
          <button
            type="button"
            class="btn btn-success w-100"
          >
            <span class="pc-text">Registrar</span>
            <span class="movil-icon"><i class="flaticon-view"></i></span>
          </button>
          <button
            type="button"
            class="btn btn-success w-100"
          >
            <span class="pc-text">Pagado</span>
            <span class="movil-icon"><i class="flaticon-view"></i></span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div> -->
<section *ngIf="!loading">
  <div class="list-payment" *ngFor="let transaction of listTransactions">
    <hr />
    <div class="row">
      <div class="col-12">
        <h5 class="mb-1">Usuario</h5>
      </div>
      <div class="col-12 col-md-4">
        <strong>Nombre: </strong> {{ transaction.nombre }}
      </div>
      <div class="col-12 col-md-4">
        <strong>Apellidos: </strong> {{ transaction.apellidos }}
      </div>
      <div class="col-12 col-md-4">
        <strong>Identificación: </strong> {{ transaction.identificacion }}
      </div>
      <div class="col-12">
        <h5 class="mt-2 mb-1">Detalles</h5>
      </div>
      <div class="col-6 col-md-3">
        <strong>Cuenta: </strong> {{ transaction.nro_cuenta }}
      </div>
      <div class="col-6 col-md-3">
        <strong>Fecha: </strong> {{ transaction.fecha | date: "shortDate" }}
      </div>
      <div class="col-6 col-md-3">
        <strong>Total: </strong> {{ transaction.total }}$
      </div>
      <div class="col-6 col-md-3">
        <strong>Estado: </strong> {{ transaction.estado }}
      </div>
      <div class="col-6 col-md-3" *ngIf="transaction.tarjeta">
        <strong>Tarjeta: </strong> {{ transaction.tarjeta }}
      </div>
      <div class="col-12 mt-3" *ngIf="viewOptions">
        <a class="ml-0 btn btn-primary" role="button" (click)="registerPayment(transaction)">Registrar el Pago</a>
        <a class="btn btn-warning" role="button" (click)="markAsPaid(transaction)">Marcar Como Pagado</a>
      </div>
    </div>
  </div>
  <hr />
  <pagination
    *ngIf="total"
    class="pagination-c"
    [totalItems]="total"
    (pageChanged)="getTransactions(state, $event.page - 1)"
    [maxSize]="3"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
  ></pagination>
</section>
<section *ngIf="noData">
  <h1 class="text-center">No existen Registros</h1>
</section>

<section *ngIf="loading">
  <div *ngFor="let item of [1, 1, 1]">
    <hr />
    <div class="row">
      <div class="col-12">
        <h5 class="mb-1">Usuario</h5>
      </div>
      <div class="col-12 col-md-4 load">
        <strong>Nombre: </strong>
        <div class="loading-i"></div>
      </div>
      <div class="col-12 col-md-4 load">
        <strong>Apellidos: </strong>
        <div class="loading-i"></div>
      </div>
      <div class="col-12 col-md-4 load">
        <strong>Identificación: </strong>
        <div class="loading-i"></div>
      </div>
      <div class="col-12">
        <h5 class="mt-2 mb-1">Detalles</h5>
      </div>
      <div class="col-6 col-md-3 load">
        <strong>Cuenta: </strong>
        <div class="loading-i"></div>
      </div>
      <div class="col-6 col-md-3 load">
        <strong>Fecha: </strong>
        <div class="loading-i"></div>
      </div>
      <div class="col-6 col-md-3 load">
        <strong>Total: </strong>
        <div class="loading-i"></div>
      </div>
      <div class="col-6 col-md-3 load">
        <strong>Estado: </strong>
        <div class="loading-i"></div>
      </div>
    </div>
  </div>
  <hr />
</section>
