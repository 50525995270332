import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { UserLogin } from '../../../helpers/interfaces/user-login.interface';
import { LoginData } from '../../../helpers/interfaces/login-data.interface';
import { AuthService } from '../../../helpers/services/auth.service';
declare var Notify: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  estaCargando = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private machalaAdminService: MachalaAdminService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // this.machalaAdminService.getDataServer().subscribe((resp) => {

    // });
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    // this.machalaAdminService.getTest().subscribe(resp => {
    //   console.log(resp);
    // });
  }

  logIn(): void {
    if (this.form.valid) {
      this.estaCargando = true;
      const user: UserLogin = {
        email: this.form.get('email').value,
        password: this.form.get('password').value,
      };
      this.machalaAdminService.loginUser(user).subscribe(
        (userData: LoginData) => {
          this.authService.setSession(userData);
          this.router.navigateByUrl(
            '/' +
              (userData.roles[0] === 'transparencias'
                ? 'transparencia'
                : userData.roles[0])
          );
          this.estaCargando = false;
        },
        (err) => {
          this.estaCargando = false;
          console.warn(err);
          Notify(err.error.message, null, null, 'danger');
        }
      );
    } else {
      Notify('Revise la información ingresada.', null, null, 'danger');
      this.invalidForm();
    }
  }

  get invalidEmail(): boolean {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }

  get invalidPassword(): boolean {
    return (
      this.form.get('password').invalid && this.form.get('password').touched
    );
  }

  invalidForm(): void {
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }
  }
}
