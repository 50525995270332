<section class="news-global-container container normal-text">
    <div class="title-news">
        <div class="title__add-news" title="Crear Cargo">
            <i routerLink="/cargos/agregar-cargo" class="glyph-icon flaticon-add large-text"></i>
        </div>
        <h2 class="border-title"><span>Cargos</span></h2>
    </div>
    <div *ngIf="!noRecords">
        <div class="table-container">
            <div class="loading-list">
                <img src="assets/img/load.gif" alt="" *ngIf="loadingList" />
              </div>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th class="table__col-1" scope="col">Cargo</th>
                        <th class="table__col-2" scope="col">Gestionar</th>
                    </tr>

                </thead>
                <tbody class="news_list">
                    <tr *ngFor="let items of charge">
                        <td class="ellipsis table__col-1">{{items.nombre}}</td>
                        <td class="d-flex">

                            
                            <button 
                                type="button" 
                                class="btn btn-warning"
                                (click)="editCharge(items.id_cargo)"
                                >
                                <span class="pc-text">Editar</span>
                                <span class="movil-icon"><i class="glyph-icon flaticon-edit"></i></span>
                            </button>
                            <button 
                                type="button" 
                                class="btn btn-danger"
                                (click)="openModal(items.id_cargo)" 
                                >
                                <span class="pc-text">Eliminar</span>
                                <span class="movil-icon"><i class="glyph-icon flaticon-delete"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
                 <tbody class="news_list" *ngIf="!charge.length">
                      <tr *ngFor="let load of newChargesLoading">
                        <td>
                          <div class="loading-i"></div>
                        </td>
                        <td>
                          <div class="loading-i"></div>
                        </td>
                      </tr>
                    </tbody>
            </table>
        </div>
    </div>
</section>
<app-modal 
    #modalDeleteCharge 
    [esUsado]="'delete-news'" 
    [header]="'Eliminar'"
    [body]="'Está seguro que desea eliminar el cargo.'" [msjBotonAcep]="'Eliminar'"
    [msjBotonCan]="'Cancelar'" 
    (acepto)="deleteCharge()"
></app-modal>