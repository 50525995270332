<section class="news-global-container container normal-text">
    <div class="title-news">
        <div class="title__add-news" title="Crear Convocatoria">
            <i routerLink="/convocatorias/agregar-convocatoria" class="glyph-icon flaticon-add large-text"></i>
        </div>
        <h2 class="border-title"><span>Convocatorias</span></h2>
    </div>
    <div *ngIf="!noRecords">
      <div class="table-container">
        <div class="loading-list">
          <img src="assets/img/load.gif" alt="" *ngIf="loadingList" />
        </div>
          <table class="table">
              <thead class="thead-dark">
                  <tr>
                    <th class="table__col-1" scope="col">Convocatoria</th>
                    <th class="table__col-2" scope="col">Gestionar</th>
                  </tr>
                  
                </thead>
                <tbody class="news_list" >
                  <tr *ngFor="let items of announcement">
                      <td class="ellipsis table__col-1">{{items.titulo}}</td>
                      <td class="d-flex">
                        <button
                          type="button"
                          class="btn btn-success"
                          (click)="assignChargeAnnouncement(items.id_convocatoria)"
                        >
                          <span class="pc-text">Cargos</span>
                          <span class="movil-icon"
                            ><i class="glyph-icon flaticon-edit"></i
                          ></span>
                        </button>
                       
                        <button
                          type="button"
                          class="btn btn-warning"
                          (click)="editAnnouncement(items.id_convocatoria)"
                        >
                          <span class="pc-text">Editar</span>
                          <span class="movil-icon"
                            ><i class="glyph-icon flaticon-edit"></i
                          ></span>
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          (click)="openModal(items.id_convocatoria)" 
                        >
                          <span class="pc-text">Eliminar</span>
                          <span class="movil-icon"
                            ><i class="glyph-icon flaticon-delete"></i
                          ></span>
                        </button>
                      </td>
                    </tr>
                </tbody>
                <tbody class="news_list" *ngIf="!announcement.length">
                  <tr *ngFor="let load of newAnnouncementLoading">
                    <td>
                      <div class="loading-i"></div>
                    </td>
                    <td>
                      <div class="loading-i"></div>
                    </td>
                  </tr>
                </tbody>
          </table>
      </div>
    </div>
</section>
<app-modal
  #modalDeleteAnnouncement
  [esUsado]="'delete-news'"
  [header]="'Eliminar'"
  [body]="'Está seguro que desea eliminar la convocatoria.'"
  [msjBotonAcep]="'Eliminar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="deleteAnnouncement()"
></app-modal>