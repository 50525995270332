import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { NewsObtained } from '../../../helpers/interfaces/news-obtained.interface';
import { TemplateFormNewsComponent } from '../template-form-news/template-form-news.component';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.scss'],
})
export class EditNewsComponent implements OnInit, AfterViewInit {
  newsObtained: NewsObtained;
  @ViewChild('editNews') editNews: TemplateFormNewsComponent;
  constructor(
    private activatedRoute: ActivatedRoute,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe((data) => {
      this.machalaAdminService.getNewsById(data.id).subscribe((news) => {
        this.newsObtained = news;
        console.log(this.newsObtained);

        this.editNews.fillFields(this.newsObtained);
      });
    });
  }
}
