<!-- <app-view-claim #viewClaim></app-view-claim> -->
<section
  class="transparency-global-container container-fluid container-md normal-text"
>
  <div class="title-news">
    <i></i>
    <h2 class="border-title"><span>Transparencia</span></h2>
  </div>

  <div>
    <app-add-documents
      #addTransparencyComponent
      (reloadList)="reloadList()"
      (closeOthers)="closeOthers('u')"
    ></app-add-documents>
  </div>
  <div>
    <app-update-transparency
      #updateTransparencyComponent
      (reloadList)="reloadList()"
    ></app-update-transparency>
  </div>

  <div class="list-docs">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th width="60%" scope="col">Nombre</th>
          <th width="30%" scope="col">Fecha</th>
          <th width="10%" scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="listTransparency && listTransparency.length">
        <tr
          *ngFor="
            let item of listTransparency
              | paginate: { itemsPerPage: 10, currentPage: p }
          "
        >
          <td
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            <a [href]="item.url" target="_blank">{{ item.nombre }}</a>
          </td>
          <td>{{ item.mes }}/{{ item.anio }}</td>
          <td class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-danger"
              (click)="saveIdDeleteTransparency(item.id)"
            >
              <!-- <span class="pc-text">Editar</span>
              <span class="movil-icon"><i class="flaticon-view"></i></span> -->
              <i class="glyph-icon flaticon-delete"></i>
            </button>
            <button
              type="button"
              class="btn btn-success"
              (click)="updateTransparency(item)"
              [ngxScrollTo]="'#comp-upd-trans'"
            >
              <!-- <span class="pc-text">Editar</span>
              <span class="movil-icon"><i class="flaticon-view"></i></span> -->
              <i class="glyph-icon flaticon-edit"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody
        class="trans_list"
        *ngIf="listTransparency && !listTransparency.length"
      >
        <tr *ngFor="let load of transLoading">
          <td>
            <div class="loading-i"></div>
          </td>
          <td>
            <div class="loading-i"></div>
          </td>
          <td>
            <div class="loading-i"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      [previousLabel]="''"
      [nextLabel]="''"
      [maxSize]="5"
      class="my-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
  </div>
  <section>
    <div class="title-news mt-4" id="comp-upd-accountability">
      <i></i>
      <h2 class="border-title"><span>Rendición de Cuentas</span></h2>
    </div>
    <div>
      <app-add-accountability
        #addAccountabilityComponent
        (reloadList)="getAccountabilities()"
        (closeOthers)="closeOthers('ua')"
      ></app-add-accountability>
    </div>
    <div>
      <app-update-accountability
        #updateAccountabilityComponent
        (reloadList)="getAccountabilities()"
      ></app-update-accountability>
    </div>
    <div class="documents-accountability mt-4">
      <div
        class="acco-container"
        *ngFor="let accountability of accountabilities"
      >
        <div class="acco-year mb-4">
          <h4 class="border-title">
            <span>{{ accountability.anio }}</span>
          </h4>
        </div>

        <div
          class="acco-doc"
          *ngFor="let document of accountability.documentos"
        >
          <ul>
            <li class="normal-text">
              <h6 class="d-flex">
                <div style="width: 50%">
                  <a [href]="document.url">{{ document.nombre }}</a>
                </div>
                <div class="text-right" style="width: 50%">
                  <button
                    type="button"
                    class="btn btn-danger"
                    (click)="
                      saveIdDeleteAccountability(document.id_rendicioncuentas)
                    "
                  >
                    <i class="glyph-icon flaticon-delete"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-success"
                    [ngxScrollTo]="'#comp-upd-accountability'"
                    [ngxScrollToOffset]="-144"
                    (click)="updateAccountability(document)"
                  >
                    <i class="glyph-icon flaticon-edit"></i>
                  </button>
                </div>
              </h6>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section id="comp-upd-docs">
    <div class="title-news mt-4" id="comp-upd-accountability">
      <i></i>
      <h2 class="border-title"><span>Otros Documentos</span></h2>
    </div>
    <div>
      <app-add-other-documents
        #addFileComponent
        (reloadList)="reloadOtherDocuments()"
        (closeOthers)="closeOthers('uod')"
      ></app-add-other-documents>
    </div>
    <div>
      <app-update-other-documents
        #updateFileComponent
        (reloadList)="reloadOtherDocuments()"
      ></app-update-other-documents>
    </div>
    <div class="list-docs">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th width="30%" scope="col">Nombre</th>
            <th width="50%" scope="col">URL</th>
            <th width="20%" scope="col"></th>
          </tr>
        </thead>
        <tbody *ngIf="!loadDocuments">
          <tr
            *ngFor="
              let item of listDocuments
                | paginate
                  : { id: 'other-documents', itemsPerPage: 10, currentPage: p }
            "
          >
            <td>
              <a [href]="item.url" target="_blank">{{ item.nombre }}</a>
            </td>
            <td>
              <button
                class="btn btn-outline-primary"
                ngxClipboard
                [cbContent]="item.url"
                (click)="notifyCopy()"
              >
                <i class="fas fa-clipboard"></i></button
              >{{ item.url }}
            </td>
            <td class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-danger"
                (click)="saveIdDeleteDocument(item.id_archivo)"
              >
                <!-- <span class="pc-text">Editar</span>
                <span class="movil-icon"><i class="flaticon-view"></i></span> -->
                <i class="glyph-icon flaticon-delete"></i>
              </button>
              <button
                type="button"
                class="btn btn-success"
                (click)="updateDocument(item)"
                [ngxScrollTo]="'#comp-upd-docs'"
              >
                <!-- <span class="pc-text">Editar</span>
                <span class="movil-icon"><i class="flaticon-view"></i></span> -->
                <i class="glyph-icon flaticon-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody class="trans_list" *ngIf="loadDocuments">
          <tr *ngFor="let load of transLoading">
            <td>
              <div class="loading-i"></div>
            </td>
            <td>
              <div class="loading-i"></div>
            </td>
            <td>
              <div class="loading-i"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        *ngIf="listDocuments && listDocuments.length"
        [id]="'other-documents'"
        [previousLabel]="''"
        [nextLabel]="''"
        [maxSize]="5"
        class="my-pagination"
        (pageChange)="p = $event"
      ></pagination-controls>
      <h3
        class="text-center"
        *ngIf="listDocuments && !listDocuments.length && !loadDocuments"
      >
        Aun no existen documentos
      </h3>
    </div>
  </section>
</section>

<app-modal
  #modalDeleteTransparency
  [esUsado]="'delete-transparency'"
  [header]="'Eliminar'"
  [body]="'Está seguro que desea eliminar el documento.'"
  [msjBotonAcep]="'Eliminar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="deleteTransparency()"
></app-modal>

<app-modal
  #modalDeleteAccountability
  [esUsado]="'delete-accountability'"
  [header]="'Eliminar'"
  [body]="'Está seguro que desea eliminar el documento.'"
  [msjBotonAcep]="'Eliminar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="deleteAccountability()"
></app-modal>

<app-modal
  #modalDeleteDocument
  [esUsado]="'delete-document'"
  [header]="'Eliminar'"
  [body]="'Está seguro que desea eliminar el documento.'"
  [msjBotonAcep]="'Eliminar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="deleteDocument()"
></app-modal>
