<section class="container normal-text container-create-edit">
    <div class="container-form mx-auto">
        <div class="text-center title-template">
            <h2 class="border-title d-inline-block">
                <span>Asignar Cargos</span>
            </h2>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <label for="selectExample">Selecciona una opción:</label>
            <select class="form-control" id="selectCharge" formControlName="id_cargo" >
                <option *ngFor="let item of not_assigned" value="{{item.id_cargo}}">{{item.nombre}}</option>
                
              
            </select>
            <ng-container *ngIf="form.controls['id_cargo'].errors && form.controls['id_cargo'].touched">
                <ng-container *ngIf="form.controls['id_cargo'].hasError('required')">
                    <small class="text-danger">El título es requerido.</small>
                </ng-container>
            </ng-container>
            <div class="text-right form-btn mt-5">
                <button type="submit" class="btn btn-success clean-text btn-enviar title-text">
                    <strong>Guardar</strong> 
                </button>
            </div>
        </form>
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <div class="img-load">
                <img src="assets/img/load.gif" alt="" />
            </div>
        </div>
        <div class="my-3" >
            <hr>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="text-center title-template">
                    <h2 class="border-title d-inline-block">
                        <span>Cargos Asignados</span>
                    </h2>
                </div>
                <div *ngIf="!noRecords">
                    <div class="table-container">
                      <div class="loading-list">
                        <img src="assets/img/load.gif" alt="" *ngIf="isLoading" />
                      </div>
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                  <th class="table__col-1" scope="col">Cargos</th>
                                  <th class="table__col-2" scope="col">Gestionar</th>
                                </tr>
                                
                              </thead>
                              <tbody class="news_list" >
                                <tr *ngFor="let items of assigned">
                                    <td class="ellipsis table__col-1">{{items.nombre}}</td>
                                    <td class="d-flex">
                                      <button 
                                      type="button" 
                                      class="btn btn-success"
                                      (click)="addFile(items.id_convocatoria_cargo)"
                                      >
                                      <span class="pc-text">Archivo</span>
                                      <span class="movil-icon"><i class="glyph-icon flaticon-edit"></i></span>
                                  </button>
                                      <button
                                        type="button"
                                        class="btn btn-danger"
                                        (click)="openModal(items.id_convocatoria_cargo)" 
                                      >
                                        <span class="pc-text">Eliminar</span>
                                        <span class="movil-icon"
                                          ><i class="glyph-icon flaticon-delete"></i
                                        ></span>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                              <tbody class="news_list" *ngIf="!assigned.length">
                                <tr *ngFor="let load of newAnnouncementLoading">
                                  <td>
                                    <div class="loading-i"></div>
                                  </td>
                                  <td>
                                    <div class="loading-i"></div>
                                  </td>
                                </tr>
                              </tbody>
                        </table>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</section>
<app-modal 
    #modalDeleteChargeAnnouncement 
    [esUsado]="'delete-news'" 
    [header]="'Eliminar'"
    [body]="'Está seguro que desea eliminar el cargo.'" [msjBotonAcep]="'Eliminar'"
    [msjBotonCan]="'Cancelar'" 
    (acepto)="deleteCharge()"
></app-modal>