<section
  class="claims-global-container container-fluid container-lg normal-text"
>
  <div class="title-news">
    <i></i>
    <h2 class="border-title"><span>Pagos pendientes</span></h2>
  </div>
  <section>
    <span class="box" style="background-color: #0b6097"></span>
    <strong>Registrar el Pago:</strong> Registra en el sistema de aguas Machala
    el pago en caso de algún error en la página principal. Nota: La caja debe
    estar abierta para el registro, por lo que el pago solo se puede registrar
    dentro del horario de cobro.
    <br />
    <span class="box" style="background-color: #ffc107"></span>
    <strong>Marcar como pagado: </strong> Marca como pagado el registro en la
    base de datos. Antes de realizar esta operación debe comprobar o registrar
    el pago del cliente en el sistema de Aguas Machala.
    <app-payments-list
      #pagosPendientes
      [viewOptions]="true"
      (reload)="reloadLists()"
    ></app-payments-list>
  </section>

  <div class="title-news">
    <i></i>
    <h2 class="border-title"><span>Pagos Finalizados</span></h2>
  </div>
  <section>
    <app-payments-list #pagados></app-payments-list>
  </section>

  <div class="title-news">
    <i></i>
    <h2 class="border-title"><span>No Pagados</span></h2>
  </div>
  <section>
    <app-payments-list #noPagados></app-payments-list>
  </section>
</section>
