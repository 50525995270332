import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ValidatorsService } from '../../../helpers/services/validators.service';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { Rol } from '../../../helpers/interfaces/rol.interface';
import { EditUser } from 'src/app/helpers/interfaces/edit-user.interface';
import { ModalComponent } from '../../common-pages/modal/modal.component';

declare var Notify: any;

interface UserEdit {
  id: string;
  nombre: string;
  email: string;
  area: string;
  roles: string[];
}

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  form: FormGroup;
  estaCargando = false;
  passEdit = false;
  checked = [];
  screms: Rol[] = [];
  idUser = -1;
  @Output() viewUsers = new EventEmitter<boolean>();
  @Output() reloadUser = new EventEmitter<boolean>();
  @ViewChild('modalDeleteUser') modalDeleteUser: ModalComponent;
  viewForm = false;
  constructor(
    private formBuilder: FormBuilder,
    private validatorsService: ValidatorsService,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {}

  initializerForm(): void {
    this.checked = [];
    this.screms = [];
    this.idUser = -1;
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        pass1: [{ value: '', disabled: true }],
        pass2: [{ value: '', disabled: true }],
        area: ['Comercial', [Validators.required]],
        views: new FormArray([]),
      },
      {
        validators: this.validatorsService.equalPassUpdate('pass1', 'pass2'),
      }
    );
  }

  onCheckChange(event: any): void {
    const formArray: FormArray = this.form.get('views') as FormArray;
    if (!formArray.touched) {
      formArray.markAllAsTouched();
    }

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  editUser(): void {
    if (this.form.valid) {
      const user: EditUser = {
        id_usuario: this.idUser,
        nombre: this.form.get('name').value,
        email: this.form.get('email').value,
        password: this.form.get('pass1').value,
        area: this.form.get('area').value,
        roles: [],
      };
      user.password = user.password.length ? user.password : undefined;
      const controls = (this.form.get('views') as FormArray).controls;
      for (const control of controls) {
        user.roles.push({ codigo: control.value });
      }
      this.estaCargando = true;
      this.machalaAdminService.putUser(user).subscribe(
        (resp) => {
          this.estaCargando = false;
          this.reloadUser.emit(true);
          Notify('Usuario modificado correctamente.', null, null, 'success');
        },
        (err) => {
          this.estaCargando = false;
          Notify(
            'Error al modificar, revise la información ingresada.',
            null,
            null,
            'danger'
          );
        }
      );
    } else {
      Notify('Revise la información ingresada.', null, null, 'danger');
      this.validForm();
    }
  }

  viewListUsers(): void {
    this.viewForm = false;
    this.viewUsers.emit(true);
  }

  modifyUser(id: number): void {
    this.initializerForm();
    this.idUser = id;
    this.viewForm = true;
    this.machalaAdminService.getRoles().subscribe((roles: Rol[]) => {
      this.screms = roles;
      this.machalaAdminService.getUserById(id).subscribe((user: UserEdit) => {
        this.form.get('name').setValue(user.nombre);
        this.form.get('email').setValue(user.email);
        this.form.get('area').setValue(user.area);
        const formArray: FormArray = this.form.get('views') as FormArray;
        for (const rol of roles) {
          let equals = false;
          for (const rolUser of user.roles) {
            if (rolUser === rol.nombre) {
              formArray.push(new FormControl(rol.codigo));
              equals = true;
              break;
            }
          }
          this.checked.push(equals);
        }
      });
    });
  }

  deleteUser(): void {
    if (this.idUser != -1) {
      this.machalaAdminService.deleteUser(this.idUser).subscribe(
        (resp) => {
          this.reloadUser.emit(true);
          Notify('Usuario eliminado correctamente.', null, null, 'success');
        },
        (err) => {
          Notify('Error al eliminar, intente de nuevo.', null, null, 'danger');
        }
      );
    }
  }

  editPass(): void {
    if (!this.passEdit) {
      this.passEdit = true;
      this.form.get('pass1').enable();
      this.form.get('pass2').enable();
    } else {
      this.passEdit = false;
      this.form.get('pass1').setValue('');
      this.form.get('pass2').setValue('');
      this.form.get('pass1').disable();
      this.form.get('pass2').disable();
    }
  }

  get invalidName(): boolean {
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get invalidEmail(): boolean {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }

  get invalidArea(): boolean {
    return this.form.get('area').invalid && this.form.get('area').touched;
  }

  get invalidPass1(): boolean {
    return this.form.get('pass1').invalid && this.form.get('pass1').touched;
  }

  get invalidPass2(): boolean {
    // console.log(this.form.get('pass2'));

    return this.form.get('pass2').invalid && this.form.get('pass2').touched;
  }

  get invalidViews(): boolean {
    const controls = (this.form.get('views') as FormArray).controls;
    const touched = (this.form.get('views') as FormArray).touched;
    return controls.length === 0 && touched;
  }

  validForm(): void {
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) =>
        control.markAllAsTouched()
      );
    }
  }

  openModal(): void {
    this.modalDeleteUser.mostrarPopup();
  }
}
