import { FileUpdate } from './../../../helpers/interfaces/file-update.interface';

import { UploadFilesService } from './../../../helpers/services/upload-files.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
declare var Notify: any;

@Component({
  selector: 'app-update-other-documents',
  templateUrl: './update-other-documents.component.html',
  styleUrls: ['./update-other-documents.component.scss'],
})
export class UpdateOtherDocumentsComponent implements OnInit {
  formIsActive = false;
  formTransparency: FormGroup;
  file: File;
  loadNumber = 0;
  loadingFile = false;
  idUpdate = -1;
  isLoading = false;
  @ViewChild('formContainer') formContainer: ElementRef<HTMLElement>;
  @Output() reloadList = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private uploadFilesService: UploadFilesService,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {
    this.reloadAtributes();
  }

  saveFile() {
    this.isLoading = true;
    if (this.formTransparency.valid) {
      if (this.file) {
        this.loadingFile = true;
        const url = 'https://www.aguasmachala.com:3000/api/v1/file/files/';
        let name: string = this.formTransparency.get('filePath').value;
        name = decodeURI(name.substring(url.length));
        const blob = this.file.slice(0, this.file.size, this.file.type);
        const newFile = new File([blob], name, { type: this.file.type });

        this.uploadFilesService.upload(newFile).subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              console.log((100 * event.loaded) / event.total);
              this.loadNumber = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              this.formTransparency.get('filePath').setValue(event.body.url);
              this.loadingFile = false;
              this.formTransparency.get('file').enable();
              const newTranparency: FileUpdate = {
                id_archivo: this.idUpdate,
                url: this.formTransparency.get('filePath').value,
                nombre: this.formTransparency.get('name').value,
              };
              this.updateFile(newTranparency);
            }
          },
          (error) => {
            this.formTransparency.get('file').enable();
            this.loadingFile = false;
            this.isLoading = false;
            this.loadNumber = 0;
            try {
              if (error.error && error.error.message) {
                Notify(error.error.message, null, null, 'danger');
              } else {
                Notify(
                  'Error el archivo pesa mas de 50gb',
                  null,
                  null,
                  'danger'
                );
              }
            } catch (error) {
              Notify('Error el archivo pesa mas de 50gb', null, null, 'danger');
            }
          }
        );
      } else {
        const newTranparency: FileUpdate = {
          id_archivo: this.idUpdate,
          url: this.formTransparency.get('filePath').value,
          nombre: this.formTransparency.get('name').value,
        };
        this.updateFile(newTranparency);
      }
    } else {
      this.validateForm();
      this.formTransparency.get('file').enable();
      this.loadingFile = false;
      this.isLoading = false;
      this.loadNumber = 0;
    }
  }

  updateFile(newTranparency: FileUpdate): void {
    this.machalaAdminService
      .updateFile(
        newTranparency.id_archivo,
        newTranparency.nombre,
        newTranparency.url
      )
      .subscribe(
        (resp) => {
          this.reloadList.emit(true);
          this.activeForm();
          this.isLoading = false;
          Notify('Registro modificado correctamente.', null, null, 'success');
        },
        (err) => {
          Notify(
            'Surgio un error al realizar la petición al servidor.',
            null,
            null,
            'danger'
          );

          this.isLoading = false;
        }
      );
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text;
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      this.formTransparency.get('file').disable();
      const file = event.target.files[0];
      this.file = file;
    }
  }

  activeForm(): void {
    this.formIsActive = !this.formIsActive;
    const form = this.formContainer.nativeElement.getElementsByClassName(
      'form-transparency'
    )[0] as HTMLElement;
    if (!this.formIsActive) {
      this.formContainer.nativeElement.style.height = '0';
      this.reloadAtributes();
    } else {
      this.formContainer.nativeElement.style.height =
        form.clientHeight + 114 + 'px';
    }
  }

  activeFormUpload(): void {
    if (!this.formIsActive) {
      this.formIsActive = true;
      const form = this.formContainer.nativeElement.getElementsByClassName(
        'form-transparency'
      )[0] as HTMLElement;
      this.formContainer.nativeElement.style.height =
        form.clientHeight + 114 + 'px';
    }
    this.reloadAtributes();
  }

  reloadAtributes(): void {
    this.idUpdate = -1;
    this.file = undefined;
    this.loadNumber = 0;
    this.formTransparency = this.formBuilder.group({
      name: ['', Validators.required],
      file: [''],
      filePath: ['', Validators.required],
    });
    this.untouchedForm();
  }

  get invalidName(): boolean {
    return (
      this.formTransparency.get('name').invalid &&
      this.formTransparency.get('name').touched
    );
  }
  get invalidFile(): boolean {
    return (
      this.formTransparency.get('file').invalid &&
      this.formTransparency.get('file').touched
    );
  }

  fillForm(id: number): void {}

  validateForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAllAsTouched();
        }
      );
    }
  }

  untouchedForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAsUntouched();
        }
      );
    }
  }

  uploadFile(transparency: FileUpdate): void {
    this.activeFormUpload();
    this.idUpdate = transparency.id_archivo;
    this.formTransparency.get('name').setValue(transparency.nombre);
    this.formTransparency.get('filePath').setValue(transparency.url);
  }
}
