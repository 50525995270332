import { AddAccountabilityComponent } from './../add-accountability/add-accountability.component';
import { AddDocumentsComponent } from './../add-documents/add-documents.component';
import { UpdateTransparencyComponent } from './../update-transparency/update-transparency.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { TransparencyList } from 'src/app/helpers/interfaces/transparency-list.interface';
import {
  AccountabilityList,
  YearAccountability,
} from 'src/app/helpers/interfaces/accountability.interface';
import { UpdateAccountabilityComponent } from '../update-accountability/update-accountability.component';
import { Accountability } from 'src/app/helpers/interfaces/transparency.insterface';
import { ModalComponent } from '../../common-pages/modal/modal.component';
import { AddOtherDocumentsComponent } from '../add-other-documents/add-other-documents.component';
import { UpdateOtherDocumentsComponent } from '../update-other-documents/update-other-documents.component';
import { FileUpdate } from 'src/app/helpers/interfaces/file-update.interface';

declare var Notify: any;

@Component({
  selector: 'app-transparency',
  templateUrl: './transparency.component.html',
  styleUrls: ['./transparency.component.scss'],
})
export class TransparencyComponent implements OnInit {
  listTransparency: TransparencyList[] = [];
  listDocuments: any = [];
  p: number = 1;
  transLoading = [];
  accountabilities: YearAccountability[] = [];
  @ViewChild('updateTransparencyComponent')
  updateTransparencyComponent: UpdateTransparencyComponent;
  @ViewChild('addTransparencyComponent')
  addTransparencyComponent: AddDocumentsComponent;
  @ViewChild('updateAccountabilityComponent')
  updateAccountabilityComponent: UpdateAccountabilityComponent;
  @ViewChild('addAccountabilityComponent')
  addAccountabilityComponent: AddAccountabilityComponent;
  @ViewChild('updateFileComponent')
  updateFileComponent: UpdateOtherDocumentsComponent;
  @ViewChild('addFileComponent')
  addFileComponent: AddOtherDocumentsComponent;

  @ViewChild('modalDeleteTransparency') modalDeleteTransparency: ModalComponent;
  @ViewChild('modalDeleteDocument') modalDeleteDocument: ModalComponent;
  @ViewChild('modalDeleteAccountability')
  modalDeleteAccountability: ModalComponent;
  idDeleteTransparency = -1;
  idDeleteAccountability = '-1';
  idDeleteDocument = -1;

  loadDocuments = false;
  constructor(private machalaAdminService: MachalaAdminService) {}

  ngOnInit(): void {
    this.transLoading = Array(10).fill('');
    this.reloadList();
    this.getAccountabilities();
    this.reloadOtherDocuments();
  }

  updateTransparency(transparency: TransparencyList): void {
    this.closeOthers('a');
    this.updateTransparencyComponent.uploadFile(transparency);
  }

  updateAccountability(accountability: AccountabilityList): void {
    this.closeOthers('aa');
    this.updateAccountabilityComponent.uploadFile(accountability);
  }

  updateDocument(accountability: FileUpdate): void {
    this.closeOthers('aod');
    this.updateFileComponent.uploadFile(accountability);
  }

  reloadList(): void {
    this.machalaAdminService.getTransparency().subscribe(
      (resp) => {
        this.p = 1;
        this.listTransparency = resp;
      },
      (err) => {}
    );
  }

  reloadOtherDocuments(): void {
    this.loadDocuments = true;
    this.machalaAdminService.getAllFiles().subscribe(
      (resp) => {
        this.listDocuments = resp;
        console.log(resp);

        this.loadDocuments = false;
      },
      (err) => {
        this.loadDocuments = false;
      }
    );
  }

  closeOthers(type: string): void {
    switch (type) {
      case 'u':
        if (this.updateTransparencyComponent.formIsActive) {
          this.updateTransparencyComponent.activeForm();
        }
        break;
      case 'a':
        if (this.addTransparencyComponent.formIsActive) {
          this.addTransparencyComponent.activeForm();
        }
        break;
      case 'ua':
        if (this.updateAccountabilityComponent.formIsActive) {
          this.updateAccountabilityComponent.activeForm();
        }
        break;
      case 'aa':
        if (this.addAccountabilityComponent.formIsActive) {
          this.addAccountabilityComponent.activeForm();
        }
        break;
      case 'aod':
        if (this.addFileComponent.formIsActive) {
          this.addFileComponent.activeForm();
        }
        break;
      case 'uod':
        if (this.updateFileComponent.formIsActive) {
          this.updateFileComponent.activeForm();
        }
        break;
    }
  }

  deleteTransparency(): void {
    this.machalaAdminService
      .deleteTransparency(this.idDeleteTransparency)
      .subscribe(
        (resp) => {
          Notify('Registro eliminado correctamente.', null, null, 'success');
          this.reloadList();
        },
        (err) => {
          Notify(
            'Surgio un error al realizar la petición al servidor.',
            null,
            null,
            'danger'
          );
        }
      );
  }

  saveIdDeleteTransparency(id: number): void {
    this.idDeleteTransparency = id;
    this.modalDeleteTransparency.mostrarPopup();
  }
  deleteAccountability(): void {
    this.machalaAdminService
      .deleteAccountability(this.idDeleteAccountability)
      .subscribe(
        (resp) => {
          Notify('Registro eliminado correctamente.', null, null, 'success');
          this.getAccountabilities();
        },
        (err) => {
          Notify(
            'Surgio un error al realizar la petición al servidor.',
            null,
            null,
            'danger'
          );
        }
      );
  }

  saveIdDeleteDocument(id: number): void {
    this.idDeleteDocument = +id;
    this.modalDeleteDocument.mostrarPopup();
  }

  deleteDocument(): void {
    this.machalaAdminService.deleteFile(this.idDeleteDocument).subscribe(
      (resp) => {
        Notify('Registro eliminado correctamente.', null, null, 'success');
        this.reloadOtherDocuments();
      },
      (err) => {
        Notify(
          'Surgio un error al realizar la petición al servidor.',
          null,
          null,
          'danger'
        );
      }
    );
  }

  saveIdDeleteAccountability(id: string): void {
    this.idDeleteAccountability = id;
    this.modalDeleteAccountability.mostrarPopup();
  }

  getAccountabilities(): void {
    this.machalaAdminService.getAccountabilities().subscribe((resp) => {
      this.accountabilities = resp;
    });
  }

  notifyCopy(): void {
    Notify('URL copiado.', null, null, 'success');
  }
}
