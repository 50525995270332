export class CreateNewsInterface {
  titulo: string;
  subtitulo: string;
  descripcion: string;
  timestamp: Date;
  urls: CreateNewsURL[];
  tags: CreateNewsTag[];
}

export interface CreateNewsTag {
  id: number;
}

export interface CreateNewsURL {
  tipo: string;
  url: string;
}
