<section class="edit-user-container" *ngIf="viewForm">
  <hr />
  <div>
    <button
      class="btn btn-primary w-100 text-center"
      (click)="viewListUsers()"
    >
      Ver Usuarios
    </button>
  </div>
  <hr />
  <h2 class="border-title">
    <span><strong >Usuario</strong></span>
  </h2>
  <form action="" [formGroup]="form" (submit)="editUser()">
    <div class="form-group">
      <label for="name">Nombre</label>
      <input
        type="email"
        class="form-control"
        id="name-up"
        aria-describedby="emailHelp"
        formControlName="name"
        [class.is-invalid]="invalidName"
      />
      <small *ngIf="invalidName" class="form-text text-danger"
        >Ingrese un nombre de usuario.</small
      >
    </div>
    <div class="form-group">
      <label for="email">Email</label>
      <input
        type="email"
        class="form-control"
        id="email-up"
        aria-describedby="emailHelp"
        formControlName="email"
        [class.is-invalid]="invalidEmail"
      />
      <small *ngIf="invalidEmail" class="form-text text-danger"
        >Email incorrecto.</small
      >
    </div>
    <div class="form-group">
      <label for="roles">Área Laboral</label>
      <select class="form-control" name="" id="roles-up" formControlName="area">
        <option>Comercial</option>
        <option>Comunicación</option>
        <option>Planificación</option>
        <option>Administración</option>
        <option>Otro</option>
      </select>
      <!-- <small *ngIf="invalidArea" id="helpId" class="text-danger">Informacíon requerida</small> -->
    </div>

    <div class="form-group">
      <label for="roles">Permisos</label>
      <div class="roles">
        <div class="rol" *ngFor="let screm of screms; let i = index">
          <input
            type="checkbox"
            name="rol-r"
            id="rol-r-up-{{ i }}"
            [value]="screm.codigo"
            (change)="onCheckChange($event)"
            [checked]="checked[i]"
          />
          <label for="rol-r-up-{{ i }}"
            ><small>{{ screm.nombre }}</small></label
          >
        </div>
      </div>
      <small *ngIf="invalidViews" class="text-danger">
        Debe ingresar las vistas al que este usuario tendra permiso.
      </small>
    </div>
    <hr>
    <div class="row">
      <div class="col-12">
        <div>
          <button
            type="button"
            class="text-center btn btn-outline-dark btn-block mb-4"
            (click)="editPass()"
          >
            <span *ngIf="!passEdit"
              ><i class="glyph-icon flaticon-edit"></i> Editar Contraseña</span
            ><span *ngIf="passEdit"
              ><i class="glyph-icon flaticon-cancelar"></i> Cancelar</span
            >
          </button>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input
            type="password"
            class="form-control"
            id="password-up"
            formControlName="pass1"
            [class.is-invalid]="invalidPass1"
          />
          <small *ngIf="invalidPass1" class="form-text text-danger"
            >Contraseña incorrecto.</small
          >
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="con-password">Confirmar Contraseña</label>
          <input
            type="password"
            class="form-control"
            id="con-password-ud"
            formControlName="pass2"
            [class.is-invalid]="invalidPass2"
          />
          <small
            *ngIf="invalidPass2 && form.get('pass2').errors.required"
            class="form-text text-danger"
            >Ingrese una Contraseña.</small
          >
          <small
            *ngIf="invalidPass2 && form.get('pass2').errors.notEquals"
            class="form-text text-danger"
            >Contraseñas no coinciden.</small
          >
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-6">
        <button type="submit" class="btn btn-primary btn-block">Modificar</button>
      </div>
      <div class="col-6">
        <button type="button" class="btn btn-danger btn-block" (click)="openModal()">Eliminar</button>
      </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="estaCargando">
      <div class="img-load">
        <img src="assets/img/load.gif" alt="" />
      </div>
    </div>
  </form>
</section>
<app-modal
  #modalDeleteUser
  [esUsado]="'update-user'"
  [header]="'Eliminar'"
  [body]="'Está seguro que desea eliminar al usuario.'"
  [msjBotonAcep]="'Eliminar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="deleteUser()"
></app-modal>
