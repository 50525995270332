import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { Announcement } from 'src/app/controller/announcemet.interface';
import { ModalComponent } from '../common-pages/modal/modal.component';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {

  loadingList = false;
  announcement: Announcement[];
  newAnnouncementLoading = ['', '', '', '', '', ''];
  idAnnouncementDelete = '';
  maxPosition = 4;
  position = [];
  positionAux = [];
  noRecords = false;
  @ViewChild('modalDeleteAnnouncement') modalDeleteAnnouncement: ModalComponent;
  constructor(
    private machalaAdminService: MachalaAdminService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadingList = true;
    this.machalaAdminService.getAnnouncement().subscribe(
      (resp) => {
        this.announcement = resp.announcements;
        this.loadingList = false;
        if (!this.announcement.length) {
          this.noRecords = true;
        }
      }, (err) => {
        this.loadingList = false;
      }
    )
  }

  editAnnouncement(id: string): void {
    this.router.navigate(['/convocatorias/editar-convocatoria', id]);
  }
  assignChargeAnnouncement(id: string): void {
    this.router.navigate(['/convocatorias/agregar-cargo-convocatoria', id]);
  }

  deleteAnnouncement(): void {
    if (this.idAnnouncementDelete) {
      this.loadingList = true;
      this.machalaAdminService.deleteAnnouncement(this.idAnnouncementDelete).subscribe(
        (resp) => {
          this.machalaAdminService.getAnnouncement().subscribe(
            (resp) => {
              this.announcement = resp.announcements;
              this.loadingList = false;
              if (!this.announcement.length) {
                this.noRecords = true;
              }
            }, (err) => {
              this.loadingList = false;
            }
          )
        },
        (err) => {
          this.loadingList = false;
        }
      );
    }
  }


  openModal(idAnnouncement: string): void {
    this.idAnnouncementDelete = idAnnouncement;
    this.modalDeleteAnnouncement.mostrarPopup();
  }

}
