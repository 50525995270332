<section class="container-login">
  <header class="d-flex justify-content-center align-items-center title-text">
    <div class="header__logo ">
      <img src="assets/img/machalaep/img-logo-2.png" alt="logo de la página" class="logo__img">
    </div>
    <h5 class="header__text text-primary bold-text">Administración</h5>
  </header>
  <section class="container-form-login">
    <div class="container-fluid">
      <div class="form__icon">
        <i class="flaticon-user glyph-icon"></i>
        <div class="text-center title-text">
          <span>Iniciar Sesión</span>
        </div>
      </div>
      <form [formGroup]='form' (ngSubmit)='logIn()'>
        <div class="form-group">
          <input type="email" name="" id="" class="form-control" placeholder="Usuario"
            aria-describedby="Email del Usuario" formControlName='email' [class.is-invalid]='invalidEmail' autofocus>
          <small *ngIf="invalidEmail" class="text-danger small-text">Usuario Incorrecto.</small>
        </div>
        <div class="form-group">
          <input type="password" name="" id="" class="form-control" placeholder="Contraseña" aria-describedby="helpId"
            formControlName='password' [class.is-invalid]='invalidPassword'>
          <small *ngIf="invalidPassword" class="text-danger small-text">Contraseña Incorrecto.</small>
        </div>
        <div class="form-btn text-center">
          <button type="submit" class="btn btn-success clean-text normal-text">Enviar</button>
        </div>
        <div class="d-flex justify-content-center" *ngIf="estaCargando">
          <div class="img-load">
            <img src="assets/img/load.gif" alt="">
          </div>
        </div>
        <div class="loadingio-spinner-eclipse-rbiyig7cs3">
          <div class="ldio-kvyes51jk3s">
            <div></div>
          </div>
        </div>
      </form>
    </div>
  </section>
</section>


