import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { throwError } from 'rxjs';
declare var Notify: any;

@Component({
  selector: 'app-create-charge',
  templateUrl: './create-charge.component.html',
  styleUrls: ['./create-charge.component.scss']
})
export class CreateChargeComponent implements OnInit {
  isLoading = false;
  useform: FormGroup;
  constructor(
    private machalaAdminService: MachalaAdminService,
    private readonly fb: FormBuilder,
    private router: Router
  ) {
    this.useform = fb.group({
      nombre: [null, Validators.compose([Validators.required])],
      detalle: [null]
    })
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.useform.invalid) {
      this.useform.markAllAsTouched();
      throw new Error("Formulario inválido");
    }
    this.isLoading = true;

    const formData = { ...this.useform.value };

    Object.keys(formData).forEach((key) => {
      if (formData[key] === null) {
        delete formData[key];
      }
    });
    
    this.machalaAdminService.createCharge(formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
      }),
        catchError(err => {
          Notify('Error al crear el cargo -> ' + err.error.message, null, null, 'danger');
          return throwError(err)
        }),
      )
      .subscribe(() => {
        Notify('Cargo creado correctamente.', null, null, 'success');
        this.router.navigateByUrl('/cargos');
      })
  }

}
