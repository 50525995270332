import { Component, OnInit, ViewChild } from '@angular/core';
import { element } from 'protractor';
import { ViewClaimComponent } from '../view-claim/view-claim.component';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { retry } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent implements OnInit {
  @ViewChild('viewClaim') viewClaim: ViewClaimComponent;
  newsLoading = ['', '', '', '', '', ''];
  loading = false;
  noRecords = false;
  constructor(
    private machalaAdminService: MachalaAdminService,
    private router: Router
  ) {}
  totalPagB = 0;
  loadingList = false;
  totalNews = 0;
  currentPosition = 0;
  maxPosition = 4;
  maxNewsPag = 6;
  claims = [];
  // claimsStateIngresado = [];

  ngOnInit(): void {
    this.machalaAdminService.getClaimsPagination(0, 0).subscribe((resp) => {
      this.totalNews = resp.length;
      // this.totalNews = resp.filter(claim => claim.estado_actual === "Ingresado").length;
      this.totalPagB = Math.ceil(this.totalNews / this.maxNewsPag) * 10;

      this.getClaims(1);
    });
  }

  async getClaims(index: number): Promise<any> {
    const limit = this.maxNewsPag;
    const offset = (index - 1) * this.maxNewsPag;
    this.loading = true;
    try {
      await this.machalaAdminService
        .getClaimsPagination(offset, limit)
        .toPromise()
        .then((resp) => {
          // this.claims = resp.claimDB;

          this.claims = resp;
        console.log(this.claims);

          // this.claimsStateIngresado = this.claims.filter(claim => claim.estado_actual === "Ingresado");

          if(!this.claims.length) {
            this.noRecords = true;
          }
          this.loading = false;
        });
    } catch (error) {
      this.loading = false;
    }
  }

  openPreviewClaim() {
    this.viewClaim.openModal();
  }

  openClaim(id: string): void {
    this.router.navigate(['/reclamos/ver-reclamo', id]);
  }
}
