import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './pages/layout/layout.component';
import { NewsComponent } from './pages/news/news/news.component';
import { CreateNewsComponent } from './pages/news/create-news/create-news.component';
import { ClaimsComponent } from './pages/claim/claims/claims.component';
import { ViewNewsComponent } from './pages/news/view-news/view-news.component';
import { ViewClaimComponent } from './pages/claim/view-claim/view-claim.component';
import { ViewComplaintComponent } from './pages/complaint/view-complaint/view-complaint.component';
import { AchievementsComponent } from './pages/achievement/achievements/achievements.component';
import { RegisterComponent } from './pages/register/register/register.component';
import { AuthGuard } from './helpers/guards/auth.guard';
import { PreviewNewsComponent } from './pages/news/preview-news/preview-news.component';
import { EditNewsComponent } from './pages/news/edit-news/edit-news.component';
import { ComplaintsComponent } from './pages/complaint/complaints/complaints.component';
import { TransparencyComponent } from './pages/transparency/transparency/transparency.component';
import { AddDocumentsComponent } from './pages/transparency/add-documents/add-documents.component';
import { ClaimsDashboardComponent } from './pages/dashboard/claims-dashboard/claims-dashboard.component';
import { UserResolver } from './helpers/resolvers/user.resolver';
import { PaymentsComponent } from './pages/payments/payments/payments.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { CreateAnnouncementComponent } from './pages/announcement/create-announcement/create-announcement/create-announcement.component';
import { EditAnnouncementComponent } from './pages/announcement/edit-announcement/edit-announcement.component';
import { EditAnnouncementResolver } from './pages/announcement/edit-announcement/edit-announcement.resolver';
import { ChargesComponent } from './pages/charges/charges.component';
import { CreateChargeComponent } from './pages/charges/create-charge/create-charge.component';
import { EditChargeComponent } from './pages/charges/edit-charge/edit-charge.component';
import { EditChargeResolver } from './pages/charges/edit-charge/edit-charge.resolver';
import { ViewChargesComponent } from './pages/announcement/view-charges/view-charges.component';
import { ViewChargesResolver } from './pages/announcement/view-charges/view-charges.resolver';
import { FileChargeComponent } from './pages/charges/file-charge/file-charge.component';
import { FileChargeResolver } from './pages/charges/file-charge/file-charge.resolver';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'preview',
    component: PreviewNewsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'pagos',
        children: [
          {
            path: '',
            component: PaymentsComponent,
            pathMatch: 'full',
          }
        ]
      },
      {
        path: 'noticias',
        children: [
          {
            path: 'crear-noticia',
            component: CreateNewsComponent,
          },
          {
            path: 'ver-noticia/:id',
            component: ViewNewsComponent,
          },
          {
            path: 'editar-noticia/:id',
            component: EditNewsComponent,
          },
          {
            path: '',
            component: NewsComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'reclamos',
        children: [
          {
            path: 'ver-reclamo/:id',
            component: ViewClaimComponent,
          },
          {
            path: '',
            component: ClaimsComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'denuncias',
        children: [
          {
            path: 'ver-denuncia/:id',
            component: ViewComplaintComponent,
          },
          {
            path: '',
            component: ComplaintsComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'transparencia',
        children: [
          {
            path: 'agregar-documentos',
            component: AddDocumentsComponent,
          },
          {
            path: '',
            component: TransparencyComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'logros',
        children: [
          {
            path: '',
            component: AchievementsComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'usuarios',
        children: [
          {
            path: '',
            component: RegisterComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'convocatorias',
        children: [
          {
            path: 'agregar-convocatoria',
            component: CreateAnnouncementComponent,
          },
          {
            path: 'editar-convocatoria/:id',
            component: EditAnnouncementComponent,
            resolve: {
              announcement: EditAnnouncementResolver,
            }
          },
          {
            path: 'agregar-archivo/:id',
            component: FileChargeComponent,
            resolve: {
              fileCharge: FileChargeResolver,
            }
          },
          {
            path: 'agregar-cargo-convocatoria/:id',
            component: ViewChargesComponent,
            resolve: {
              assignCharge: ViewChargesResolver,
            }
          },
          {
            path: '',
            component: AnnouncementComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'cargos',
        children: [
          {
            path: 'agregar-cargo',
            component: CreateChargeComponent,
          },
          {
            path: 'editar-cargo/:id',
            component: EditChargeComponent,
            resolve: {
              charge: EditChargeResolver,
            }
          },
          {
            path: '',
            component: ChargesComponent,
          },
        ],
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            component: ClaimsDashboardComponent,
          },
        ],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRouterModule { }
