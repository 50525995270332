import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const TOKEN = localStorage.getItem('accessToken');
    if (TOKEN) {
      const expiry = JSON.parse(atob(TOKEN.split('.')[1])).exp;
      if (Math.floor(new Date().getTime() / 1000) >= expiry) {
        // this.authService.logOut();
      }
    }

    if (TOKEN) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${TOKEN}`,
        },
      });
    }
    return next.handle(request);
  }
}
