import { UpdateUserComponent } from './../update-user/update-user.component';
import { User } from './../../../helpers/interfaces/user.interface';
import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ValidatorsService } from '../../../helpers/services/validators.service';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { Rol } from '../../../helpers/interfaces/rol.interface';
declare var Notify: any;

interface obtainedGet {
  id_usuario: number;
  nombre: string;
  email: string;
  area: string;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  estaCargando = false;
  clickAddUser = false;
  clickEditUser = false;
  viewListUser = true;
  p = 1;
  usersLoad = [];

  @ViewChild('userEditC') userEditC: UpdateUserComponent;
  users: obtainedGet[] = [];

  screms: Rol[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private validatorsService: ValidatorsService,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {
    this.usersLoad = Array(5).fill('');
    this.reloadFormUser();
    this.machalaAdminService.getRoles().subscribe((roles) => {
      this.screms = roles;
    });

    this.reloadListUser();
  }

  reloadListUser(): void {
    this.clickEditUser = false;
    this.reloadFormUser();
    this.machalaAdminService.getUsers().subscribe((resp) => {
      this.users = resp;
    });
  }

  reloadFormUser(): void {
    this.viewListUser = true;
    this.clickAddUser = false;
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        pass1: ['', [Validators.required]],
        pass2: ['', [Validators.required]],
        area: ['Comercial', [Validators.required]],
        views: new FormArray([]),
      },
      {
        validators: this.validatorsService.equalPass('pass1', 'pass2'),
      }
    );
  }

  onCheckChange(event: any): void {
    const formArray: FormArray = this.form.get('views') as FormArray;
    if (!formArray.touched) {
      formArray.markAllAsTouched();
    }

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  createUser(): void {
    if (this.form.valid) {
      const user: User = {
        nombre: this.form.get('name').value,
        email: this.form.get('email').value,
        password: this.form.get('pass1').value,
        area: this.form.get('area').value,
        roles: [],
      };
      const controls = (this.form.get('views') as FormArray).controls;
      for (const control of controls) {
        user.roles.push({ codigo: control.value });
      }
      this.estaCargando = true;
      this.machalaAdminService.createUser(user).subscribe(
        (resp) => {
          this.estaCargando = false;
          this.reloadListUser();
          Notify('Usuario creado correctamente.', null, null, 'success');
        },
        (err) => {
          this.estaCargando = false;
          Notify('Revise la información ingresada.', null, null, 'danger');
        }
      );
    } else {
      Notify('Revise la información ingresada.', null, null, 'danger');
      this.validForm();
    }
  }

  editUser(id: number): void {

    this.viewListUser = false;
    this.clickEditUser = true;
    this.userEditC.modifyUser(id);
  }

  get invalidName(): boolean {
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get invalidEmail(): boolean {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }

  get invalidArea(): boolean {
    return this.form.get('area').invalid && this.form.get('area').touched;
  }

  get invalidPass1(): boolean {
    return this.form.get('pass1').invalid && this.form.get('pass1').touched;
  }

  get invalidPass2(): boolean {
    return this.form.get('pass2').invalid && this.form.get('pass2').touched;
  }

  get invalidViews(): boolean {
    const controls = (this.form.get('views') as FormArray).controls;
    const touched = (this.form.get('views') as FormArray).touched;
    return controls.length === 0 && touched;
  }

  validForm(): void {
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) =>
        control.markAllAsTouched()
      );
    }
  }
}
