import { Injectable } from '@angular/core';
import {
   Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { AnnouncemetResponse_Charge } from '../interface/announcemet.response';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViewChargesResolver implements Resolve<AnnouncemetResponse_Charge> {

  constructor(  private readonly machalaAdminService: MachalaAdminService){}

  resolve(route: ActivatedRouteSnapshot): Observable<AnnouncemetResponse_Charge> {
    const id: string = route.params['id'];
    return this.machalaAdminService.getAnnouncementById_Charges(id)
    .pipe(map(next => next))
  }
}
