import { AuthService } from './../services/auth.service';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any> {
  userData: any;
  userToken: string;

  constructor(
    private machalaAdminService: MachalaAdminService,
    private authService: AuthService,
    private router: Router
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    console.log(this.router.url);
    if(this.userToken && localStorage.getItem('accessToken') !== this.userToken) {
      this.userData = undefined;
      this.userData = '';
    }
    if (!this.userData || this.userToken) {
      this.userData = localStorage.getItem('accessToken');
      this.machalaAdminService.getUserMe().toPromise().then(user => {
        this.userData = user;
        return this.userData;
      }).catch(err => {
        this.authService.logOut();
        return of(null);
      });
    } else {
      return this.userData;
    }

  }
}
