import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }
  equalPass(pass1Name: string, pass2Name: string): any {
    return (formGroup: FormGroup) => {
      const pass1 = formGroup.get(pass1Name);
      const pass2 = formGroup.get(pass2Name);
      if (!pass2.value) {
        pass2.setErrors({required: true});
      }
      else if (pass1.value === pass2.value) {
        pass2.setErrors(null);
      } else {
        pass2.setErrors({notEquals: true});
      }
    }

  }

  equalPassUpdate(pass1Name: string, pass2Name: string): any {
    return (formGroup: FormGroup) => {
      const pass1 = formGroup.get(pass1Name);
      const pass2 = formGroup.get(pass2Name);

      if (pass1.value && !pass2.value) {
        pass2.setErrors({required: true});
      } else if (pass2.value && !pass1.value) {
        pass1.setErrors({required: true});
      }
      else if (pass1.value === pass2.value) {
        pass2.setErrors(null);
      } else {
        pass2.setErrors({notEquals: true});
      }
    }

  }
}
