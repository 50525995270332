import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompressorService {
  constructor() {}
  compress(file: File, width: number): Observable<any> {
    /*FileReader: Permite que las aplicaciones web lean de forma asincrónica el contenido de los archivos
    (o búferes de datos sin procesar) almacenados en el equipo del usuario, mediante objetos
    File o Blob para especificar el archivo o los datos que se van a leer.*/
    const reader = new FileReader();
    /*El método readAsDataURL es usado para leer el contenido del especificado Blob o File.
    Cuando la operación de lectura es terminada, el readyState se convierte en DONE,
    y el loadend es lanzado. En ese momento, el atributo result contiene  la información
    como una URL representando la información del archivo como una cadena de caracteres codificados en base64.*/
    reader.readAsDataURL(file);

    return new Observable<File>((subs) => {
      /*La propiedad FileReader.onload contiene un controlador de evento ejecutado cuando load
        es ejecutado, cuando el  contenido es leído con readAsArrayBuffer, readAsBinaryString,
        readAsDataURL o readAsText está habilitado.*/
      reader.onload = (ev) => {
        /*Se crea un elemento de tipo imagen mediante codigo fuente*/
        const img = new Image();
        // Se Obtienen los datos del archivo leído
        img.src = (ev.target as any).result;
        img.onload = () => {
          /* Creo un nuevo elemento donde se va a dibujar la imagen escalada*/
          const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
          /* El calculo del escalado de la imagen se la realiza haciendo uso Tales(Geometria)*/
          const scaleFactor = width / img.width;
          elem.width = width;
          /*Si queremos escalar el ancho y tener un alto fijo se lo haria al reves*/
          elem.height = img.height * scaleFactor;
          /*La interfaz CanvasRenderingContext2D proporciona el contexto de
            renderizado 2D para la superficie de dibujo de un elemento <canvas>.
            Para obtener un objeto de esta interfaz, llama a getContext() en un <canvas>,
            proporcionando "2d" como argumento*/
          const ctx = elem.getContext('2d') as CanvasRenderingContext2D;
          /*El método CanvasRenderingContext2D.drawImage() de la API Canvas 2D
            proporciona diferentes formas para dibujar una imagen dentro de canvas.*/
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          /*const image = ctx.canvas.toDataURL("image/png");
            console.log(image);*/
          /*EL metodo HTMLCanvasElement.toBlob() crea un objeto Blob que representa
            la imagen contenida en el canvas; este archivo puede ser cacheado en el disco
            o guardado en la memoria a desicion del  user agent. Si la propiedad type no
            se especifica el tipo de la imagen será image/png. La imagen creada tiene una resolución de 96dpi.
            El tercer argumento es usado con las imagenes  image/jpeg para especificar la calidad de salida.
            */
          ctx.canvas.toBlob(
            (blob) => {
              subs.next(
                new File([blob], file.name, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                })
              );
            },
            'image/jpeg',
            1
          );
        };
        reader.onerror = (error) => subs.error(error);
      };
    });
  }
}
