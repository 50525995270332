import { UploadFilesService } from './../../../helpers/services/upload-files.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Transparency } from 'src/app/helpers/interfaces/transparency.insterface';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
declare var Notify: any;

@Component({
  selector: 'app-add-other-documents',
  templateUrl: './add-other-documents.component.html',
  styleUrls: ['./add-other-documents.component.scss'],
})
export class AddOtherDocumentsComponent implements OnInit {
  formIsActive = false;
  formTransparency: FormGroup;
  file: File;
  loadNumber = 0;
  loadingFile = false;
  isLoading = false;
  @ViewChild('formContainer') formContainer: ElementRef<HTMLElement>;
  @Output() reloadList = new EventEmitter<boolean>();
  @Output() closeOthers = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private uploadFilesService: UploadFilesService,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {
    this.reloadAtributes();
  }

  saveFile(): void {
    this.isLoading = true;
    console.log(this.formTransparency);

    if (this.formTransparency.valid && this.file) {
      this.loadingFile = true;
      this.uploadFile();
    } else {
      this.validateForm();
      this.loadNumber = 0;
      this.isLoading = false;
    }
  }

  uploadFile(): void {
    const date = new Date();
    const name =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      this.generateName() +
      '-' +
      this.file.name;
    const blob = this.file.slice(0, this.file.size, this.file.type);
    const newFile = new File([blob], name, { type: this.file.type });
    this.uploadFilesService.upload(newFile).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log((100 * event.loaded) / event.total);
          this.loadNumber = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          this.formTransparency.get('filePath').setValue(event.body.url);
          this.loadingFile = false;
          this.createFile();
        }
      },
      (error) => {
        this.loadingFile = false;
        this.isLoading = false;
        this.loadNumber = 0;
        try {
          if (error.error && error.error.message) {
            Notify(error.error.message, null, null, 'danger');
          } else {
            Notify('Error el archivo pesa mas de 50gb', null, null, 'danger');
          }
        } catch (error) {
          Notify('Error el archivo pesa mas de 50gb', null, null, 'danger');
        }
        console.log(error);
      }
    );
  }

  createFile() {
    this.machalaAdminService
      .createFile(
        this.formTransparency.get('name').value,
        this.formTransparency.get('filePath').value
      )
      .subscribe(
        (resp) => {
          this.isLoading = false;
          this.reloadList.emit(true);
          this.activeForm();
          Notify('Registro creado correctamente.', null, null, 'success');
        },
        (err) => {
          this.isLoading = false;
          Notify(
            'Surgio un error al realizar la petición al servidor.',
            null,
            null,
            'danger'
          );
        }
      );
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text;
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.file = file;
    }
  }

  activeForm(): void {
    this.formIsActive = !this.formIsActive;
    const form = this.formContainer.nativeElement.getElementsByClassName(
      'form-transparency'
    )[0] as HTMLElement;
    if (!this.formIsActive) {
      this.formContainer.nativeElement.style.height = '0';
      this.reloadAtributes();
    } else {
      this.closeOthers.emit(true);
      this.formContainer.nativeElement.style.height =
        form.clientHeight + 114 + 'px';
    }
  }

  reloadAtributes(): void {
    const date = new Date();
    this.file = undefined;
    this.loadNumber = 0;
    this.formTransparency = this.formBuilder.group({
      name: ['', Validators.required],
      filePath: [''],
      file: [''],
    });
    this.untouchedForm();
  }

  get invalidName(): boolean {
    return (
      this.formTransparency.get('name').invalid &&
      this.formTransparency.get('name').touched
    );
  }
  get invalidFile(): boolean {
    return this.file === undefined && this.formTransparency.get('file').touched;
  }

  validateForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAllAsTouched();
        }
      );
    }
  }

  untouchedForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAsUntouched();
        }
      );
    }
  }
}
