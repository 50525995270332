<!-- <app-view-claim #viewClaim></app-view-claim> -->
<section class="claims-global-container container normal-text">
  <div class="title-news">
    <i></i>
    <h2 class="border-title"><span>Denuncias</span></h2>
  </div>
  <div *ngIf="!noRecords">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th class="table__col-1" scope="col">Reclamos</th>
          <th class="table__col-2" scope="col">Gestionar</th>
        </tr>
      </thead>
      <tbody *ngIf="complaints.length && !loading">
        <tr *ngFor="let complaint of complaints; let i = index">
          <td class="ellipsis table__col-1">{{ complaint.descripcion }}</td>
          <td class="d-flex">
            <button
              type="button"
              class="btn btn-success w-100"
              (click)="openComplaint(complaint.id_reclamo)"
            >
              <span class="pc-text">Ver</span>
              <span class="movil-icon"><i class="flaticon-view"></i></span>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody class="news_list" *ngIf="!complaints.length || loading">
        <tr *ngFor="let load of newsLoading">
          <td>
            <div class="loading-i"></div>
          </td>
          <td>
            <div class="loading-i"></div>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination
      class="pagination-c"
      [totalItems]="totalPagB"
      (pageChanged)="getComplaints($event.page)"
      [maxSize]="maxPosition"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
    ></pagination>
  </div>
  <div *ngIf="noRecords">
    <h1 class="text-center">No existen Registros</h1>
  </div>
</section>
