import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Achievement } from '../../../helpers/interfaces/create-achievement.interface';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { GetAchievement } from '../../../helpers/interfaces/achievement.interface';
import { ModalComponent } from '../../common-pages/modal/modal.component';
declare var Notify: any;
@Component({
  selector: 'app-template-form-achievement',
  templateUrl: './template-form-achievement.component.html',
  styleUrls: ['./template-form-achievement.component.scss'],
})
export class TemplateFormAchievementComponent implements OnInit, AfterViewInit {
  @Input() editState = true;
  @Input() modifyAchievement = false;
  @Input() achievementToModify: GetAchievement;
  @Input() id: string;
  @Output() updateAchievements = new EventEmitter<boolean>();
  @ViewChild('formContainer') formContainer: ElementRef<HTMLElement>;
  @ViewChild('previewContainer') previewContainer: ElementRef<HTMLElement>;
  @ViewChild('formCard') formCard: ElementRef<HTMLElement>;
  @ViewChild('modalAchievement') modalAchievement: ModalComponent;
  form: FormGroup;
  loadingAchievement = false;
  idAchievement = '';

  constructor(
    private formBuilder: FormBuilder,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      cantidad: ['', [Validators.required, Validators.pattern('(^[0-9]+$)')]],
      unidad: [
        'Seleccione',
        [Validators.required, Validators.pattern('^(?!.*Seleccione).*$')],
      ],
      descripcion: ['', Validators.required],
    });
    if (this.modifyAchievement) {
      this.form.get('unidad').setValue(this.achievementToModify.unidad);
      this.form.get('cantidad').setValue(this.achievementToModify.cantidad);
      this.form
        .get('descripcion')
        .setValue(this.achievementToModify.descripcion);
    }
  }

  ngAfterViewInit(): void {
    this.changeState();
  }

  changeState(): void {
    if (this.editState) {
      if (
        !this.previewContainer.nativeElement.classList.contains('form-disable')
      ) {
        this.previewContainer.nativeElement.classList.add('form-disable');
      }
      if (this.formContainer.nativeElement.classList.contains('form-disable')) {
        this.formContainer.nativeElement.classList.remove('form-disable');
      }

      if (this.formCard.nativeElement.classList.contains('card-rotate')) {
        this.formCard.nativeElement.classList.remove('card-rotate');
      }
      this.editState = false;
    } else {
      if (
        !this.formContainer.nativeElement.classList.contains('form-disable')
      ) {
        this.formContainer.nativeElement.classList.add('form-disable');
      }
      if (
        this.previewContainer.nativeElement.classList.contains('form-disable')
      ) {
        this.previewContainer.nativeElement.classList.remove('form-disable');
      }
      if (!this.formCard.nativeElement.classList.contains('card-rotate')) {
        this.formCard.nativeElement.classList.add('card-rotate');
      }
      this.editState = true;
    }
  }

  get invalidQuatity(): boolean {
    return (
      this.form.get('cantidad').invalid && this.form.get('cantidad').touched
    );
  }

  get invalidDescription(): boolean {
    return (
      this.form.get('descripcion').invalid &&
      this.form.get('descripcion').touched
    );
  }

  get invalidUnit(): boolean {
    return this.form.get('unidad').invalid && this.form.get('unidad').touched;
  }

  saveAchievement(): void {
    if (this.form.valid) {
      const achievement: Achievement = {
        unidad: this.form.get('unidad').value,
        descripcion: this.form.get('descripcion').value,
        cantidad: +this.form.get('cantidad').value,
      };
      this.loadingAchievement = true;
      this.machalaAdminService.createAchievement(achievement).subscribe(
        (resp) => {
          Notify('Logro ingresado correctamente', null, null, 'success');
          this.loadingAchievement = false;

          this.form.get('unidad').setValue('Seleccione');
          this.form.get('descripcion').setValue('');
          this.form.get('cantidad').setValue('');
          Object.values(this.form.controls).forEach((control) =>
            control.markAsUntouched()
          );
          this.updateAchievements.emit(true);
        },
        (error) => {
          Notify('Error, revise la información.', null, null, 'danger');
          this.loadingAchievement = false;
        }
      );
    } else {
      this.validForm();
    }
  }

  validForm(): void {
    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) =>
        control.markAllAsTouched()
      );
    }
  }

  openModal(idAchievemet: string): void {
    this.idAchievement = idAchievemet;
    this.modalAchievement.mostrarPopup();
  }

  deleteAchievement(): void {
    if (this.idAchievement) {
      this.loadingAchievement = true;
      this.machalaAdminService.delAchievement(this.idAchievement).subscribe(
        (resp) => {
          Notify('Logro eliminado correctamente.', null, null, 'success');
          this.loadingAchievement = false;
          this.updateAchievements.emit(true);
          this.idAchievement = '';
        },
        (err) => {
          this.loadingAchievement = false;
          Notify('Error, intente de nuevo.', null, null, 'danger');
          this.idAchievement = '';
        }
      );
    }
  }

  updateAchievent(id: string): void {
    if (this.form.valid) {
      const achievement: Achievement = {
        unidad: this.form.get('unidad').value,
        descripcion: this.form.get('descripcion').value,
        cantidad: +this.form.get('cantidad').value,
      };
      this.loadingAchievement = true;
      this.machalaAdminService.putAchievement(id, achievement).subscribe(
        (resp) => {
          Notify('Logro modificado correctamente.', null, null, 'success');
          this.updateAchievements.emit(true);
          this.loadingAchievement = false;
        },
        (error) => {
          Notify('Error, revise la información.', null, null, 'danger');
          this.loadingAchievement = false;
        }
      );
    } else {
      this.validForm();
    }
  }
}
