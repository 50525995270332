<section class="news-global-container container-fluid container-md normal-text">
  <div class="title-news">
    <i></i>
    <h2 class="border-title"><span>Dashboard</span></h2>
  </div>
  <section class="map-container">
    <div class="row">
      <div class="col-12 px-1">
        <div class="card shadow mb-4">
          <div class="card-header">
            <h6 class="m-0 font-weight-bold text-primary">
              Heatmap de denuncias de Ciudad
            </h6>
          </div>

          <div class="card-body">
            <!-- <highcharts-chart #chart [Highcharts]="Highcharts" [options]="optionsGauge1" [(update)]="update" style="width: 100%; height: 25vh; display: block;"></highcharts-chart> -->
            <div style="display: block; width: 100%; height: 10% !important">
              <div id="map" style="height: 50vh"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section #configDashboard class="config__dashboard">
    <div class="filters-container pt-3">
      <h3 class="border-title">
        <span>Indicadores</span>
      </h3>
      <p-accordion>
        <p-accordionTab header="Filtros">
          <div class="row">
            <div class="col-6 col-md-4">
              <div class="form-group">
                <label for="">Año {{ getYear }}</label>
                <select
                  class="form-control"
                  name=""
                  id="dashYear"
                  [(ngModel)]="getYear"
                  (change)="setMonts($event.target.value)"
                >
                  <option *ngFor="let year of years" value="{{ year }}">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6 col-md-4">
              <div class="form-group">
                <label for="">Mes</label>
                <select
                  class="form-control"
                  name=""
                  id="dashMonth"
                  [(ngModel)]="getMonth"
                  (change)="
                    changeIndicatorsDashboard(
                      this.getYear,
                      $event.target.value,
                      this.getType
                    )
                  "
                >
                  <option
                    *ngFor="let month of months"
                    value="{{ month.NUMBER }}"
                  >
                    {{ month.MES }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <label>Tipo</label>
              <div class="tags-claim">
                <div class="tag-claim">
                  <input
                    id="selectType2"
                    type="radio"
                    name="selectType"
                    value="PD"
                    [(ngModel)]="getType"
                    (click)="
                      changeIndicatorsDashboard(
                        this.getYear,
                        this.getMonth,
                        'PD'
                      )
                    "
                  />
                  <label for="selectType2"><small>Pendientes</small></label>
                </div>
                <div class="tag-claim">
                  <input
                    id="selectType1"
                    type="radio"
                    name="selectType"
                    value="FI"
                    [(ngModel)]="getType"
                    (click)="
                      changeIndicatorsDashboard(
                        this.getYear,
                        this.getMonth,
                        'FI'
                      )
                    "
                  />
                  <label for="selectType1"><small>Finalizados</small></label>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </section>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row" *ngIf="globalIndicators">
          <div class="col-12 pt-3">
            <div
              class="global-sumary alert alert-primary d-flex flex-wrap shadow"
              role="alert"
              *ngIf="globalSumary"
            >
              <div>
                <h4 class="mb-2">
                  {{ globalSumary.metaData[0].name | titlecase }}
                </h4>
                <h5>{{ globalSumary.rows[0][0] }}</h5>
              </div>
              <h4 class="mx-2">=</h4>
              <div>
                <h4 class="mb-2">
                  {{ globalSumary.metaData[1].name | titlecase }}
                </h4>
                <h5>{{ globalSumary.rows[0][1] }}</h5>
              </div>
              <h4 class="mx-2">+</h4>
              <div>
                <h4 class="mb-2">
                  {{ globalSumary.metaData[2].name | titlecase }}
                </h4>
                <h5>{{ globalSumary.rows[0][2] }}</h5>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-md-6 px-2 py-2"
            *ngFor="let indicator of globalIndicators.metaData; let i = index"
          >
            <div class="card shadow mb-3">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col">
                    <div class="h6 font-weight-bold text-primary">
                      {{ indicator.name | titlecase }}
                    </div>
                    <div class="h2 font-weight-bold text-gray-800">
                      <output
                        value="{{ globalIndicators.rows[0][i] }}"
                      ></output>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mt-4">
        <div class="card shadow mb-4">
          <div class="card-header">
            <h6 class="m-0 font-weight-bold text-primary">
              Formas en la que se realizó un reclamo
              {{ meses[+getMonth - 1] }}-{{ getYear }}
            </h6>
          </div>

          <div class="card-body">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="chartOptions"
              style="width: 100%; height: 100%; display: block"
            >
            </highcharts-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card shadow mb-4">
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <h6 class="m-0 font-weight-bold text-primary">
                  Reclamos {{ typeName }}
                </h6>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-12">
                  <div class="card shadow mb-4">
                    <div class="card-header">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Tipos de reclamos {{ meses[+getMonth - 1] }}-{{
                          getYear
                        }}
                        {{ typeName | lowercase }}
                      </h6>
                    </div>

                    <div class="card-body">
                      <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptionsTR"
                        style="width: 100%; height: 100%; display: block"
                      >
                      </highcharts-chart>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card shadow mb-4">
                    <div class="card-header">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Reclamos por semana de {{ meses[+getMonth - 1] }}-{{
                          getYear
                        }}
                        {{ typeName | lowercase }}
                      </h6>
                    </div>

                    <div class="card-body">
                      <div class="row" *ngIf="claimByWeek">
                        <div
                          class="col-12 col-md-6 col-lg-4"
                          *ngFor="
                            let data of claimByWeek.metaData;
                            let i = index
                          "
                        >
                          <highcharts-chart
                            #chart
                            [Highcharts]="Highcharts"
                            [options]="optionWeek[i]"
                            [update]="false"
                            style="width: 100%; height: 20vh; display: block"
                          >
                          </highcharts-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="card shadow mb-4">
                    <div class="card-header">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Reclamos {{ meses[+getMonth - 1] }} por año
                        {{ typeName | lowercase }}
                      </h6>
                    </div>

                    <div
                      class="row"
                      *ngIf="claimsByYear && claimsByYear.metaData"
                    >
                      <div
                        class="col-6 col-md-3"
                        *ngFor="
                          let data of claimsByYear.metaData;
                          let i = index
                        "
                        style="width: 100%; height: 100%; display: block"
                      >
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col">
                              <div class="h6 font-weight-bold text-primary">
                                {{ data.name }}
                              </div>
                              <div class="h2 font-weight-bold text-gray-800">
                                <output
                                  value="{{ claimsByYear.rows[0][i] }}"
                                ></output>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card shadow mb-4">
                    <div class="card-header">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Reclamos {{ meses[+getMonth - 1] }} por año
                        {{ typeName | lowercase }}
                      </h6>
                    </div>

                    <div class="card-body">
                      <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptionClaimsByYear"
                        style="width: 100%; height: 100%; display: block"
                      >
                      </highcharts-chart>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <!-- <div class="col-12 col-lg-6">
                  <div class="card shadow mb-2">
                    <div class="card-header">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Reclamos por día de la semana
                        {{ meses[+getMonth - 1] }}-{{ getYear }}
                        {{ typeName | lowercase }}
                      </h6>
                    </div>

                    <div class="card-body">
                      <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptionsTD"
                        style="width: 100%; height: 100%; display: block"
                      >
                      </highcharts-chart>
                    </div>
                  </div>
                </div> -->
                <div class="col-12 col-lg-6 px-1">
                  <div class="card shadow mb-2">
                    <div class="card-header">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Reclamos por mes del {{ getYear }}
                        {{ typeName | lowercase }}
                      </h6>
                    </div>

                    <div class="card-body">
                      <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptionRM"
                        style="width: 100%; height: 100%; display: block"
                      >
                      </highcharts-chart>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-6 px-1">
                  <div class="card shadow mb-2">
                    <div class="card-header">
                      <h6 class="m-0 font-weight-bold text-primary">
                        Reclamos por mes del {{ getYear }}
                        Total
                      </h6>
                    </div>

                    <div class="card-body">
                      <highcharts-chart
                        [Highcharts]="Highcharts"
                        [options]="chartOptionRMTO"
                        style="width: 100%; height: 100%; display: block"
                      >
                      </highcharts-chart>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</section>
