<!--footer start-->
<footer class="footer widget-footer clearfix text-white small-text background-third">
    <div class="first-footer">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-3 widget-area sub-footer-1">

                    <div class="mission">
                        <h2 class="title-text border-title">
                            <span>MISIÓN</span>
                        </h2>
                        <p>
                            Satisfacer las necesidades de nuestros usuarios con respecto a los servicios de agua potable y alcantarillado.
                        </p>
                    </div>
                    <div class="vision">
                        <h2 class="title-text border-title">
                            <span>VISIÓN</span>
                        </h2>
                        <p>
                            Ser una de las empresas líder en agua potable y alcantarillado, reconocida a nivel nacional e internacional. </p>
                    </div>

                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 widget-area sub-footer-2">

                    <h2 class="title-text border-title">
                        <span>INSTITUCIONES AMIGAS</span>
                    </h2>
                    <ul class="friend-institutions">
                        <li>
                            <a href=""><small><i class="glyph-icon flaticon-double-right-arrows-angles"></i></small> ASEO EP</a>
                        </li>
                        <li>
                            <a href=""><small><i class="glyph-icon flaticon-double-right-arrows-angles"></i></small> MERCADOS EP</a>
                        </li>
                        <li>
                            <a href=""><small><i class="glyph-icon flaticon-double-right-arrows-angles"></i></small> MOVILIDAD EP</a>
                        </li>
                        <li>
                            <a href=""><small><i class="glyph-icon flaticon-double-right-arrows-angles"></i></small> SALUD EP</a>
                        </li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 widget-area sub-footer-3">
                    <h2 class="title-text border-title">
                        <span>CONTACTO</span>
                    </h2>
                    <p><span class="subtitle-color">Dirección:</span> Av. 9 de Mayo e/. Manuel Serrano y Arízaga</p>
                    <p><span class="subtitle-color">Teléfonos:</span> 593 (7) 3702010 </p>
                    <p><span class="subtitle-color">E-mail:</span><br> info@aguasmachala.gob.ec
                    </p>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 widget-area sub-footer-4">
                    <div class="footer__logo">
                        <div class="icons_footer">
                            <div>
                                <img src="assets/img/machalaep/footer-logo.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
