import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PaymentsListComponent } from '../payments-list/payments-list.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, AfterViewInit {
  @ViewChild('pagosPendientes') pendingPayments: PaymentsListComponent;
  @ViewChild('noPagados') unpaid: PaymentsListComponent;
  @ViewChild('pagados') paid: PaymentsListComponent;
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.pendingPayments.getTransactions('no-procesado', 0);
    this.unpaid.getTransactions('no-pago', 0);
    this.paid.getTransactions('pagado', 0);
  }

  reloadLists(): void {
    this.unpaid.reloadList();
    this.paid.reloadList();
  }

}
