import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileCharge } from 'src/app/controller/charge.interface';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { AddFileForm } from '../../announcement/interface/announcemet.response';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FileChargeResolver } from './file-charge.resolver';
declare var Notify: any;
@Component({
  selector: 'app-file-charge',
  templateUrl: './file-charge.component.html',
  styleUrls: ['./file-charge.component.scss']
})
export class FileChargeComponent implements OnInit {

  isLoading = false;
  files: FileCharge[];
  form: FormGroup;
  formData: FormData;
  newAnnouncementLoading = ['', '', '', '', '', ''];
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly machalaAdminService: MachalaAdminService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private myResolver: FileChargeResolver
  ) {
    this.form = this.fb.group({
      typeArchive: [[null], Validators.compose([Validators.required])],
      archive: [[null], Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    const data: any = this.activatedRoute.snapshot.data;
    if ('fileCharge' in data) {
      this.files = data.fileCharge.files;
    }

  }

  generateName(name: string): string {
    return name.slice(2);
  }

  onSelectFile(input: 'archive', event: any) {
    const control = this.form.controls[input] as AbstractControl;

    if (control) {
      const files: FileList = event.target.files;
      if (files.length) {
        const file: File = event.target.files[0];
        control.setValue(file);
      }
    }
  }

  viewFile(file: any): boolean {
    if (file === null) {
      return false;
    } else {
      return true;
    }
  }

  onSubmit(): void {
    this.isLoading = true;
    const value: AddFileForm = this.form.value as AddFileForm;
    const formData = new FormData();
    formData.append('archivo', value.archive);
    this.machalaAdminService.putChargeFile(value.typeArchive, formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        catchError(err => {
          Notify('Error al cargar archivo -> ' + err.error.message, null, null, 'danger');
          return throwError(err)
        }),
      )
      .subscribe(() => {
        Notify('Archivo cargado correctamente.', null, null, 'success');
        const currentRoute = this.activatedRoute.snapshot;
        this.myResolver.resolve(currentRoute).subscribe(
          (data) => {
            this.files = data.files;
            this.form = this.fb.group({
              typeArchive: [[data.files[0].nombre]],
            });
          },(error) => {
            Notify('Error al enlistar los archivos -> ' + error.error.message, null, null, 'danger');
          }
        )
      })
  }

}
