import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Announcement } from '../../../controller/announcemet.interface';
import { AnnouncementForm } from '../interface/announcemet.response';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare var Notify: any;
type AnnouncementDocument = 'curriculum' | 'schedule' | 'manual' | 'titleDocument';

@Component({
  selector: 'app-edit-announcement',
  templateUrl: './edit-announcement.component.html',
  styleUrls: ['./edit-announcement.component.scss']
})
export class EditAnnouncementComponent implements OnInit {
  isLoading = false;
  announcement:Announcement;  
  formData: AnnouncementForm;
  form: FormGroup;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly machalaAdminService: MachalaAdminService,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router
  ) {
    this.form = formBuilder.group({
      title: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      note: [null],
      curriculum: [null],
      schedule: [null],
      manual: [null],
      titleDocument: [null]
    });
   }

  ngOnInit(): void {
    const data: any = this.activatedRoute.snapshot.data;
    
    if('announcement' in data){
      const announcement = data['announcement'] as Announcement;
      
      
      this.form.patchValue({
        title: announcement.titulo,
        email: announcement.correo,
        note: announcement.nota,
        curriculum: announcement.hoja_de_vida_url,
        schedule: announcement.cronograma_url,
        manual: announcement.manual_descripcion_url,
        titleDocument: announcement.titulo_url
      })
    }
  }

  onSelectFile(input: AnnouncementDocument, event: any) {
    const control = this.form.controls[input] as AbstractControl;
   
    if (control) {
      const files: FileList = event.target.files;
      if (files.length) {
        const file: File = event.target.files[0];
        control.setValue(file);
      }
    }
  }

  onSubmit(): void {
    this.isLoading = true;
    const value: AnnouncementForm = this.form.value as AnnouncementForm;
    const id:any = this.activatedRoute.params;
    const formData = new FormData();
    formData.append('titulo', value.title);
    formData.append('correo', value.email);
    formData.append('nota', value.note);
    formData.append('titulo_file', value.titleDocument);
    formData.append('hoja_de_vida_file', value.curriculum);
    formData.append('cronograma_file', value.schedule);
    formData.append('manual_descripcion_file', value.manual);

    this.machalaAdminService.putAnnouncement(String(id.value['id']), formData)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      }),
      catchError(err => {
        console.log(err.error.message);
        Notify('Error al actualizar la convocatoria -> '+err.error.message, null, null, 'danger');
        return throwError(err)
      }),
    )
    .subscribe(() => {
      Notify('Convocatoria actualizada correctamente.', null, null, 'success');
      this.router.navigateByUrl('/convocatorias');
    })
  }

}
