import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { CreateNewsInterface } from '../../../helpers/interfaces/create-news.interface';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { ActivatedRoute } from '@angular/router';
import { NewsObtained } from '../../../helpers/interfaces/news-obtained.interface';

@Component({
  selector: 'app-view-news',
  templateUrl: './view-news.component.html',
  styleUrls: ['./view-news.component.scss'],
})
export class ViewNewsComponent implements OnInit {
  @Input() news: NewsObtained;
  @Input() images = [];

  customOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  safeURLVideo: SafeUrl;
  tags = [];
  tagsValue = [];
  constructor(
    private machalaAdminService: MachalaAdminService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((data) => {
      this.machalaAdminService.getNewsById(data.id).subscribe((resp) => {
        this.news = resp;
        if (this.news.videos && this.news.videos.length > 0) {
          this.safeURLVideo = this.getSefeURL(this.news.videos[0]);
        }

      });
    });


  }



  getSefeURL(url: string): SafeUrl {
    let URL = url;
    if (url.indexOf('?v=') > 0) {
      const pos = url.indexOf('?v=') + 3;
      const end = url.indexOf('&') > 0 ? url.indexOf('&') : url.length;
      URL = `https://www.youtube.com/embed/${url.substring(pos, end)}`;
    } else if (url.indexOf('.be/') > 0) {
      const pos = url.indexOf('.be/') + 4;
      URL = `https://www.youtube.com/embed/${url.substring(pos)}`;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL);
  }
}
