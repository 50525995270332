import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { PaymentService } from 'src/app/helpers/services/payment.service';
declare var Notify: any;

interface Transaction {
  id_transaccion: number;
  estado: string;
  nro_cuenta: number;
  identificacion: string;
  fecha: Date;
  ultima_factura: number;
  nombre: string;
  apellidos: string;
  total: number;
  tarjeta: string;
  id_pago: string;
  numero_tarjeta: string;
}

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
})
export class PaymentsListComponent implements OnInit {
  loading = true;
  noData = false;
  index = 0;

  total = 0;
  current = 0;
  max = 3;


  state = '';

  @Input() viewOptions = false;
  @Output() reload: EventEmitter<boolean> = new EventEmitter();

  listTransactions: Transaction[] = [];

  constructor(private machalaAdminService: MachalaAdminService,
    private paymentService: PaymentService) {}

  ngOnInit(): void {

  }

  async getTransactions(state: string, index: number) {
    try {
      this.index = index;
      this.state = state;
      const DATA_TRANSACTION = await this.machalaAdminService.getTransactions({
        state,
        limit: this.max,
        offset: (index) * this.max,
      });
      console.log(DATA_TRANSACTION);
      this.total =  Math.ceil(DATA_TRANSACTION.total / this.max) * 10;
      console.log(this.total);

      this.listTransactions = DATA_TRANSACTION.data;
      console.log(JSON.stringify(this.listTransactions));
      this.loading = false;
      this.noData = this.listTransactions.length === 0;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  async markAsPaid(data: Transaction) {
    try {
      const checkPayBill = (await this.paymentService.checkPayBill(data.ultima_factura, data.nro_cuenta)).billPaid;
      console.log(checkPayBill);
      if(checkPayBill) {
        await this.machalaAdminService.updateTransaction(data.id_transaccion, {estado: 'pagado'})
      } else {
        Notify('Primero registre el pago en el sistema interno y luego marque como pagado.', null, null, 'danger');
      }
      console.log(checkPayBill);

    } catch (error) {
      console.log(error);
    }
  }

  async registerPayment(data: Transaction) {
    try {
      await this.paymentService.payment(data.nro_cuenta, data.total, data.tarjeta, data.ultima_factura, data.id_pago, data.numero_tarjeta).toPromise();
      await this.machalaAdminService.updateTransaction(data.id_transaccion, {estado: 'pagado'})
      this.reloadList();
      this.reload.emit(true);
      Notify('Se registrado el pago en el sistema.', null, null, 'success');
    } catch (error) {
      if(error.error && error.error.message) {
        Notify(error.error.message, null, null, 'danger');
      }
      console.log(error)
    }
  }

  reloadList() {
    this.getTransactions(this.state, this.index);
  }
}
