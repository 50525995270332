
<div class="container-preview container" *ngIf="news">

  <div class="carousel-news">
    <div class="carousel__date-info">
      <span class="info__day">
        {{news.fecha| date: 'dd'}}
      </span>
      <span class="info__mount">
        {{news.fecha| date: 'MMM'}}
      </span>
    </div>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let item of news.imagenes">
        <div class="slide">
          <img [src]='item' alt="img 1">
          <div class="loading-div"></div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
  <h4>{{news.titulo}}</h4>
  <h6 class="subtitle-color"><strong>{{news.subtitulo}}</strong> </h6>
  <span>
    <i class="glyph-icon flaticon-calendar"></i> {{news.fecha | date}}
  </span>
  <div class="container-tag">
    <div class="tags">
      <div class="tag" *ngFor="let tag of news.tags">
        {{tag}}
      </div>
    </div>
  </div>

  <div class="content-news mb-4" [innerHTML]="news.resumen" >
  </div>

  <div class="video-news" *ngIf="safeURLVideo">
    <iframe [src]="safeURLVideo" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>


</div>
<div style="height: 2rem;">

</div>
