import { UploadFilesService } from './../../../helpers/services/upload-files.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import {
  TransparencyList,
  TransparencyUpdate,
} from 'src/app/helpers/interfaces/transparency-list.interface';

declare var Notify: any;

@Component({
  selector: 'app-update-transparency',
  templateUrl: './update-transparency.component.html',
  styleUrls: ['./update-transparency.component.scss'],
})
export class UpdateTransparencyComponent implements OnInit {
  formIsActive = false;
  formTransparency: FormGroup;
  file: File;
  loadNumber = 0;
  loadingFile = false;
  year = [];
  mounth = [];
  idUpdate = -1;
  isLoading = false;
  @ViewChild('formContainer') formContainer: ElementRef<HTMLElement>;
  @Output() reloadList = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private uploadFilesService: UploadFilesService,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {
    const lastYear = new Date().getFullYear();
    const range = lastYear - 2015;
    this.mounth = Array(12)
      .fill(1)
      .map((v, i) => i + 1);
    this.year = Array(range)
      .fill(1)
      .map((v, i) => 2016 + i);
    this.reloadAtributes();
  }

  async saveFile() {
    this.isLoading = true;
    if (this.formTransparency.valid) {
      if (this.file) {
        this.loadingFile = true;
        const anio = this.formTransparency.get('anio').value;
        const mes = this.formTransparency.get('mes').value;
        const name =
          anio + '-' + mes + '-' + this.generateName() + '-' + this.file.name;
        const blob = this.file.slice(0, this.file.size, this.file.type);
        const newFile = new File([blob], name, { type: this.file.type });

        this.uploadFilesService.upload(newFile).subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              console.log((100 * event.loaded) / event.total);
              this.loadNumber = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              this.formTransparency.get('filePath').setValue(event.body.url);
              this.loadingFile = false;
              this.formTransparency.get('file').enable();
              const newTranparency: TransparencyUpdate = {
                id_transparencia: this.idUpdate,
                url: this.formTransparency.get('filePath').value,
                nombre: this.formTransparency.get('name').value,
                anio: this.formTransparency.get('anio').value,
                mes: this.formTransparency.get('mes').value,
              };
              this.updateFile(newTranparency);
            }
          },
          (error) => {
            this.formTransparency.get('file').enable();
            this.loadingFile = false;
            this.isLoading = false;
            this.loadNumber = 0;
            try {
              if (error.error && error.error.message) {
                Notify(error.error.message, null, null, 'danger');
              } else {
                Notify(
                  'Error el archivo pesa mas de 50gb',
                  null,
                  null,
                  'danger'
                );
              }
            } catch (error) {
              Notify('Error el archivo pesa mas de 50gb', null, null, 'danger');
            }
          }
        );
      } else {
        const newTranparency: TransparencyUpdate = {
          id_transparencia: this.idUpdate,
          url: this.formTransparency.get('filePath').value,
          nombre: this.formTransparency.get('name').value,
          anio: this.formTransparency.get('anio').value,
          mes: this.formTransparency.get('mes').value,
        };
        this.updateFile(newTranparency);
      }
    } else {
      this.validateForm();
      this.formTransparency.get('file').enable();
      this.loadingFile = false;
      this.isLoading = false;
      this.loadNumber = 0;
    }
  }

  updateFile(newTranparency: TransparencyUpdate): void {
    this.machalaAdminService.putTransparency(newTranparency).subscribe(
      (resp) => {
        this.reloadList.emit(true);
        this.activeForm();
        this.isLoading = false;
        Notify('Registro modificado correctamente.', null, null, 'success');
      },
      (err) => {
        Notify(
          'Surgio un error al realizar la petición al servidor.',
          null,
          null,
          'danger'
        );

        this.isLoading = false;
      }
    );
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text;
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      this.formTransparency.get('file').disable();
      const file = event.target.files[0];
      this.file = file;
    }
  }

  activeForm(): void {
    this.formIsActive = !this.formIsActive;
    const form = this.formContainer.nativeElement.getElementsByClassName(
      'form-transparency'
    )[0] as HTMLElement;
    if (!this.formIsActive) {
      this.formContainer.nativeElement.style.height = '0';
      this.reloadAtributes();
    } else {
      this.formContainer.nativeElement.style.height =
        form.clientHeight + 114 + 'px';
    }
  }

  activeFormUpload(): void {
    if (!this.formIsActive) {
      this.formIsActive = true;
      const form = this.formContainer.nativeElement.getElementsByClassName(
        'form-transparency'
      )[0] as HTMLElement;
      this.formContainer.nativeElement.style.height =
        form.clientHeight + 114 + 'px';
    }
    this.reloadAtributes();
  }

  reloadAtributes(): void {
    this.idUpdate = -1;
    this.file = undefined;
    this.loadNumber = 0;
    this.formTransparency = this.formBuilder.group({
      name: ['', Validators.required],
      file: [''],
      filePath: ['', Validators.required],
      anio: ['', Validators.required],
      mes: ['', Validators.required],
    });
    this.untouchedForm();
  }

  get invalidName(): boolean {
    return (
      this.formTransparency.get('name').invalid &&
      this.formTransparency.get('name').touched
    );
  }
  get invalidFile(): boolean {
    return (
      this.formTransparency.get('file').invalid &&
      this.formTransparency.get('file').touched
    );
  }

  fillForm(id: number): void {}

  validateForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAllAsTouched();
        }
      );
    }
  }

  untouchedForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAsUntouched();
        }
      );
    }
  }

  uploadFile(transparency: TransparencyList): void {
    this.activeFormUpload();
    this.idUpdate = transparency.id;
    this.formTransparency.get('anio').setValue(transparency.anio);
    this.formTransparency.get('mes').setValue(transparency.mes);
    this.formTransparency.get('name').setValue(transparency.nombre);
    this.formTransparency.get('filePath').setValue(transparency.url);
  }
}
