import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Achievement } from '../../../helpers/interfaces/create-achievement.interface';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { GetAchievement } from '../../../helpers/interfaces/achievement.interface';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss'],
})
export class AchievementsComponent implements OnInit {
  achievements: GetAchievement[] = [];
  @ViewChild('listAchievements') listAchievements: ElementRef<HTMLElement>;
  constructor(private machalaAdminService: MachalaAdminService) {}
  ngOnInit(): void {
    this.machalaAdminService.getAchievements().subscribe((resp) => {
      this.achievements = resp;
    });
  }
  updateAchievements(event: any): void {
    this.machalaAdminService.getAchievements().subscribe((resp) => {
      this.achievements = resp;
      const scroll = this.listAchievements.nativeElement.scrollHeight;
    });
  }
  updateAchievementsForm(event: any): void {
    this.machalaAdminService.getAchievements().subscribe((resp) => {
      this.achievements = resp;
      const scroll = this.listAchievements.nativeElement.scrollHeight;
      setTimeout(() => {
        window.scrollTo({
          top: scroll,
          behavior: 'smooth',
        });
      }, 250);
    });
  }
}
