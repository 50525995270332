<app-preview-news #modalPreviewNews></app-preview-news>
<section class="container normal-text container-create-edit">
    <div class="container-form mx-auto">
        <div class="text-center title-template">
            <h2 class="border-title d-inline-block">
                <span>Agregar Convocatoria</span>
            </h2>
            <!--   <h2 class="border-title d-inline-block" *ngIf="isEdit">
                <span>Editar Noticia</span>
            </h2> -->

        </div>


        <form [formGroup]="form" (ngSubmit)="onSubmit()">


            <div class="form-group">
                <label for="titulo" class="form-title">Título</label>
                <input 
                    id="titulo" 
                    type="text" 
                    name="" 
                    class="form-control" 
                    placeholder="Ingrese un Título"
                    aria-describedby="helpId" 
                    formControlName="title" />
                <ng-container *ngIf="form.controls['title'].errors && form.controls['title'].touched">
                    <ng-container *ngIf="form.controls['title'].hasError('required')">
                        <small class="text-danger">El título es requerido.</small>
                    </ng-container>
                </ng-container>
            </div>
            <div class="form-group">
                <label for="correo" class="form-title">Correo</label>
                <input 
                    id="correo" 
                    type="email" 
                    name="" 
                    class="form-control" 
                    placeholder="Ingrese correo"
                    aria-describedby="helpId" 
                    formControlName="email"/>
                    <ng-container *ngIf="form.controls['email'].errors && form.controls['email'].touched">
                        <ng-container *ngIf="form.controls['email'].hasError('required')">
                            <small class="text-danger">El correo es requerido.</small>
                        </ng-container>
                        <ng-container *ngIf="form.controls['email'].hasError('email')">
                            <small class="text-danger">El correo es inválido.</small>
                        </ng-container>
                    </ng-container>
            </div>
            <div class="form-group">
                <label for="Resumen" class="form-title">Nota</label>
                <textarea 
                    id='resumen' 
                    type="text" 
                    class="form-control" 
                    placeholder="Ingresar nota"
                    aria-describedby="helpId" 
                    rows="6" 
                    formControlName="note"></textarea>
                    <ng-container *ngIf="form.controls['note'].errors && form.controls['note'].touched">
                        <ng-container *ngIf="form.controls['note'].hasError('required')">
                            <small class="text-danger">Tiene que ingresar algún contenido.</small>
                        </ng-container>
                    </ng-container>
            </div>
            <label class="form-title">Agregar archivo de convocatoria</label>
            <div class="form-img">
                <div class="form-img">
                    <div class="container mt-3">
                        <input 
                            type="file" 
                            (change)="onSelectFile('titleDocument',$event)" 
                            class="form-control mb-2">
                    </div>
                </div>
            </div>
            <label class="form-title mt-3">Agregar archivo de hoja de vida</label>
            <div class="form-img">
                <div class="form-img">
                    <div class="container mt-3">
                        <input 
                            type="file" 
                            (change)="onSelectFile('curriculum',$event)" 
                            class="form-control mb-2">
                    </div>
                </div>
            </div>
            <label class="form-title mt-3">Agregar archivo de cronograma</label>
            <div class="form-img">
                <div class="form-img">
                    <div class="container mt-3">
                        <input 
                            type="file" 
                            (change)="onSelectFile('schedule',$event)" 
                            class="form-control mb-2">
                    </div>
                </div>
            </div>
            <label class="form-title mt-3">Agregar archivo de manual de descripción</label>
            <div class="form-img">
                <div class="form-img">
                    <div class="container mt-3">
                        <input 
                            type="file" 
                            (change)="onSelectFile('manual',$event)" 
                            class="form-control mb-2">
                    </div>
                </div>
            </div>
            <div class="text-right form-btn mt-5">
                <button type="submit" class="btn btn-success clean-text btn-enviar title-text">
                    <strong>Guardar</strong>
                </button>
            </div>
        </form>
        <div class="d-flex justify-content-center" *ngIf="isLoading">
            <div class="img-load">
                <img src="assets/img/load.gif" alt="" />
            </div>
        </div>
    </div>
</section>