import { Target } from '@angular/compiler';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TemplateFormNewsComponent } from '../template-form-news/template-form-news.component';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-create-news',
  templateUrl: './create-news.component.html',
  styleUrls: ['./create-news.component.scss'],
})
export class CreateNewsComponent implements OnInit, AfterViewInit {
  // https://regex101.com/r/N1CoyQ/1
  @ViewChild('createNews') createNews: TemplateFormNewsComponent;
  constructor(private formBuilder: FormBuilder) {}
  ngOnInit(): void {}

  ngAfterViewInit(): void {

    this.createNews.initializeCreate();
  }
}
