import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Charge } from 'src/app/controller/charge.interface';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';

@Injectable({
  providedIn: 'root'
})
export class EditChargeResolver implements Resolve<Charge> {
  constructor(  private readonly machalaAdminService: MachalaAdminService){}
  resolve(route: ActivatedRouteSnapshot): Observable<Charge> {
    const id: string = route.params['id']
    return this.machalaAdminService.getChargeById(id)
    .pipe(map(next => next.charge));
  }
}
