<section class="achievement-global-container container normal-text">
  <h2 class="border-title"><span>Logros</span></h2>

  <div>
    <div class="add-achievement text-center ">
      <hr>
      <h4 class="m-0 d-inline-block"><span>Agregar Logro</span></h4>
      <hr>
    </div>
    <div class="">
      <div class="width-form">
        <app-template-form-achievement (updateAchievements)='updateAchievementsForm($event)' [id] = "'create'"></app-template-form-achievement>
      </div>
    </div>
  </div>
  <div class="mt-4">
    <div class="all-achievements text-center ">
      <hr>
      <h4 class="m-0 d-inline-block"><span>Logros Agregados</span></h4>
      <hr>
    </div>
    <div #listAchievements class="list-achievements">
      <div class="achievement" *ngFor="let achievement of achievements; let i = index">
        <app-template-form-achievement [id]='i.toString()' [editState]='false' [modifyAchievement] ='true' [achievementToModify]='achievement' (updateAchievements)='updateAchievements($event)'></app-template-form-achievement>
      </div>
    </div>
  </div>



</section>


