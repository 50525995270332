import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  Input,
  OnDestroy,
} from '@angular/core';
import { FirebaseService } from '../../../helpers/services/firebase.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { of, Observable, Subscription } from 'rxjs';
import {
  CreateNewsURL,
  CreateNewsTag,
} from '../../../helpers/interfaces/create-news.interface';
import { CreateNewsInterface } from '../../../helpers/interfaces/create-news.interface';
import { CompressorService } from '../../../helpers/compressor/compressor.service';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { PreviewNewsComponent } from '../preview-news/preview-news.component';
import { NewsObtained } from '../../../helpers/interfaces/news-obtained.interface';
import { Editor, Toolbar, toHTML } from 'ngx-editor';
import { Router } from '@angular/router';
import { UploadFilesService } from 'src/app/helpers/services/upload-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
declare var Notify: any;
@Component({
  selector: 'app-template-form-news',
  templateUrl: './template-form-news.component.html',
  styleUrls: ['./template-form-news.component.scss'],
})
export class TemplateFormNewsComponent implements OnInit, OnDestroy {
  currentDate = new Date();
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic', 'underline'],
    ['blockquote'],
    ['ordered_list', 'bullet_list'],
    ['link'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ];

  /*
    toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify']
    ];
  */
  html: '';
  @ViewChild('modalPreviewNews') modalPreviewNews: PreviewNewsComponent;
  newsObtained: NewsObtained;
  form: FormGroup;
  formArray: FormArray;
  tagsArray: FormArray;
  suscriber: Subscription;
  checkedTags = [];
  loadingImage = false;
  @Input() isEdit = false;
  numImages = 1;
  tags = [];
  estaCargando = false;
  indexLoading = -1;
  // images = [];
  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService,
    private compressorService: CompressorService,
    private machalaAdminService: MachalaAdminService,
    private uploadFilesService: UploadFilesService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      subtitle: ['', Validators.required],
      content: ['', Validators.required],
      timestamp: ['', Validators.required],
      tags: new FormArray([]),
      urlYoutube: [
        '',
        Validators.pattern('^(http(s|)://)?(www.youtube.com|youtu.?be)/.+$'),
      ],
      photos: new FormArray([]),
    });

    this.formArray = this.form.get('photos') as FormArray;
    this.tagsArray = this.form.get('tags') as FormArray;
    this.formArray.push(new FormControl(''));
  }
  ngOnDestroy(): void {
    if (this.suscriber) {
      this.suscriber.unsubscribe();
    }
  }
  getTags(): Observable<any> {
    return this.machalaAdminService.getTags(); /*.subscribe((resp) => {
      this.tags = resp;
      console.log(this.tags);
    }); */
  }

  initializeCreate(): void {
    this.getTags().subscribe((resp) => {
      this.tags = resp;
    });
  }
  addTag(event: any, index: number): void {
    if (event.target.checked) {
      this.tagsArray.push(new FormControl(`${index}`));
    } else {
      this.deleteTag(index);
    }
  }

  deleteTag(index: number): void {
    let i = 0;
    for (const tag of this.tagsArray.controls) {
      if (tag.value == index) {
        this.tagsArray.removeAt(i);
        break;
      }
      i++;
    }
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text;
  }

  addImage(event: any, index: number): any {
    if (event.target.files.length > 0) {
      this.indexLoading = index;
      const fileInput = event.target.files[0];
      this.suscriber = this.compressorService
        .compress(fileInput, 700)
        .subscribe((file) => {
          this.ngZone.run(() => {
            const fileOutput = file;
            const reader = new FileReader();
            let src: string = '';
            reader.onload = (eve) => {
              src = eve.target.result as string;
              const FILE = this.firebaseService.createBlobImageFileAndShow(src);
              this.loadingImage = true;
              this.uploadFilesService.upload(FILE).subscribe(
                (event) => {
                  if (event.type === HttpEventType.UploadProgress) {
                    // this.loadNumber = Math.round((100 * event.loaded) / event.total);
                  } else if (event instanceof HttpResponse) {
                    // this.formTransparency.get('filePath').setValue(event.body.url);
                    this.loadingImage = false;
                    this.addImageControl(event.body.url, index);
                    this.indexLoading = -1;
                    // this.formTransparency.get('file').enable();
                    const msg =
                      'Uploaded the file successfully: ' + event.body.url;
                  }
                },
                (error) => {
                  // this.formTransparency.get('file').enable();
                  this.loadingImage = false;
                  this.indexLoading = -1;
                }
              );
            };
            reader.readAsDataURL(fileOutput);
          });
        });
    }
    /*
    const fileInput = event.target.files[0];
    this.indexLoading = index;
    this.suscriber = this.compressorService.compress(fileInput, 1000).subscribe((file) => {
      this.ngZone.run(() => {
        const fileOutput = file;
        const reader = new FileReader();
        let src: string = '';
        reader.onload = (eve) => {
          src = eve.target.result as string;
          // * subir image a firebase para que regrese una URL
          const FILE = this.firebaseService.createBlobImageFileAndShow(src);
          this.loadingImage = true;

          this.firebaseService
            .uploadFile(FILE)
            .toPromise()
            .finally()
            .then((resp) => {
              console.log(resp);
              this.addImageControl(resp, index);
              this.loadingImage = false;
              this.indexLoading = -1;
            }, err => {
              this.loadingImage = false;
              this.indexLoading = -1;
            });
          // * -------------------------------------------
          // * Guadar la imagen de tipo blob directamente en el control
          // this.formArray.controls[index].setValue(src);
          // * -------------------------------------------

          console.log(`${index} --- ${this.formArray.controls.length - 1}`);
          /* * Agregar un nuevo control en caso de agregar una imagen al final
          if (index === this.formArray.controls.length - 1) {
            this.formArray.push(new FormControl(''));
          }

          console.log(this.formArray.length);
        };

        reader.readAsDataURL(fileOutput);
      });
    });  */
  }

  addImageControl(src: string, index: number): void {
    this.formArray.controls[index].setValue(src);
    if (index === this.formArray.controls.length - 1) {
      this.formArray.push(new FormControl(''));
    }
  }

  deleteImage(index: number): void {
    this.formArray.removeAt(index);
  }

  fillFields(newsObtained: NewsObtained): void {
    this.newsObtained = newsObtained;
    this.form.get('title').setValue(newsObtained.titulo);
    this.form.get('subtitle').setValue(newsObtained.subtitulo);
    this.form.get('content').setValue(newsObtained.resumen);
    if (newsObtained.fecha.indexOf('T') > 0) {
      this.form
        .get('timestamp')
        .setValue(
          newsObtained.fecha.substring(0, newsObtained.fecha.indexOf('T'))
        );
    } else {
      this.form.get('timestamp').setValue(newsObtained.fecha);
    }

    if (newsObtained.videos && newsObtained.videos.length) {
      this.form.get('urlYoutube').setValue(newsObtained.videos[0]);
    }
    this.getTags().subscribe((resp) => {
      this.tags = resp;
      for (let i = 0; i < this.tags.length; i++) {
        let enter = false;
        for (const tag of newsObtained.tags) {
          if (tag === this.tags[i].tag) {
            this.tagsArray.push(new FormControl(`${i}`));
            this.checkedTags.push(true);
            enter = true;
            break;
          }
        }
        if (!enter) {
          this.checkedTags.push(false);
        }
      }
    });

    if (newsObtained.imagenes && newsObtained.imagenes.length) {
      for (let i = 0; i < newsObtained.imagenes.length; i++) {
        this.addImageControl(newsObtained.imagenes[i], i);
      }
    }
  }

  editNews(): void {
    if (this.form.valid && !this.loadingImage) {
      const news = this.createNews();
      // console.log(news);
      if (this.newsObtained) {
        this.estaCargando = true;
        console.log(news);

        this.machalaAdminService.putNews(this.newsObtained.id, news).subscribe(
          (resp) => {
            this.router.navigateByUrl('/noticias');
            Notify('Noticia modificada correctamente.', null, null, 'success');
            this.estaCargando = false;
          },
          (err) => {
            Notify('Error, revise la información.', null, null, 'danger');
            this.estaCargando = false;
          }
        );
      }
    } else {
      this.validForm();
      Notify('Error, revise la información.', null, null, 'danger');
    }
  }

  addNews(): void {
    if (this.form.valid && !this.loadingImage) {
      const news = this.createNews();
      this.estaCargando = true;
      this.machalaAdminService.createNews(news).subscribe(
        (resp) => {
          this.estaCargando = false;
          this.router.navigateByUrl('/noticias');
          Notify('Noticia creada correctamente.', null, null, 'success');
        },
        (err) => {
          this.estaCargando = false;
          Notify('Error, revise la información.', null, null, 'danger');
        }
      );
    } else {
      this.validForm();
      Notify('Error, revise la información.', null, null, 'danger');
    }
  }

  createNews(): CreateNewsInterface {
    const imagesNewNews = this.formArray.controls
      .filter((values) => values.value)
      .map((values) => values.value);
    const tagsNewNews: string[] = this.tagsArray.controls.map(
      (values) => this.tags[values.value].id_tag
    );

    const news: CreateNewsInterface = new CreateNewsInterface();
    news.titulo = this.form.get('title').value;
    news.subtitulo = this.form.get('subtitle').value;
    news.descripcion = this.form.get('content').value;
    news.timestamp = this.form.get('timestamp').value;

    console.log(news.timestamp);

    const contentImgVid: CreateNewsURL[] = [];
    if (this.form.get('urlYoutube').value) {
      const video: CreateNewsURL = {
        tipo: 'video',
        url: this.form.get('urlYoutube').value,
      };
      contentImgVid.push(video);
    }

    if (imagesNewNews.length) {
      imagesNewNews.forEach((src) => {
        const imagen: CreateNewsURL = {
          tipo: 'imagen',
          url: src,
        };
        contentImgVid.push(imagen);
      });
    }
    news.urls = contentImgVid;
    const contentTags: CreateNewsTag[] = [];
    if (tagsNewNews.length) {
      tagsNewNews.forEach((idTag: string) => {
        const tag: CreateNewsTag = {
          id: parseInt(idTag, 10),
        };
        contentTags.push(tag);
      });
    }
    news.tags = contentTags;

    return news;
  }

  openModalPreview(): void {
    const news = this.createNews();

    this.modalPreviewNews.setNews(news);
    this.modalPreviewNews.openModal();
  }

  get invalidTitle(): boolean {
    return this.form.get('title').invalid && this.form.get('title').touched;
  }

  get invalidSubtitle(): boolean {
    return (
      this.form.get('subtitle').invalid && this.form.get('subtitle').touched
    );
  }

  get invalidContent(): boolean {
    return this.form.get('content').invalid && this.form.get('content').touched;
  }

  get invalidURLYoutube(): boolean {
    return (
      this.form.get('urlYoutube').invalid && this.form.get('urlYoutube').touched
    );
  }

  get invalidTimestamp(): boolean {
    return (
      this.form.get('timestamp').invalid && this.form.get('timestamp').touched
    );
  }

  validForm(): void {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach((control) =>
        control.markAllAsTouched()
      );
    }
  }
}
