<section #formContainer class="collapsible">
  <section class="form-transparency container-fluid">
    <h5 class="mt-3">Editar Archivo</h5>
    <form [formGroup]="formTransparency" (ngSubmit)="saveFile()">
      <div class="row">
        <div class="col-8">
          <div class="form-group">
            <label for="f-m-name-acco">Nombre</label>
            <input
              type="text"
              name="nombre"
              id="f-m-name-acco"
              class="form-control"
              placeholder="Nombre archivo"
              aria-describedby="helpId-f-name-t"
              formControlName="name"
              [class.is-invalid]="invalidName"
            />
            <small *ngIf="invalidName" id="helpId-f-name-t" class="text-danger"
              >Ingrese un nombre para el archivo.</small
            >
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="f-m-anio-acco">Año</label>
            <select
              id="f-m-anio-acco"
              class="form-control"
              formControlName="anio"
            >
              <option
                *ngFor="let item of year"
                [selected]="item == this.formTransparency.get('anio').value"
              >
                {{ item }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <hr />
        <label for="">Subir archivos menores a 5mb</label>
        <input
          type="file"
          name="nombre"
          id="f-m-file-acco"
          class="form-control-file"
          placeholder="Nombre archivo"
          aria-describedby="helpId-f-file-t"
          accept=".doc,.docx,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          formControlName="file"
          (change)="onFileChange($event)"
        />
        <label for="f-m-file-acco"
          ><a
            name=""
            type="button"
            id=""
            class="btn btn-outline-primary btn-block"
            role="button"
            ><i class="glyph-icon flaticon-file"></i> Subir un archivo nuevo</a
          ></label
        >
        <div class="mt-2">
          Estado del archivo nuevo:
          <span
            class="text-danger"
            *ngIf="!file && !this.formTransparency.get('filePath').value"
            >Aun no se carga ningun archivo.</span
          >
          <span class="text-secondary" *ngIf="loadNumber > 0 && loadNumber < 99"
            >Subiendo archivo al servidor.</span
          >
          <span class="text-success" *ngIf="file && loadNumber >= 99"
            >Archivo subido al servidor.</span
          >
        </div>
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            [ngStyle]="{ width: loadNumber + '%' }"
            attr.aria-valuenow="{{ loadNumber }}"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <small *ngIf="invalidFile" class="text-danger"
          >Ingrese un nombre para el archivo.</small
        >
        <div class="text-success">
          *Si no desea modificar el archivo anterior, ignore este campo.
        </div>
        <hr />
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-block"
        [disabled]="loadingFile"
      >
        Modificar
      </button>
      <div class="d-flex justify-content-center" *ngIf="isLoading">
        <div class="img-load">
          <img src="assets/img/load.gif" alt="" />
        </div>
      </div>
    </form>
  </section>
</section>
