<section #previewNewsModal class="preview-news-modal">
  <div class="preview-news-container">
    <div class="close-preview">
      <i class="glyph-icon flaticon-cancelar" (click)="closeModal()"></i>
    </div>
    <div class="container-preview container" *ngIf="news">
      <div class="carousel-news">
        <div class="carousel__date-info">
          <span class="info__day">
            {{ news.timestamp | date: "dd" }}
          </span>
          <span class="info__mount">
            {{ news.timestamp | date: "MMM" }}
          </span>
        </div>
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let item of images">
            <div class="slide">
              <img [src]="item" alt="img 1" />
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <h4>{{ news.titulo }}</h4>
      <h6 class="subtitle-color">
        <strong>{{ news.subtitulo }}</strong>
      </h6>
      <span>
        <i class="glyph-icon flaticon-calendar"></i>
      </span>
      <div class="container-tag">
        <div class="tags">
          <div class="tag" *ngFor="let tag of tagsValue">
            {{ tag }}
          </div>
        </div>
      </div>

      <div class="content-news" [innerHTML]="news.descripcion"></div>

      <div class="video-news" *ngIf="safeURLVideo">
        <iframe
          [src]="safeURLVideo"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
  <div style="height: 2rem"></div>
</section>
