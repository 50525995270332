<section class="news-global-container container normal-text">
  <div class="title-news">
    <div class="title__add-news">
      <i
        routerLink="/noticias/crear-noticia"
        class="glyph-icon flaticon-add large-text"
      ></i>
    </div>
    <h2 class="border-title"><span>Noticias</span></h2>
  </div>

  <div *ngIf="!noRecords">
    <div class="table-container">
      <div class="loading-list">
        <img src="assets/img/load.gif" alt="" *ngIf="loadingList" />
      </div>
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th class="table__col-1" scope="col">Noticias</th>
            <th class="table__col-2" scope="col">Gestionar</th>
          </tr>
        </thead>
        <tbody class="news_list" *ngIf="news.length">
          <tr *ngFor="let noticia of news">
            <td class="ellipsis table__col-1">{{ noticia.titulo }}</td>
            <td class="d-flex">
              <button
                type="button"
                class="btn btn-success"
                (click)="viewNews(noticia.id)"
              >
                <span class="pc-text">Ver</span>
                <span class="movil-icon"><i class="flaticon-view"></i></span>
              </button>
              <button
                type="button"
                class="btn btn-warning"
                (click)="editNews(noticia.id)"
              >
                <span class="pc-text">Editar</span>
                <span class="movil-icon"
                  ><i class="glyph-icon flaticon-edit"></i
                ></span>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="openModal(noticia.id)"
              >
                <span class="pc-text">Eliminar</span>
                <span class="movil-icon"
                  ><i class="glyph-icon flaticon-delete"></i
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody class="news_list" *ngIf="!news.length">
          <tr *ngFor="let load of newsLoading">
            <td>
              <div class="loading-i"></div>
            </td>
            <td>
              <div class="loading-i"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination
      class="pagination-c"
      [totalItems]="totalPagB"
      (pageChanged)="getNewsId($event.page)"
      [maxSize]="maxPosition"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
    ></pagination>
  </div>
  <div *ngIf="noRecords">
    <h1 class="text-center">No existen Registros</h1>
  </div>
</section>
<app-modal
  #modalDeleteNews
  [esUsado]="'delete-news'"
  [header]="'Eliminar'"
  [body]="'Está seguro que desea eliminar la noticia.'"
  [msjBotonAcep]="'Eliminar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="deleteNews()"
></app-modal>
