import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };
  URL = 'https://www.aguasmachala.com:3000/api/v1';

  constructor(private httpClient: HttpClient) {}

  concatURL(subURL: string): string {
    return this.URL + subURL;
  }

  makePostRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.httpClient.post(URL, data, headers);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.delete(URL, headers);
  }

  makePutRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.httpClient.put(URL, data, headers);
  }
  checkPayBill(ULTIMA_FACTURA: number, NUMERO_CUENTA: number): Promise<any> {
    const PATH = this.concatURL(`/payment/check-payment?ULTIMA_FACTURA=${ULTIMA_FACTURA}&NUMERO_CUENTA=${NUMERO_CUENTA}`);
    return this.makeGetRequest(PATH).toPromise();
  }

  payment(nroAccount: number, total: number, card: string, lastBillNumber: number, idPago: string, cardNumber: string): Observable<any> {
    const path = this.concatURL('/payment');
    return this.makePostRequest(path, {NUMERO_CUENTA: nroAccount, VALOR_PAGAR: total, FORMA_PAGO: card, ULTIMA_FACTURA: lastBillNumber, ID_PAGO: idPago, NUMERO_TARJETA: cardNumber});
  }
}
