<div class="container-form template-achievement">
  <div class="loading" *ngIf="loadingAchievement">
    <img src="assets/img/load.gif" alt="">
  </div>
  <div #formCard class="card-form-init">

    <div class="form-add-achievement">
      <form #formContainer class="m-auto" (submit)='saveAchievement()' [formGroup] = 'form'>
        <div class="form-group">
          <input
            type="text"
            name=""
            id=""
            class="form-control"
            placeholder="Cantidad"
            aria-describedby="helpId"
            formControlName='cantidad'
            [class.is-invalid]='invalidQuatity'
          />
          <small *ngIf="invalidQuatity" id="helpId" class="text-danger">Ingrese una cantidad numérica.</small>
        </div>
        <div class="form-group">
          <select class="form-control" name="" id="" formControlName='unidad' [class.is-invalid]='invalidUnit'>
            <option>Seleccione</option>
            <option value="u">Unidades - u</option>
            <option value="%">Porcentaje - %</option>
            <option>Km</option>
            <option>m</option>
          </select>
          <small *ngIf="invalidUnit" id="helpId" class="text-danger">Informacíon requerida</small>
        </div>
        <div class="form-group">
          <textarea
            name=""
            id=""
            class="form-control"
            placeholder="Descripción"
            aria-describedby="helpId"
            formControlName='descripcion'
            [class.is-invalid]='invalidDescription'
            rows="2"
          ></textarea>
          <small *ngIf="invalidDescription" id="helpId" class="text-danger">Informacíon requerida</small>
        </div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary text-center"
            (click)="changeState()"
          >
            <i class="glyph-icon flaticon-view"></i>
          </button>
          <button type="submit" class="btn btn-success text-center" *ngIf='!modifyAchievement'>
            <i class="glyph-icon flaticon-floppy-disk"></i>
          </button>
          <button type="button" class="btn btn-success text-center" (click)='updateAchievent(achievementToModify.id)' *ngIf='modifyAchievement'>
            <i class="glyph-icon flaticon-floppy-disk"></i>
          </button>
        </div>
      </form>
    </div>

    <div #previewContainer class="preview-achievement text-center p-2">
      <div class="m-auto">
        <h3 class="mb-2 title-achievement">+{{this.form.get('cantidad').value}} {{this.form.get('unidad').value}}</h3>
        <p class="m-0">
          {{this.form.get('descripcion').value}}
        </p>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-warning text-center"
            (click)="changeState()"
          >
            <i class="glyph-icon flaticon-edit"></i>
          </button>
          <button type="button" class="btn btn-danger text-center" *ngIf='modifyAchievement' (click)='this.openModal(achievementToModify.id)'>
            <i class="glyph-icon flaticon-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>

<app-modal #modalAchievement id='modal-achi-{{id}}' [esUsado]="'delete-news'" [header]="'Eliminar'"
  [body]="'Está seguro que desea eliminar la noticia.'" [msjBotonAcep]="'Eliminar'" [msjBotonCan]="'Cancelar'"
  (acepto)="this.deleteAchievement()" [esUsado]='id'></app-modal>

