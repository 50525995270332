import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserLogin } from '../interfaces/user-login.interface';
import { LoginInterface } from '../interfaces/login-data.interface';
import { Achievement } from '../interfaces/create-achievement.interface';
import { User } from '../interfaces/user.interface';
import { CreateNewsInterface } from '../interfaces/create-news.interface';
import { url } from 'inspector';
import { Me } from '../interfaces/me.interface';
import { EditUser } from '../interfaces/edit-user.interface';
import {
  Accountability,
  Transparency,
} from '../interfaces/transparency.insterface';
import {
  TransparencyList,
  TransparencyUpdate,
} from '../interfaces/transparency-list.interface';
import { AnnouncementForm, AnnouncemetResponse, AnnouncemetResponseID, AnnouncemetResponse_Charge, CreateAnnouncemetResponse, PutAnnouncemetResponse } from 'src/app/pages/announcement/interface/announcemet.response';
import { AddFileChargeResponse, ChargeIdResponse, ChargeResponse, CreateChargeResponse, FormCreate } from 'src/app/pages/charges/interface/charge.response';
import { BaseResponse } from 'src/shared/interfaces/responses/base.response';

interface QueryTransaction {
  state: string;
  limit: number;
  offset: number;
}

@Injectable({
  providedIn: 'root',
})
export class MachalaAdminService {
  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };
  URL = 'https://www.aguasmachala.com:3000';
  URL_DATA = 'https://www.aguasmachala.com:3000';
  userData: Me;
  constructor(private httpClient: HttpClient) {}

  concatURL(subURL: string): string {
    return this.URL + subURL;
  }

  makePostRequest(
    URL: string,
    data: any,
    /* headers = this.httpOptions */
  ): Observable<any> {
    return this.httpClient.post(URL, data);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.delete(URL, headers);
  }

  makePutRequest(
    URL: string,
    data: any,
  ): Observable<any> {
    return this.httpClient.put(URL, data);
  }

  loginUser(userLogin: UserLogin): Observable<any> {
    const URL = this.concatURL('/api/v1/autenticacion/login');
    return this.makePostRequest(URL, userLogin).pipe(
      map((resp: LoginInterface) => resp.data)
    );
  }

  createAchievement(dataAchievement: Achievement): Observable<any> {
    const URL = this.concatURL('/api/v1/logros');
    return this.makePostRequest(URL, dataAchievement);
  }

  getAchievements(): Observable<any> {
    const URL = this.concatURL('/api/v1/logros');
    return this.makeGetRequest(URL);
  }

  createUser(user: User): Observable<any> {
    const URL = this.concatURL('/api/v1/admin/usuario');
    return this.makePostRequest(URL, user);
  }

  getUserMe(): Observable<any> {
    const URL = this.concatURL('/api/v1/user/me');
    if (this.userData) {
      return of(this.userData);
    } else {
      return this.makeGetRequest(URL).pipe(
        map((resp) => {
          this.userData = resp;
          return resp;
        })
      );
    }
  }

  emptyUserMe(): void {
    this.userData = undefined;
  }

  getUsers(): Observable<any> {
    const URI = this.concatURL(`/api/v1/admin/usuario`);
    return this.makeGetRequest(URI);
  }

  getUserById(id: number): Observable<any> {
    const URI = this.concatURL(`/api/v1/admin/usuario/${id}`);
    return this.makeGetRequest(URI);
  }

  delAchievement(id: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/logros/${id}`);
    return this.makeDelRequest(URL);
  }

  putAchievement(id: string, data: Achievement): Observable<any> {
    const URL = this.concatURL(`/api/v1/logros/${id}`);
    return this.makePutRequest(URL, data);
  }

  putUser(user: EditUser): Observable<any> {
    const URL = this.concatURL('/api/v1/admin/usuario');
    return this.makePutRequest(URL, user);
  }

  getTags(): Observable<any> {
    const URL = this.concatURL(`/api/v1/tag`);
    return this.makeGetRequest(URL);
  }

  /* Announcement */
  getAnnouncement(): Observable<AnnouncemetResponse> {
    const URL = this.concatURL(
      `/api/v1/convocatoria`
    );
    return this.makeGetRequest(URL);
  }
  getAnnouncementById(id: string): Observable<AnnouncemetResponseID> {
    const URL = this.concatURL(`/api/v1/convocatoria/get_by_id/${id}`);
    return this.makeGetRequest(URL);
  }
  getAnnouncementById_Charges(id: string): Observable<AnnouncemetResponse_Charge> {
    const URL = this.concatURL(`/api/v1/convocatoria/availability_charges/${id}`);
    return this.makeGetRequest(URL);
  }
  deleteAnnouncement(id: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/convocatoria/delete/${id}`);
    return this.makeDelRequest(URL);
  }
  deleteCharge_Announcement(id: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/convocatoria/delete_charge/${id}`);
    return this.makeDelRequest(URL);
  }

  createAnnouncement(formData: FormData): Observable<CreateAnnouncemetResponse> {
    const URL = this.concatURL(`/api/v1/convocatoria`);
    return this.makePostRequest(URL,formData);
  }
  putAnnouncement(id: string, formData: FormData): Observable<PutAnnouncemetResponse> {
    const URL = this.concatURL(`/api/v1/convocatoria/update/${id}`);
    return this.makePutRequest(URL, formData);
  }
  assignChargeAnnouncement(form:any): Observable<BaseResponse> {
    const URL = this.concatURL(`/api/v1/convocatoria/assign_charge`);
    return this.makePostRequest(URL,form);
  }

  /*Charges*/ 
  getCharges(): Observable<ChargeResponse> {
    const URL = this.concatURL(
      `/api/v1/cargo`
    );
    return this.makeGetRequest(URL);
  }
  getChargeById(id: string): Observable<ChargeIdResponse> {
    const URL = this.concatURL(`/api/v1/cargo/${id}`);
    return this.makeGetRequest(URL);
  }
  getChargeById_Announcement(id: string): Observable<AddFileChargeResponse> {
    const URL = this.concatURL(`/api/v1/convocatoria/get_files/${id}`);
    return this.makeGetRequest(URL);
  }
  createCharge(form: FormCreate): Observable<CreateChargeResponse> {
    const URL = this.concatURL(`/api/v1/cargo`);
    return this.makePostRequest(URL,form);
  }
  putCharge(id: string, form: FormCreate): Observable<CreateChargeResponse> {
    const URL = this.concatURL(`/api/v1/cargo/${id}`);
    return this.makePutRequest(URL, form);
  }
  putChargeFile(id: string, form: FormData): Observable<CreateChargeResponse> {
    const URL = this.concatURL(`/api/v1/convocatoria/update_charge_file/${id}`);
    return this.makePutRequest(URL, form);
  }
  deleteCharge(id: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/cargo/${id}`);
    return this.makeDelRequest(URL);
  }

  /* News */

  getNews(offset: number, limit = 6): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/noticias?offset=${offset}&limit=${limit}`
    );
    return this.makeGetRequest(URL);
  }

  createNews(news: CreateNewsInterface): Observable<any> {
    const URL = this.concatURL(`/api/v1/noticias`);
    return this.makePostRequest(URL, news);
  }

  putNews(id: string, news: CreateNewsInterface): Observable<any> {
    const URL = this.concatURL(`/api/v1/noticias/${id}`);
    return this.makePutRequest(URL, news);
  }

  deleteNews(id: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/noticias/${id}`);
    return this.makeDelRequest(URL);
  }

  getTotalNews(): Observable<any> {
    const URL = this.concatURL(`/api/v1/noticias/total`);
    return this.makeGetRequest(URL);
  }

  getNewsById(id: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/noticias/${id}`);
    return this.makeGetRequest(URL);
  }

  getDataServer(): Observable<any> {
    const DATA = {
      sql: 'select * from TRC_SOLICITUD_RECLAMO WHERE EXTRACT(YEAR FROM FECHA_INGRESO)=2019 AND EXTRACT(MONTH FROM FECHA_INGRESO)=6',
    };
    return this.httpClient.post(this.URL_DATA, DATA, this.httpOptions);
  }
  getClaims(): Observable<any> {
    const URL = this.concatURL(`/api/v1/reclamos`);
    return this.makeGetRequest(URL);
  }

  getClaim(id: string): Observable<any> {
    // const URL = this.concatURL(`/api/v1/reclamos/claim/${id}`);
    const URL = this.concatURL(`/api/v1/admin/reclamos/${id}?type=1`);
    return this.makeGetRequest(URL);
  }

  getComplaint(id: string): Observable<any> {
    // const URL = this.concatURL(`/api/v1/reclamos/complaint/${id}`);
    const URL = this.concatURL(`/api/v1/admin/reclamos/${id}?type=2`);
    return this.makeGetRequest(URL);
  }

  getClaimsPagination(offset = 0, limit = 0): Observable<any> {
    let URL = '';
    if (limit) {
      // URL = this.concatURL( `/api/v1/reclamos/claim?offset=${offset}&limit=${limit}`);
      URL = this.concatURL(
        `/api/v1/admin/reclamos?type=1&offset=${offset}&limit=${limit}&status=1`
      );
    } else {
      URL = this.concatURL(`/api/v1/admin/reclamos?type=1&status=1`);
      //URL = this.concatURL(`/api/v1/reclamos/claim`);
    }

    return this.makeGetRequest(URL);
  }

  getComplaintPagination(offset = 0, limit = 0): Observable<any> {
    let URL = '';
    if (limit) {
      // URL = this.concatURL(`/api/v1/reclamos/complaint?offset=${offset}&limit=${limit}`);
      URL = this.concatURL(
        `/api/v1/admin/reclamos?type=2&offset=${offset}&limit=${limit}&status=1`
      );
    } else {
      //URL = this.concatURL(`/api/v1/reclamos/complaint`);
      URL = this.concatURL(`/api/v1/admin/reclamos?type=2&status=1`);
    }

    return this.makeGetRequest(URL);
  }

  test(): Observable<any> {
    const URL = 'https://www.aguasmachala.com:3000';
    return this.makeGetRequest(URL);
  }

  getTotalClaims(): Observable<any> {
    const URL = this.concatURL(`/api/v1/oracle/claims`);
    return this.makeGetRequest(URL);
  }

  getRoles(): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/roles`);
    return this.makeGetRequest(URL);
  }

  getTransparency(): Observable<any> {
    const URL = this.concatURL('/api/v1/transparencia');
    return this.makeGetRequest(URL);
  }

  createTransparency(transparency: Transparency): Observable<any> {
    const URL = this.concatURL('/api/v1/transparencia');
    return this.makePostRequest(URL, transparency);
  }

  createAccountability(accountability: Accountability): Observable<any> {
    const URL = this.concatURL('/api/v1/rendicionCuentas');
    return this.makePostRequest(URL, accountability);
  }

  putTransparency(transparency: TransparencyUpdate): Observable<any> {
    const URL = this.concatURL(`/api/v1/transparencia`);
    return this.makePutRequest(URL, transparency);
  }

  putAccountability(
    id: string,
    accountability: Accountability
  ): Observable<any> {
    const URL = this.concatURL(`/api/v1/rendicionCuentas/${id}`);
    return this.makePutRequest(URL, accountability);
  }

  deleteUser(id: number): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/usuario/${id}`);
    return this.makeDelRequest(URL);
  }

  getTest(): Observable<any> {
    return this.makeGetRequest(this.URL_DATA);
  }

  getClaimsState(): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/estados-reclamo`);
    return this.makeGetRequest(URL);
  }

  changeStateClaim(id: string, state: any): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/reclamos/${id}/status`);
    return this.makePutRequest(URL, state);
  }

  deleteTransparency(id: number): Observable<any> {
    const URL = this.concatURL(`/api/v1/transparencia/${id}`);
    return this.makeDelRequest(URL);
  }

  getAccountabilities(): Observable<any> {
    const URL = this.concatURL('/api/v1/rendicionCuentas');
    return this.makeGetRequest(URL);
  }

  deleteAccountability(idAccointability: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/rendicionCuentas/${idAccointability}`);
    return this.makeDelRequest(URL);
  }

  //* ------------------------Dashboard---------------------

  getYearsDashboard(): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/dashboard/anios`);
    return this.makeGetRequest(URL);
  }

  getMonthsDashboard(anio: number): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/dashboard/anios/${anio}/meses`);
    return this.makeGetRequest(URL);
  }

  getGlobalIndicatorsDashboard(anio: number, mes: string): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/indicadoresGlobales?anio=${anio}&mes=${mes}`
    );
    return this.makeGetRequest(URL);
  }

  getClaimFormatDashboard(anio: number, mes: string): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/formatoReclamo?anio=${anio}&mes=${mes}`
    );
    return this.makeGetRequest(URL);
  }

  getLatLogDashboard(): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/dashboard/latlngReclamos`);
    return this.makeGetRequest(URL);
  }

  getClaimByTypeDashboard(
    anio: number,
    mes: string,
    type: string
  ): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/reclamosPorTipo?anio=${anio}&mes=${mes}&estado=${type}`
    );
    return this.makeGetRequest(URL);
  }

  getClaimByWeekDashboard(
    anio: number,
    mes: string,
    type: string
  ): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/reclamosPorSemanaMes?anio=${anio}&mes=${mes}&estado=${type}`
    );
    return this.makeGetRequest(URL);
  }

  getClaimByYearDashboard(mes: string, type: string): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/reclamosPorAnio?mes=${mes}&estado=${type}`
    );
    return this.makeGetRequest(URL);
  }

  getClaimByDayDashboard(
    year: number,
    mes: string,
    type: string
  ): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/reclamosPorDiaSemana?anio=${year}&mes=${mes}&estado=${type}`
    );
    return this.makeGetRequest(URL);
  }

  getClaimByMountOfYearDashboard(year: number, type: string): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/reclamosPorMesAnio?anio=${year}&estado=${type}`
    );
    return this.makeGetRequest(URL);
  }

  getSummaryClaim(year: number, month: string): Observable<any> {
    const URL = this.concatURL(
      `/api/v1/admin/dashboard/resumenCantidadReclamos?anio=${year}&mes=${month}`
    );

    return this.makeGetRequest(URL);
  }

  // --------- upload others documents

  createFile(nombre: string, url: string): Observable<any> {
    const file = {
      nombre,
      url,
    };
    console.log(file);

    const URL = this.concatURL(`/api/v1/admin/archivos`);
    return this.makePostRequest(URL, file);
  }

  deleteFile(id: number): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/archivos/${id}`);
    return this.makeDelRequest(URL);
  }

  getAllFiles(): Observable<any> {
    const URL = this.concatURL(`/api/v1/admin/archivos`);
    return this.makeGetRequest(URL);
  }

  updateFile(id: number, nombre: string, url: string) {
    const file = {
      nombre,
      url,
    };
    console.log(file + ' ' + id);

    const URL = this.concatURL(`/api/v1/admin/archivos/${id}`);
    return this.makePutRequest(URL, file);
  }

  getTransactions(queryTransaction: QueryTransaction): Promise<any> {
    let url = this.concatURL(`/api/v1/transaccion`);
    url = url + '?';
    Object.keys(queryTransaction).forEach((key) => {
      const value = queryTransaction[key];
      if (value) {
        url += `${key}=${value}&`;
      }
    });
    url = url.substring(0, url.length - 1);
    console.log(url);

    return this.makeGetRequest(url).toPromise();
  }

  updateTransaction(id: number, transaction: any): Promise<any> {
    const path = this.concatURL('/api/v1/transaccion/'+id);
    return this.makePutRequest(path, transaction).toPromise();
  }
}
