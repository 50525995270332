<!--
<section #previewClaimModal class="preview-claim-modal">
  <div class="preview-claim-container">
    <div class="close-preview">
      <i class="glyph-icon flaticon-cancelar" (click)='closeModal()'></i>
    </div>
    <div class="container-preview container" >
      <header>
        <h1 class="border-title"><span>Reclamo</span></h1>
      </header>
      <div class="content">
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>Cédula:</span></strong></h5>
            <p>0125682566</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>RUC:</span></strong></h5>
            <p>0125682566001</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>Nro Cuenta:</span></strong></h5>
            <p>0125682566001</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>Nombre:</span></strong></h5>
            <p>Christian Xavier Collaguazo Malla</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>Email:</span></strong></h5>
            <p>christian.colla@gmail.com</p>
          </div>

          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>Fecha:</span></strong></h5>
            <p>12/06/2020</p>
          </div>

          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>Teléfono de Casa:</span></strong></h5>
            <p>2368965</p>
          </div>
          <div class="col-12 col-md-6 col-xl-4">
            <h5><strong class="border-title text-third"><span>Teléfono Celular:</span></strong></h5>
            <p>0125682566</p>
          </div>
          <div class="col-12">
            <h5><strong class="border-title text-third"><span>Categoría de servicio:</span></strong></h5>
            <ul>
              <li>
                Servicio de agua potable
              </li>
            </ul>
          </div>

          <div class="col-12">
            <div class="content-claim text-justify">
              <h2><strong class="border-title text-third"><span>Información del reclamo:</span></strong></h2>
              <p *ngFor="let item of [1,1]">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eaque eveniet officiis, dolore est pariatur
                eius ipsam rem natus nam minus tenetur autem. Ipsum corporis commodi, atque exercitationem minus id ut.
              </p>
            </div>
          </div>

          <div class="col-12" *ngIf="false">
            <div class="map-claim">
              <agm-map [latitude]="-3.2572415" [zoom]="14" [longitude]="-79.9569167">
                <agm-marker [latitude]="-3.263372" [longitude]="-79.968497"></agm-marker>
              </agm-map>
            </div>


          </div>
        </div>

      </div>
    </div>
  </div>
  <div style="height: 2rem;">

  </div>
</section>  -->
<section class="view-claim-container container normal-text">
  <form class="mb-4" *ngIf="claim">
    <div class="form-group d-flex justify-content-end">
      <select
        class="form-control h-100 normal-text"
        style="max-width: 200px"
        (change)="changeState($event.target.value)"
      >
        <option value="-1" selected>Cambiar Estado</option>
        <option [value]="state.id_estado" *ngFor="let state of claimsStates">
          {{ state.estado }}
        </option>
      </select>
      <button
        type="button"
        class="btn btn-success clean-text normal-text"
        (click)="openModal()"
      >
        Enviar
      </button>
    </div>

    <div *ngIf="idState == '2'">
      <label for=""
        >Elija un el código de reclamo para ser enviado a la bd.</label
      >
      <div class="tags-claim">
        <div class="tag-claim" *ngFor="let states of statesDB; let i = index">
          <input
            type="radio"
            name="estados-rcl"
            id="f-cla-ch-{{ i }}"
            [checked]="stateCode === states.id"
            [value]="states.id"
            (change)="onItemChange($event.target.value)"
          />
          <label for="f-cla-ch-{{ i }}" class="m-0"
            ><small
              >{{ states.id }} - {{ states.value | titlecase }}</small
            ></label
          >
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="idState == '3'">
      <label for="complaint-f-ta">Ingrese el motivo del rechazo.</label>
      <textarea
        class="form-control"
        name="motivo-rechazo"
        id="complaint-f-ta"
        rows="3"
        [(ngModel)]="reasonClaim"
      ></textarea>
      <small class="text-danger" *ngIf="!reasonClaim">Ingrese un motivo.</small>
    </div>
  </form>

  <div class="content" *ngIf="claim">
    <section class="mb-4">
      <h2 class="border-title"><span>Gestión del Reclamo</span></h2>
      <h4 class="subtitle-color">Reclamo Domiciliario</h4>
      <h5>
        Estado: <span class="text-success">{{ claim.estado_actual }}</span>
      </h5>
    </section>
    <div class="row">
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"
            ><span>{{ this.idLabel }}: </span></strong
          >
        </h6>
        {{ claim.id_persona }}
      </div>
      <!-- <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"><span>RUC:</span></strong>
        </h6>
        {{ claim.id_persona }}
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"><span>Nro Cuenta:</span></strong>
        </h6>
        0125682566001
      </div> -->
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"><span>Nombre:</span></strong>
        </h6>
        {{ claim.nombre }}
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"><span>Email:</span></strong>
        </h6>
        {{ claim.email }}
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"><span>Fecha:</span></strong>
        </h6>
        {{ claim.timestamp_reclamo | date }}
      </div>

      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"
            ><span>Teléfono de Contacto 1:</span></strong
          >
        </h6>
        {{ claim.telefono1 }}
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <h6 class="claim-content">
          <strong class="text-third"
            ><span>Teléfono de Contacto 2:</span></strong
          >
        </h6>
        {{ claim.telefono2 }}
      </div>
      <div class="col-12">
        <div class="mt-3">
          <h6>
            <strong class="text-third"
              ><span>Categoría de servicio:</span></strong
            >
          </h6>
          <ul>
            <li>
              {{ claim.categoria_reclamo }}
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12">
        <div class="content-claim text-justify">
          <h6>
            <strong class="text-third"
              ><span>Información del reclamo:</span></strong
            >
          </h6>
          <p>
            {{ claim.descripcion }}
          </p>
        </div>
      </div>

      <div class="col-12" *ngIf="false">
        <div class="map-claim">
          <agm-map
            [latitude]="-3.2572415"
            [zoom]="14"
            [longitude]="-79.9569167"
          >
            <agm-marker
              [latitude]="-3.263372"
              [longitude]="-79.968497"
            ></agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
  </div>
</section>
<app-modal
  #modalClaim
  [esUsado]="'view-complaint'"
  [header]="'Cambiar de estado'"
  [body]="'Está seguro que desea cambiar de estado.'"
  [msjBotonAcep]="'Continuar'"
  [msjBotonCan]="'Cancelar'"
  (acepto)="saveState()"
></app-modal>
