import { Component, OnInit, ViewChild } from '@angular/core';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { Router } from '@angular/router';
import { ModalComponent } from '../../common-pages/modal/modal.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  @ViewChild('modalDeleteNews') modalDeleteNews: ModalComponent;
  constructor(
    private machalaAdminService: MachalaAdminService,
    private router: Router
  ) {}
  totalPagB = 0;
  loadingList = false;
  news = [];
  newsLoading = ['', '', '', '', '', ''];
  totalNews = 0;
  currentPosition = 0;
  maxPosition = 4;
  position = [];
  positionAux = [];
  beforePosition = 2;
  maxNewsPag = 6;
  idNewsDelete = '';
  noRecords = false;
  ngOnInit(): void {
    for (let i = 0; i < this.maxPosition; i++) {
      this.positionAux[i] = i + 1;
    }
    this.loadingList = true;
    this.machalaAdminService.getNews(0).subscribe(
      (resp) => {
        this.news = resp;
        if(!this.news.length) {
          this.noRecords = true;
        }

        this.machalaAdminService.getTotalNews().subscribe(
          (resp) => {
            this.totalNews = resp.total;
            this.totalPagB = Math.ceil(this.totalNews / this.maxNewsPag) * 10;
            this.getItemPagination();
            this.loadingList = false;

          },
          (err) => {
            this.loadingList = false;
          }
        );
      },
      (err) => {
        this.loadingList = false;
      }
    );
  }

  getItemPagination(): void {
    const totalPag = Math.ceil(this.totalNews / this.maxNewsPag);
    for (let i = 0; i < this.maxPosition; i++) {
      // * sirve para comparar si los items a mostrar exenden al maximo de paginas a paginar
      const itemExceed =
        this.currentPosition - this.beforePosition + this.maxPosition;
      if (this.currentPosition - this.beforePosition > 0) {
        // * En caso de los items a mostrar exceden al total posible, se muestran los numero maximo de item posibles
        /*
         * total paginas = 8
         * Pos. Actual = 8
         * total a mostrar = 4
         * pag anteriores a mostrar = 2
         * -----------------------
         * Comportamiento Esperado
         * |6|7|8|
         * -----------------------
         * Comportamiento con la validacion
         * |5|6|7|8|
         */
        if (itemExceed > totalPag) {
          // El numero de pag inicia en este caso seria el total de paginas a mostrar - la maxima posicion posible y + 1 dado que
          // se inicia desde el 0
          const numSave = totalPag - this.maxPosition + i + 1;
          if (!this.breakPagination(numSave, totalPag)) {
            break;
          }
          this.position.push(numSave);
        } else {
          const numSave = this.currentPosition - this.beforePosition + i + 1;
          if (!this.breakPagination(numSave, totalPag)) {
            break;
          }
          this.position.push(numSave);
        }
      } else {
        const numSave = i + 1;
        if (!this.breakPagination(numSave, totalPag)) {
          break;
        }
        this.position.push(numSave);
      }
    }
  }

  breakPagination(numSave: number, totalPag: number): boolean {
    if (numSave <= totalPag) {
      return true;
    } else {
      return false;
    }
  }

  getNewsId(id: number): void {
    this.position = [];
    this.currentPosition = id - 1;
    const getNewIndex = this.currentPosition * this.maxNewsPag;
    this.loadingList = true;
    this.machalaAdminService.getNews(getNewIndex).subscribe(
      (resp) => {
        this.news = resp;
        this.loadingList = false;
      },
      (err) => {
        this.loadingList = false;
      }
    );
    this.getItemPagination();
  }

  viewNews(id: string): void {
    this.router.navigate(['/noticias/ver-noticia', id]);
  }

  editNews(id: string): void {
    this.router.navigate(['/noticias/editar-noticia', id]);
  }

  deleteNews(): void {
    if (this.idNewsDelete) {
      this.loadingList = true;
      this.machalaAdminService.deleteNews(this.idNewsDelete).subscribe(
        (resp) => {
          this.machalaAdminService.getTotalNews().subscribe(
            (total) => {
              this.totalNews = total.total;
              const totalPages = Math.ceil(this.totalNews / this.maxNewsPag);
              if (totalPages < this.currentPosition + 1) {
                this.currentPosition = totalPages - 1;
              }
              this.position = [];
              const getNewIndex = this.currentPosition * this.maxNewsPag;
              this.machalaAdminService.getNews(getNewIndex).subscribe(
                (news) => {
                  this.news = news;
                  this.getItemPagination();
                  this.loadingList = false;
                },
                (err) => {
                  this.loadingList = false;
                }
              );
            },
            (err) => {
              this.loadingList = false;
            }
          );
        },
        (err) => {
          this.loadingList = false;
        }
      );
    }
  }

  openModal(idNews: string): void {
    this.idNewsDelete = idNews;
    this.modalDeleteNews.mostrarPopup();
  }
}
