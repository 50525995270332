import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';


declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit {
  @Input() header: string;
  @Input() body: string;
  @Input() msjBotonAcep = '';
  @Input() msjBotonCan = 'Cancelar';
  @Input() esUsado = 'general';
  @Output() acepto = new EventEmitter<boolean>();
  @Input() tipoModal = 'sm';
  pos1 = document.body.scrollTop;
  pos2 = document.documentElement.scrollTop;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    window.addEventListener('hashchange', () => {
      if (window.location.hash !== '#modal') {
        this.cerrarPopup();
      } else {
        this.pos1 = document.body.scrollTop;
        this.pos2 = document.documentElement.scrollTop;
      }
    }, false);


  }
  mostrarPopup(){
    this.pos1 = document.body.scrollTop;
    this.pos2 = document.documentElement.scrollTop;
    $('#modal-global-' + this.esUsado).modal('show');
    window.location.hash = 'modal';
  }
  cerrarPopup() {
    $('#modal-global-' + this.esUsado).modal('hide');
    window.location.hash = '';
    document.body.scrollTop = this.pos1;
    document.documentElement.scrollTop = this.pos2;
  }

  aceptarModal(){
    $('#modal-global-' + this.esUsado).modal('hide');
    window.location.hash = '';
    document.body.scrollTop = this.pos1;
    document.documentElement.scrollTop = this.pos2;
    this.acepto.emit(true);
  }

}
