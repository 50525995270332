import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Announcement } from 'src/app/controller/announcemet.interface';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditAnnouncementResolver implements Resolve<Announcement> {

  constructor(  private readonly machalaAdminService: MachalaAdminService){}

  resolve(route: ActivatedRouteSnapshot): Observable<Announcement> {
    const id: string = route.params['id'];
    return this.machalaAdminService.getAnnouncementById(id)
    .pipe(map(next => next.announcement));
  }
}
