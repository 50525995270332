import { Component, NgZone, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl, FormBuilder, NgForm, Validators, AbstractControl } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { Subscription, throwError } from 'rxjs';
import { CompressorService } from 'src/app/helpers/compressor/compressor.service';
import { FirebaseService } from 'src/app/helpers/services/firebase.service';
import { UploadFilesService } from 'src/app/helpers/services/upload-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { Router } from '@angular/router';
import { AnnouncementForm } from '../../interface/announcemet.response';
import { finalize, catchError } from 'rxjs/operators';
declare var Notify: any;

type AnnouncementDocument = 'curriculum' | 'schedule' | 'manual' | 'titleDocument';

@Component({
  selector: 'app-create-announcement',
  templateUrl: './create-announcement.component.html',
  styleUrls: ['./create-announcement.component.scss']
})
export class CreateAnnouncementComponent implements OnInit {
  isLoading = false;
  formData: AnnouncementForm;

  form: FormGroup;

  constructor(
    private machalaAdminService: MachalaAdminService,
    private readonly fb: FormBuilder,
    private router: Router) {
    this.form = fb.group({
      title: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      note: [null],
      curriculum: [null],
      schedule: [null],
      manual: [null],
      titleDocument: [null]
    });
  }

  ngOnInit(): void {
  }

  onSelectFile(input: AnnouncementDocument, event: any) {
    const control = this.form.controls[input] as AbstractControl;
    if (control) {
      const files: FileList = event.target.files;
      if (files.length) {
        const file: File = event.target.files[0];
        control.setValue(file);
      }
    }
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      throw new Error("Formulario inválido");
    }

    this.isLoading = true;
    const value: AnnouncementForm = this.form.value as AnnouncementForm;
    console.log(value)
    const formData = new FormData();
    formData.append('titulo', value.title);
    formData.append('correo', value.email);
    formData.append('nota', value.note);
    formData.append('titulo_file', value.titleDocument);
    formData.append('hoja_de_vida_file', value.curriculum);
    formData.append('cronograma_file', value.schedule);
    formData.append('manual_descripcion_file', value.manual);
    console.log(formData);
    
    this.machalaAdminService.createAnnouncement(formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        catchError(err => {
          Notify('Error al crear convocatoria -> '+err.error.message, null, null, 'danger');
          return throwError(err)
        }),
      )
      .subscribe(() => {
        Notify('Convocatoria creada correctamente.', null, null, 'success');
        this.router.navigateByUrl('/convocatorias');
      })
  }

}
