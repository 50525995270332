import { Component, OnInit } from '@angular/core';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss'],
})
export class ComplaintsComponent implements OnInit {
  newsLoading = ['', '', '', '', '', ''];
  loading = false;
  noRecords = false;

  constructor(
    private machalaAdminService: MachalaAdminService,
    private router: Router
  ) {}
  totalPagB = 0;
  loadingList = false;
  totalNews = 0;
  currentPosition = 0;
  maxPosition = 4;
  maxNewsPag = 6;
  complaints = [];
  // complaintsStateIngresado = [];

  ngOnInit(): void {
    this.machalaAdminService.getComplaintPagination(0, 0).subscribe((resp) => {
      this.totalNews = resp.length;
      // this.totalNews = resp.filter(claim => claim.estado_actual === "Ingresado").length;
      this.totalPagB = Math.ceil(this.totalNews / this.maxNewsPag) * 10;

      this.getComplaints(1);
    });
  }
  async getComplaints(index: number): Promise<any> {
    this.loading = true;
    const limit = this.maxNewsPag;
    const offset = (index - 1) * this.maxNewsPag;
    try {
      await this.machalaAdminService
        .getComplaintPagination(offset, limit)
        .toPromise()
        .then((resp) => {
          // this.complaints = resp.complaintDB;
          this.complaints = resp;
          console.log(resp);

          // this.complaintsStateIngresado = this.complaints.filter(claim => claim.estado_actual === "Ingresado");

          this.loading = false;
          if (!this.complaints.length) {
            this.noRecords = true;
          }
        });
    } catch (error) {
      this.loading = false;
    }
  }
  openComplaint(id: string): void {
    this.router.navigate(['/denuncias/ver-denuncia', id]);
  }
}
