import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Charge } from 'src/app/controller/charge.interface';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { ModalComponent } from '../common-pages/modal/modal.component';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
declare var Notify: any;

@Component({
  selector: 'app-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss']
})
export class ChargesComponent implements OnInit {
  loadingList = false;
  charge: Charge[];
  newChargesLoading = ['', '', '', '', '', ''];
  idChargeDelete = '';
  noRecords = false;
  @ViewChild('modalDeleteCharge') modalDeleteCharge: ModalComponent;

  constructor(
    private machalaAdminService: MachalaAdminService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadingList = true;
    this.machalaAdminService.getCharges().subscribe(
      (res) => {
        this.charge = res.charges;
        this.loadingList = false;
        if (!this.charge.length) {
          this.noRecords = true;
        }
      }, (err) => {
        this.loadingList = false;
      }
    )
  }

  openModal(idCharge: string): void {
    this.idChargeDelete = idCharge;
    this.modalDeleteCharge.mostrarPopup();
  }

  editCharge(id: string): void {
    this.router.navigate(['/cargos/editar-cargo', id]);
  }

  addFile(id: string): void {
    this.router.navigate(['/cargos/agregar-archivo', id]);
  }

  deleteCharge(): void {
    if (this.idChargeDelete) {
      this.loadingList = true;
      this.machalaAdminService.deleteCharge(this.idChargeDelete)
        .pipe(
          finalize(() => {
            this.loadingList = false;
          }),
          catchError(err => {
            Notify('Error al eliminar el cargo -> ' + err.error.message, null, null, 'danger');
            return throwError(err)
          }),
        )
        .subscribe(() => {
          Notify('Cargo eliminado correctamente.', null, null, 'success');
          this.ngOnInit();
          this.router.navigateByUrl('/cargos');
        })
    }
  }

}
