<div class="modal fade" id='modal-global-{{esUsado}}' tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{header}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="cerrarPopup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="white-space: pre-line">
        {{body}}
      </div>
      <div class="modal-footer">
        <button *ngIf="msjBotonAcep !== ''" type="button" class="btn btn-primary"
          (click)='aceptarModal()'>{{msjBotonAcep}}</button>
        <button type="button" class="btn btn-danger" (click)="cerrarPopup()">{{msjBotonCan}}</button>
      </div>
    </div>
  </div>
</div>
