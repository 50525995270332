import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MachalaAdminService } from '../../../helpers/services/machala-admin.service';
import { routes } from '../../../app.routes';
import { state } from '@angular/animations';
import { ModalComponent } from '../../common-pages/modal/modal.component';
declare var Notify: any;

@Component({
  selector: 'app-view-claim',
  templateUrl: './view-claim.component.html',
  styleUrls: ['./view-claim.component.scss'],
})
export class ViewClaimComponent implements OnInit {
  @ViewChild('modalClaim') modalClaim: ModalComponent;
  claim: any;
  claimsStates = [];
  idState = '-1';
  stateCode = 'CAT';
  reasonClaim = '';
  enterClaim = true;
  typeId = [
    { id: 'CED', value: 'Cedula' },
    { id: 'RUC', value: 'RUC' },
    { id: 'NRO', value: 'Numero de Cuenta' },
  ];
  idLabel = '';

  /*statesDB = [
    { id: 'AFA', value: 'ALTA FACTURACION' },
    { id: 'CAT', value: 'CATEGORIA' },
    { id: 'DOB', value: 'DOBLE EMISION' },
    { id: 'IAG', value: 'INEXISTENCIA DE AGUA' },
    { id: 'IAL', value: 'INEXISTENCIA DE ALCANTARILLADO' },
    { id: 'CIM', value: 'COBRO INDEBIDO DE MULTAS' },
    { id: 'CII', value: 'COBRO INDEBIDO DE INTERESES' },
    { id: 'IGU', value: 'INEXISTENCIA DE GUIA' },
    { id: 'PMI', value: 'PAGO MAL IMPUTADO' },
    { id: 'PNI', value: 'PAGO NO IMPUTADO' },
    { id: 'MRA', value: 'MANT. RED TERCIARIA ALCANT' },
    { id: 'VDC', value: 'VIVIENDA DESHABITADA CON SUSPENSION' },
    { id: 'VDS', value: 'VIVIENDA DESHABITADA SIN SUSPENSION' },
    { id: 'FAG', value: 'FUGA DE AGUA' },
    { id: 'CSA', value: 'CONSTRUCCION SUSPENDIDA ANTIGUA' },
    { id: 'CSN', value: 'CONSTRUCCION SUSPENDIDA NUEVA' },
    { id: 'SSV', value: 'SUSPENSION POR SOLAR VACIO' },
    { id: 'AME', value: 'AGUA EN MAL ESTADO' },
    { id: 'RAC', value: 'ROTURA DE ACUEDUCTO' },
  ];*/

  statesDB = [
    { id: 'CAT', value: 'CATEGORIA' },
    { id: 'FAG', value: 'FUGA DE AGUA' },
    { id: 'DOB', value: 'DOBLE EMISION' },
    { id: 'AFA', value: 'ALTA FACTURACION' },
    //{ id: 'PNI', value: 'PAGO NO IMPUTADO' },
    //{ id: 'PMI', value: 'PAGO MAL IMPUTADO' },
    { id: 'AME', value: 'AGUA EN MAL ESTADO' },
    //{ id: 'RAC', value: 'ROTURA DE ACUEDUCTO' },
    { id: 'IAG', value: 'INEXISTENCIA DE AGUA' },
    { id: 'IGU', value: 'INEXISTENCIA DE GUIA' },
    //{ id: 'CIM', value: 'COBRO INDEBIDO DE MULTAS' },
    { id: 'MRA', value: 'MANT. RED TERCIARIA ALCANT' },
    { id: 'SSV', value: 'SUSPENSION POR SOLAR VACIO' },
    //{ id: 'CII', value: 'COBRO INDEBIDO DE INTERESES' },
    { id: 'CSN', value: 'CONSTRUCCION SUSPENDIDA NUEVA' },
    { id: 'IAL', value: 'INEXISTENCIA DE ALCANTARILLADO' },
    { id: 'CSA', value: 'CONSTRUCCION SUSPENDIDA ANTIGUA' },
    { id: 'VDC', value: 'VIVIENDA DESHABITADA CON SUSPENSION' },
    { id: 'VDS', value: 'VIVIENDA DESHABITADA SIN SUSPENSION' },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private machalaAdminService: MachalaAdminService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((resp) => {
      this.getClaim(resp.id);
    });
    this.machalaAdminService.getClaimsState().subscribe((resp) => {
      this.claimsStates = resp;
    });
  }

  getClaim(id: any): void {
    this.machalaAdminService.getClaim(id).subscribe(
      (claim) => {
        this.claim = claim;
        this.idLabel = this.typeId.filter(
          (resp) => resp.id === claim.identificador_id_persona
        )[0].value;
      },
      (err) => {
        this.router.navigateByUrl('/reclamos');
      }
    );
  }

  openModal(): void {
    this.modalClaim.mostrarPopup();
    // if (!this.previewClaimModal.nativeElement.classList.contains('activate')) {
    //   this.previewClaimModal.nativeElement.classList.add('activate');
    //   document.body.style.overflow = 'hidden';
    // }
  }

  closeModal(): void {
    // if (this.previewClaimModal.nativeElement.classList.contains('activate')) {
    //   this.previewClaimModal.nativeElement.classList.remove('activate');
    //   document.body.style.overflow = '';
    // }
  }

  changeState(value: any): void {
    this.idState = value;
  }

  onItemChange(value: any): void {
    this.stateCode = value;
  }

  saveState(): void {
    if (this.idState != '-1') {
      let changeCode: any;
      if (this.idState === '2') {
        changeCode = { estado: this.idState, codigo_reclamo: this.stateCode };
      } else if (this.idState === '3') {
        if (this.reasonClaim) {
          changeCode = {
            estado: this.idState,
            razon_rechazo: this.reasonClaim,
          };
          this.enterClaim = true;
        } else {
          this.enterClaim = false;
        }
      } else {
        changeCode = { estado: this.idState };
      }
      if (this.enterClaim || this.idState !== '3') {
        this.machalaAdminService
          .changeStateClaim(this.claim.id_reclamo, changeCode)
          .subscribe(
            (resp) => {
              this.getClaim(this.claim.id_reclamo);
            },
            (err) => {
              Notify(err.error.message, null, null, 'danger');
            }
          );
      }
    }
  }
}
