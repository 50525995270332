import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnouncemetResponse_Charge } from '../interface/announcemet.response';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { Assigned_Charge } from 'src/app/controller/announcemet.interface';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ViewChargesResolver } from './view-charges.resolver';
import { ModalComponent } from '../../common-pages/modal/modal.component';
declare var Notify: any;

@Component({
  selector: 'app-view-charges',
  templateUrl: './view-charges.component.html',
  styleUrls: ['./view-charges.component.scss']
})
export class ViewChargesComponent implements OnInit {

  isLoading = false;
  announcement_Charge: AnnouncemetResponse_Charge;
  form: FormGroup;
  formData: FormData;
  assigned: Assigned_Charge[];
  not_assigned: Assigned_Charge[];
  noRecords = false;
  newAnnouncementLoading = ['', '', '', '', '', ''];
  idChargeDelete = '';
  @ViewChild('modalDeleteChargeAnnouncement') modalDeleteChargeAnnouncement: ModalComponent;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly machalaAdminService: MachalaAdminService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private myResolver: ViewChargesResolver
  ) {
    this.form = this.fb.group({
      id_cargo: [[null], Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    const data: any = this.activatedRoute.snapshot.data;
    if ('assignCharge' in data) {
      this.assigned = data.assignCharge.assigned
      this.not_assigned = data.assignCharge.not_assigned
      if (!this.assigned.length) {
        this.noRecords = true
      }
    }
  }

  addFile(id: string): void {
    this.router.navigate(['/convocatorias/agregar-archivo', id]);
  }

  openModal(idCharge: string): void {
    this.idChargeDelete = idCharge;
    this.modalDeleteChargeAnnouncement.mostrarPopup();
  }

  onSubmit(): void {
    this.isLoading = true;
    this.noRecords = true
    const id: any = this.activatedRoute.params;
    const formData = { "id_cargo": [Number(this.form.value.id_cargo)], "id_convocatoria": Number(id.value['id']) };
    this.machalaAdminService.assignChargeAnnouncement(formData)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        catchError(err => {
          Notify('Error al asignar el cargo a la convocatoria -> ' + err.error.message, null, null, 'danger');
          this.noRecords = false;
          return throwError(err);
        })
      )
      .subscribe(() => {
        Notify('Cargo asignado correctamente.', null, null, 'success');
        const currentRoute = this.activatedRoute.snapshot;
        this.myResolver.resolve(currentRoute).subscribe(
          (data) => {
            this.assigned = data.assigned;
            this.not_assigned = data.not_assigned;
            this.form = this.fb.group({
              id_cargo: [[data.not_assigned[0].id_cargo]],
            });
            this.noRecords = false;
          },
          (error) => {
            Notify('Error al eliminar el cargo -> ' + error.error.message, null, null, 'danger');
            this.noRecords = false;
          }
        );
      })
  }


  deleteCharge(): void {
    if (this.idChargeDelete) {
      this.isLoading = true;
      this.machalaAdminService.deleteCharge_Announcement(this.idChargeDelete)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
          catchError(err => {
            Notify('Error al eliminar el cargo -> ' + err.error.message, null, null, 'danger');
            return throwError(err)
          }),
        )
        .subscribe(() => {
          Notify('Cargo eliminado correctamente.', null, null, 'success');
          const currentRoute = this.activatedRoute.snapshot;
          this.myResolver.resolve(currentRoute).subscribe(
            (data) => {
              this.assigned = data.assigned;
              this.not_assigned = data.not_assigned;
              this.form = this.fb.group({
                id_cargo: [[data.not_assigned[0].id_cargo]],
              });
              this.noRecords = false;
            },
            (error) => {
              Notify('Error al eliminar el cargo -> ' + error.error.message, null, null, 'danger');
              this.noRecords = false;
            }
          );
        })
    }
  }

}
