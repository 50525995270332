import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../helpers/services/auth.service';
import { Me } from 'src/app/helpers/interfaces/me.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  user: Me;
  constructor(
    private authService: AuthService,
    private machalaAdminService: MachalaAdminService
  ) {}
  
  ngOnInit(): void {
    this.machalaAdminService
      .getUserMe()
      .toPromise()
      .then((user: Me) => {
        this.user = user;
      })
      .catch();
  }

  ngAfterViewInit(): void {}
  logout(): void {
    this.authService.logOut();
  }
}
