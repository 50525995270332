<div class="container container-register normal-text">
  <div class="container-form" *ngIf="!viewListUser && clickAddUser">
    <div class="form-register">
      <h2 class="border-title">
        <span><strong>Agregar Usuario</strong></span>
      </h2>
      <hr>
      <div>
        <button class="btn btn-primary w-100 text-center" (click)="reloadFormUser()">Ver Usuarios</button>
      </div>
      <hr>
      <form action="" [formGroup]='form' (submit)='createUser()'>
        <div class="form-group">
          <label for="name">Nombre</label>
          <input type="email" class="form-control" id="name" aria-describedby="emailHelp" formControlName='name'
            [class.is-invalid]='invalidName'>
          <small *ngIf="invalidName" id="emailHelp" class="form-text text-danger">Ingrese un nombre de usuario.</small>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" class="form-control" id="email" aria-describedby="emailHelp" formControlName='email'
            [class.is-invalid]='invalidEmail'>
          <small *ngIf="invalidEmail" id="emailHelp" class="form-text text-danger">Email incorrecto.</small>
        </div>
        <div class="form-group">
          <label for="roles">Área Laboral</label>
          <select class="form-control" name="" id="roles" formControlName="area">
            <option>Comercial</option>
            <option>Comunicación</option>
            <option>Planificación</option>
            <option>Administración</option>
            <option>Otro</option>
          </select>
          <!-- <small *ngIf="invalidArea" id="helpId" class="text-danger">Informacíon requerida</small> -->
        </div>

        <div class="form-group">
          <label for="roles">Permisos</label>
          <div class="roles">
            <div class="rol" *ngFor="let screm of screms; let i = index">
              <input type="checkbox" name="rol-r" id="rol-r-{{i}}" [value] = "screm.codigo" (change)="onCheckChange($event)">
              <label for="rol-r-{{i}}"><small>{{screm.nombre}}</small></label>
            </div>
          </div>
          <small *ngIf="invalidViews" class="text-danger">
            Debe ingresar las vistas al que este usuario tendra permiso.
          </small>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="password">Contraseña</label>
              <input type="password" class="form-control" id="password" formControlName='pass1'
                [class.is-invalid]='invalidPass1'>
              <small *ngIf="invalidPass1" id="emailHelp" class="form-text text-danger">Contraseña incorrecto.</small>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="con-password">Confirmar Contraseña</label>
              <input type="password" class="form-control" id="con-password" formControlName='pass2'
                [class.is-invalid]='invalidPass2'>
              <small *ngIf="invalidPass2 && form.get('pass2').errors.required" id="emailHelp"
                class="form-text text-danger">Ingrese una Contraseña.</small>
              <small *ngIf="invalidPass2 && form.get('pass2').errors.notEquals" id="emailHelp"
                class="form-text text-danger">Contraseñas no coinciden.</small>
            </div>
          </div>

        </div>



        <button type="submit" class="btn btn-primary btn-block">Enviar</button>
        <div class="d-flex justify-content-center" *ngIf="estaCargando">
          <div class="img-load">
            <img src="assets/img/load.gif" alt="">
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="container-form" *ngIf="viewListUser">
    <div class="form-register">
      <h2 class="border-title">
        <span><strong>Usuarios</strong></span>
      </h2>
      <hr>
      <div>
        <button class="btn btn-primary w-100 text-center" (click)="viewListUser = false; clickAddUser = true">Agregar Usuario</button>
      </div>
      <hr>
      <div class="users">
        <div class="user" *ngFor="let user of users | paginate: { itemsPerPage: 5, currentPage: p }" (click)="editUser(user.id_usuario)">
          {{user.nombre}} | {{user.area}}
          <br>
          <small>{{user.email}}</small>
        </div>
        <div *ngIf="!users.length">
          <div class="user"  *ngFor="let user of usersLoad">
            <div class="loading-i"></div>
            <div class="loading-i"></div>
          </div>
        </div>
      </div>
      <hr>
      <pagination-controls
      [previousLabel]="''"
      [nextLabel]="''"
      [maxSize]="5"
      class="my-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
    </div>
  </div>

  <div [ngClass]="!viewListUser && clickEditUser ? 'container-form':'container-form hidden'">
    <div class="form-register">
      <app-update-user #userEditC (reloadUser) = "reloadListUser()" (viewUsers)="viewListUser = true; clickAddUser = false"></app-update-user>
    </div>
  </div>

</div>
