import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountabilityList } from 'src/app/helpers/interfaces/accountability.interface';
import { Accountability } from 'src/app/helpers/interfaces/transparency.insterface';
import { MachalaAdminService } from 'src/app/helpers/services/machala-admin.service';
import { UploadFilesService } from 'src/app/helpers/services/upload-files.service';
declare var Notify: any;

@Component({
  selector: 'app-update-accountability',
  templateUrl: './update-accountability.component.html',
  styleUrls: ['./update-accountability.component.scss'],
})
export class UpdateAccountabilityComponent implements OnInit {
  formIsActive = false;
  formTransparency: FormGroup;
  file: File;
  loadNumber = 0;
  loadingFile = false;
  year = [];
  idUpdate = '-1';
  isLoading = false;
  @ViewChild('formContainer') formContainer: ElementRef<HTMLElement>;
  @Output() reloadList = new EventEmitter<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    private uploadFilesService: UploadFilesService,
    private machalaAdminService: MachalaAdminService
  ) {}

  ngOnInit(): void {
    const lastYear = new Date().getFullYear();
    const range = lastYear - 2015;
    this.year = Array(range)
      .fill(1)
      .map((v, i) => 2016 + i);
    this.reloadAtributes();
  }

  saveFile(): void {
    this.isLoading = true;
    if (this.formTransparency.valid) {
      const newTranparency: Accountability = {
        url: this.formTransparency.get('filePath').value,
        nombre: this.formTransparency.get('name').value,
        anio: this.formTransparency.get('anio').value,
      };
      this.machalaAdminService
        .putAccountability(this.idUpdate.toString(), newTranparency)
        .subscribe(
          (resp) => {
            this.reloadList.emit(true);
            this.activeForm();
            this.isLoading = false;
            Notify('Registro modificado correctamente.', null, null, 'success');
          },
          (err) => {
            Notify(
              'Surgio un error al realizar la petición al servidor.',
              null,
              null,
              'danger'
            );

            this.isLoading = false;
          }
        );
    } else {
      this.isLoading = false;
      this.validateForm();
    }
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text;
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      this.loadingFile = true;
      this.formTransparency.get('file').disable();
      const file = event.target.files[0];
      this.file = file;
      const name = this.generateName() + '_' + this.file.name;
      const blob = this.file.slice(0, this.file.size, this.file.type);
      const newFile = new File([blob], name, { type: this.file.type });
      this.uploadFilesService.upload(newFile).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.loadNumber = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            this.formTransparency.get('filePath').setValue(event.body.url);
            this.loadingFile = false;
            this.formTransparency.get('file').enable();
            const msg = 'Uploaded the file successfully: ' + this.file.name;
          }
        },
        (error) => {
          this.formTransparency.get('file').enable();
          this.loadingFile = false;
          this.loadNumber = 0;
          try {
            if (error.error && error.error.message) {
              Notify(error.error.message, null, null, 'danger');
            } else {
              Notify('Error el archivo pesa mas de 50gb', null, null, 'danger');
            }
          } catch (error) {
            Notify('Error el archivo pesa mas de 50gb', null, null, 'danger');
          }
        }
      );
    }
  }

  activeForm(): void {
    this.formIsActive = !this.formIsActive;
    const form = this.formContainer.nativeElement.getElementsByClassName(
      'form-transparency'
    )[0] as HTMLElement;
    if (!this.formIsActive) {
      this.formContainer.nativeElement.style.height = '0';
      this.reloadAtributes();
    } else {
      this.formContainer.nativeElement.style.height =
        form.clientHeight + 114 + 'px';
    }
  }

  activeFormUpload(): void {
    if (!this.formIsActive) {
      this.formIsActive = true;
      const form = this.formContainer.nativeElement.getElementsByClassName(
        'form-transparency'
      )[0] as HTMLElement;
      this.formContainer.nativeElement.style.height =
        form.clientHeight + 114 + 'px';
    }
    this.reloadAtributes();
  }

  reloadAtributes(): void {
    this.idUpdate = '-1';
    this.file = undefined;
    this.loadNumber = 0;
    this.formTransparency = this.formBuilder.group({
      name: ['', Validators.required],
      file: [''],
      filePath: ['', Validators.required],
      anio: ['', Validators.required],
    });
    this.untouchedForm();
  }

  get invalidName(): boolean {
    return (
      this.formTransparency.get('name').invalid &&
      this.formTransparency.get('name').touched
    );
  }
  get invalidFile(): boolean {
    return (
      this.formTransparency.get('file').invalid &&
      this.formTransparency.get('file').touched
    );
  }

  fillForm(id: number): void {}

  validateForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAllAsTouched();
        }
      );
    }
  }

  untouchedForm(): void {
    if (this.formTransparency.invalid) {
      return Object.values(this.formTransparency.controls).forEach(
        (control) => {
          control.markAsUntouched();
        }
      );
    }
  }

  uploadFile(transparency: AccountabilityList): void {
    this.activeFormUpload();
    this.idUpdate = transparency.id_rendicioncuentas;
    this.formTransparency.get('anio').setValue(transparency.anio);
    this.formTransparency.get('name').setValue(transparency.nombre);
    this.formTransparency.get('filePath').setValue(transparency.url);
  }
}
